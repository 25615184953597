/**
 * Converts the values of a flat object into an array (list).
 *
 * @param {Object} obj - The flat object whose values are to be extracted.
 * @returns {Array} An array containing the values of the object.
 *
 * @example
 * const person = { name: "Alice", age: 30, occupation: "Engineer" };
 * const valuesList = objValuesToList(person);
 * console.log(valuesList);
 * // Output: ["Alice", 30, "Engineer"]
 */
export const objValuesToArray = (obj) => {
    return Object.values(obj);
};
