    export const assignValue = (obj, keyPath, value) => {
        // Split the path into components, handling brackets for array indices
        const keys = keyPath
            .replace(/\[(\d+)\]/g, '.$1') // Convert [index] to .index
            .split('.'); // Split by dot

        // Traverse and create the required structure
        keys.reduce((currentObj, key, index) => {
            // Convert key to number if it's an array index
            const indexKey = Number(key);
            const isArrayIndex = !isNaN(indexKey) && indexKey.toString() === key;

            // Determine if the next key exists and if it is an array index
            const nextKey = keys[index + 1];
            const isNextArrayIndex = nextKey != null && !isNaN(Number(nextKey)) && Number(nextKey).toString() === nextKey;

            if (index === keys.length - 1) {
                // If at the last key, assign the value
                if (isArrayIndex) {
                    // Ensure the target is an array before assignment
                    if (!Array.isArray(currentObj)) {
                        throw new Error('Target is not an array.');
                    }
                    // Extend the array if needed
                    while (currentObj.length <= indexKey) {
                        currentObj.push({});
                    }
                    // Assign the value
                    currentObj[indexKey] = value;
                } else {
                    currentObj[key] = value;
                }
            } else {
                // Ensure the current structure is valid for the next step
                if (currentObj == null) {
                    // Create the appropriate structure if it doesn't exist
                    currentObj = isArrayIndex ? [] : {};
                } else if (isArrayIndex) {
                    if (!Array.isArray(currentObj)) {
                        throw new Error('Expected an array but found an object.');
                    }
                    // Ensure the array has enough space
                    while (currentObj.length <= indexKey) {
                        currentObj.push({});
                    }
                    // Move deeper into the array
                    currentObj = currentObj[indexKey];
                } else {
                    if (typeof currentObj !== 'object' || Array.isArray(currentObj)) {
                        throw new Error('Expected an object but found an array or non-object.');
                    }
                    // Initialize the key if it doesn't exist
                    if (currentObj[key] == null) {
                        // Determine if the next key should be an array or object
                        currentObj[key] = isNextArrayIndex ? [] : {};
                    }
                    // Move deeper into the object
                    currentObj = currentObj[key];
                }
            }

            // Return the current object/array for the next iteration
            return currentObj;
        }, obj);
    }

export const findByPath = (data, path) => {
    const keys = path
        .replace(/\[(\d+)\]/g, '.$1') // Convert [index] to .index
        .split('.'); // Split by dot

    // Traverse the object/array based on the path
    return keys.reduce((o, key) => {
        // Check if the current value is null or undefined
        if (o == null) {
            return undefined;
        }

        // Convert key to number if it's an array index
        const indexKey = Number(key);
        const isArrayIndex = !isNaN(indexKey) && indexKey.toString() === key;

        // Access the property or array index
        return isArrayIndex ? (Array.isArray(o) ? o[indexKey] : undefined) : o[key];
    }, data);
}
