import {useMemo} from 'react';
import _ from 'lodash';

/**
 * Custom hook that generates a unique ID with a given prefix.
 *
 * @param {string} prefix - The prefix to use for the unique ID.
 * @returns {string} The generated unique ID.
 */
const useUniqueId = (prefix = 'unique-id-') => {
    return useMemo(() => _.uniqueId(prefix), [prefix]);
};

export default useUniqueId;