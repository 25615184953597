import React, {useState} from 'react';
import {
    AccountBalance,
    AccountBalanceWallet,
    Assignment,
    Dashboard,
    DateRange,
    GroupWork,
    Home,
    Payment,
    People,
    Settings
} from '@material-ui/icons';

import ComponentBuilder from "../../core/ComponentBuilder";
import AppSideBar from '../../core/components/AppSideBar';
import {useSelector} from "react-redux";

const isDashboardSelected = () =>
  window.location.pathname.startsWith('/admin/dashboard');

const topAdminNavItem = { title: 'Admin', icon: Home, key: 'admin', href: '/admin' };

const baseLinks = [
  // TODO: UNCOMMENT WHEN READY TO INTRODUCE PHASE 2 ITEMS.
  // { title: 'Automatic Invoicing', icon: Assignment, key: 'automated-invoice-queue', href: '/admin/automated-invoice-queue' },
  { title: 'Bad Debt Report', icon: Payment, key: 'bad-debt-report', href: '/admin/bad-debt-report' },
  { title: 'Commission Center', icon: AccountBalance, key: 'commission-center', href: '/admin/commission-center' },
  { title: 'Commission Summary', icon: AccountBalanceWallet, key: 'commission-summary', href: '/admin/commission-summary' },
  { title: 'Company GM Report', icon: DateRange, key: 'company-gross-margin', href: '/admin/company-gross-margin' },
  { title: 'Customer Payments', icon: Payment, key: 'customer-payments', href: '/admin/customer-payments' },
  { title: 'Dashboard', icon: Dashboard, key: 'dashboard', href: '/admin/dashboard/all', isSelected: isDashboardSelected },
  { title: 'Do Not Pay Carrier', icon:  GroupWork, key: 'do-not-pay', href: '/admin/do-not-pay' },
  { title: 'Double Brokered Loads', icon:  GroupWork, key: 'double-brokered', href: '/admin/double-brokered' },
  { title: 'Home Page Settings', icon: Settings, key: 'home-settings', href: '/admin/home-settings' },
  { title: 'Manage Users', icon: People, key: 'manage-users', href: '/admin/manage-users' },
  { title: 'Risk Review Report', icon: DateRange, key: 'risk-review-report', href: '/admin/risk-review-report/high-gm-shipments' },
  { title: 'Standard Reports', icon: Assignment, key: 'standard-reports', href: '/admin/standard-reports' },
  { title: 'User Sessions', icon: DateRange, key: 'user-sessions', href: '/admin/user-sessions' },
];

const LeftNav = (props) => {
    const employeeContext = JSON.parse(localStorage.getItem("employeeContext") ?? null);
    const canEditBusinessDays = employeeContext.claims.canEditBusinessDays;

    const state = useSelector(state => state);
    const canEditBlockedDates = state.persona.agent.canEditBlockedDates;
    const canEditBlockedDatesArBilling = state.persona.agent.canEditBlockedDatesArBilling;
    const canViewCollectionsAssignment = state.persona.agent.canViewCollectionsAssignment;
    const [open, setOpen] = useState(true);

  const handleDrawerOpen = () => {
    setOpen(true);
  };

  const handleDrawerClose = () => {
    setOpen(false);
  };

  const findAdditionalLinks = (featureFlags) => {
    const { billingAutomation, arReleaseHidden } = featureFlags;

    const links = [...baseLinks];

    if(billingAutomation) {
      links.push(
          { title: 'AP Automation', icon:  GroupWork, key: 'auto-processed', href: '/admin/auto-processed' }
      );
    }

    if(canEditBlockedDatesArBilling) {
      links.push(
          { title: 'AR Automation Block Control', icon: DateRange, key: 'locked-dates-billing', href: '/admin/locked-dates-billing' }
      );
    }

    if(canViewCollectionsAssignment) {
      links.push(
          { title: 'Collectors Assignment', icon: DateRange, key: 'collectors-assignment', href: '/admin/collectors-assignment' }
      );
    }

    if(canEditBlockedDates) {
      links.push(
          { title: 'Locked Date Control', icon: DateRange, key: 'locked-dates', href: '/admin/locked-dates' }
      );
    }

    if(canEditBusinessDays) {
      links.push(
          { title: 'Business Days Control', icon: DateRange, key: 'business-days', href: '/admin/business-days' }
      );
    }

      links.push(
          { title: 'Personnel Directory', icon: People, key: 'personnel-directory-report', href: '/admin/personnel-directory-report' }
      );


      arReleaseHidden === false ?
        links.push(
            {title: 'Manual Invoicing', icon: Assignment, key: 'manual-invoicing-report', href: '/admin/manual-invoicing-report'}
        ) :
        links.push(
            {title: 'Manual Invoicing', icon: Assignment, key: 'invoicing-exception-queue', href: '/admin/invoicing-exception-queue'}
        );

    links.sort((a, b) => a.title.localeCompare(b.title));
    links.unshift(topAdminNavItem);
    return links;
  }

  const [menuItems, setMenuItems] = useState([]);

  React.useEffect( () => {
    async function getFeatureState() {
      const featureFlags = await props.getFeatureState();
      const links = findAdditionalLinks(featureFlags);
      setMenuItems(links);
    }
    getFeatureState();

  }, []);

  return (
   <AppSideBar menuList={menuItems} handleDrawerOpen={handleDrawerOpen} handleDrawerClose={handleDrawerClose} open={open} />
  );
}

export default ComponentBuilder
    .wrap(LeftNav)
    .stateToProps((state, ownProps) => ({
    }))
    .dispatchToProps((shell, dispatch, getState) => {
      return {
        async getFeatureState() {
          return await shell.gateway.communicator.get('/security/featureFlags');
        }
      }
    })
    .build();

//export default LeftNav
