import {useEffect, useState} from "react";

const assignValueByPath = (path, value, obj) => {
    const keys = path.split('.');
    const lastKey = keys.pop();
    let currentObj = obj;
    // Recursively create nested objects
    for (const key of keys) {
        currentObj[key] = currentObj[key] || {};
        currentObj = currentObj[key];
    }
    // Assign the value to the last nested property
    currentObj[lastKey] = value;
}

const useFieldValidator = (schema, formValues, setIsValid = null) => {
    const [fieldErrors, setFieldErrors] = useState({});
    useEffect(() => {
        const check = async () => {
            try {
                if(schema === null) return;
                await schema.validate(formValues, {abortEarly: false});
                setFieldErrors({});
            } catch (e) {
                const errors = {};
                for(let error of e.inner) {
                    assignValueByPath(error.path, error.message.replace(`${error.path}`, ""), errors);
                }
                setFieldErrors(prevErrors => ({ ...prevErrors, ...errors }));
            }
        }
        check();
    }, [formValues])

    useEffect(() => {
        if(setIsValid === null) return;
        if(Object.keys(fieldErrors).length !== 0) {
            setIsValid(false)
        } else {
            setIsValid(true)
        }
    }, [fieldErrors])

    return fieldErrors
}

export default useFieldValidator;
