import React from 'react';

import AppCard from "../../../../core/components/cards/AppCard/AppMuiCard";
import AppText from "../../../../core/components/text/AppText";
import AppPercentText from "../../../../core/components/text/AppPercentText";


const TotalMarginPercentage = (props) => {

    const {
        grossMarginData
    } = props;
    return (
        grossMarginData?.total ? <AppCard>
            <h1>
                <AppText
                    value={' Avg. Margin %: '}
                    style={grossMarginData.total.margin < 0 ? {color: "red"} : {color: "green"}}
                ></AppText>
                <AppPercentText
                    value={grossMarginData.total.margin === 0 ? 0 : grossMarginData.total.margin / grossMarginData.total.customerCost}
                >
                </AppPercentText>
            </h1>
        </AppCard> : <React.Fragment></React.Fragment>
    );
};

export default TotalMarginPercentage;