import React, {useEffect, useState} from "react";
import {Grid} from "@material-ui/core";

import FullWidthLayout from "../../../core/layouts/FullWidthLayout";
import PayConfigurationCard from "./includes/PayConfigurationCard";
import ReportNav from "../ReportNav";
import AppCard from "../../../core/components/cards/AppCard";
import LegendCheckMark from "./includes/LegendCheckMark";
import CommissionBreakdownCard from "./includes/CommissionBreakdownCard";
import CrmAssociateDropDown from "../../../crm/components/CrmAssociateDropDown";
import useIsAdministrator from "../../../hubs/personaV2/selectors/useIsAdministrator";
import CommissionTypeNames from "../../../hubs/commission/CommissionTypeNames";
import CommissionTypeLabels from "../../../crm/components/associate/CommissionsCenter/CommissionTypeLabels";
import AppMonthYear from "../../../core/components/inputs/AppDatePicker/AppMonthYear";
import GrossMarginTable from "../../../crm/components/associate/GrossMarginTable";
import TotalMarginTable from "./includes/TotalMarginTable";
import Business from "@tgf-crm/business";
import TotalMarginPercentage from "./includes/TotalMarginPercentage";
import TotalMarginPaceTable from "../../../crm/components/tables/TotalMarginPaceTable";
import TotalRevenuePaceTable from "../../../crm/components/tables/TotalRevenuePaceTable";
import moment from "moment";
import {usePageTitle} from "../../../crm/components/customHooks/misc/usePageTitle";
import AccessDeniedPage from "../../errors/AccessDeniedPage";
import TgfAgencyComboBox from "../../../core/kendo/form/inputs/specialty/TgfAgencyComboBox";
import InfoIconTooltip from "../../../core/components/Tooltips/InfoIconTooltip/InfoIconTooltip";
import TgfAgencyAssociatesComboBox, {
    TgfAgencyAssociateComboBoxFilters
} from "../../../core/kendo/form/inputs/specialty/TgfAgencyAssociatesComboBox";
import {Checkbox} from "@progress/kendo-react-inputs";
import ShipmentListing from "../../reporting/MonthlyGrossMargin/includes/ShipmentListing";
import AgentRankDialog from "./includes/AgentRankDialog";
import {useDispatch, useSelector} from "react-redux";
import useIsHeadOfAgency from "../../../hubs/personaV2/selectors/useIsHeadOfAgency";
import Adjustments from "./includes/Adjustments";

const PercentFormatter = new Intl.NumberFormat("en-US", {
    style: "percent",
    minimumFractionDigits: 1,
});

const computeUtcDateRange = (date) => {
    let newDate = new Date();
    if (date) {
        newDate = date;
    }
    const utcStartDate = moment.utc([newDate.getFullYear(), newDate.getMonth(), 1, 0]).toDate();
    const utcEndDate = utcStartDate
        .toMoment()
        .utc()
        .add(1, "month")
        .subtract(1, "ms")
        .toDate();
    return [utcStartDate, utcEndDate];
};

const getPayPeriods = (date) => {
    return Business.Associate.PayPeriod.buildFromMonth(
        date.toMoment().utc().month(),
        date.toMoment().utc().year()
    );
};

const MonthlyGrossMarginPage = (props) => {
    const {
        navMenu,
        agencyMode
    } = props;

    const personalDispatch = useDispatch();
    const isAdmin = useIsAdministrator();
    const isHeadOfAgency = useIsHeadOfAgency();
    const currentAssociate = useSelector(state => state.persona.associate);

    usePageTitle(!isHeadOfAgency ? "TGF: Monthly GM Report" : "TGF: Agency GM Report");

    const employeeContext = JSON.parse(localStorage.getItem("employeeContext") ?? null);
    const canView = (employeeContext?.claims?.isHeadOfAgency) || (employeeContext?.claims?.isAdministrator);


    let [utcStartDate, utcEndDate] = computeUtcDateRange();
    const [associate, setAssociate] = useState(
        isAdmin ? null : currentAssociate
    );
    const [agency, setAgency] = useState(null);
    const [monthYear, setMonthYear] = useState(new Date());
    const [startDate, setStartDate] = useState(utcStartDate);
    const [endDate, setEndDate] = useState(utcEndDate);

    const [associateFilter, setAssociateFilter] = useState(null);
    const [hideCustomerOwnerShipments, setHideCustomerOwnerShipments] = useState(false);
    const [showWarDialog, setShowWarDialog] = useState(false);
    const payPeriods = getPayPeriods(startDate);
    const [associateCommissionRates, setAssociateCommissionRates] = useState();
    const [periodConfigurations, setPeriodConfigurations] = useState();
    const [grossMarginBreakdown, setGrossMarginBreakdown] = useState();
    const [monthlyCommissionBreakdown, setMonthlyCommissionBreakdown] = useState();

    const clearGMFields = () => {
        setGrossMarginBreakdown(null);
        setMonthlyCommissionBreakdown(null);
        setPeriodConfigurations(null);
        setAssociateCommissionRates(null);
    };

    useEffect(() => {
        if (!associate) {
            clearGMFields();
        }
    }, [associate]);


    const loadGrossMarginBreakdown = async () => {
        try {
            personalDispatch(window.shell.actions.sys.processStart('loadGrossMarginBreakdown'));
            const grossMarginBreakdown = await window.shell.gateway.getAssociateGrossMarginBreakdown({
                    startDate: startDate,
                    endDate: endDate,
                    associateId: associate?.id,
                    customerId: 0,
                    hideCustomerOwnerShipments: hideCustomerOwnerShipments ? hideCustomerOwnerShipments : null,
                    showCustomerOwnerId: associateFilter ? associateFilter?.agencyCustomerOwner?.id : null
                }
            );
            setGrossMarginBreakdown(grossMarginBreakdown);
        } catch (e) {
            console.log(e);
        } finally {
            personalDispatch(window.shell.actions.sys.processComplete('loadGrossMarginBreakdown'));
        }
    };

    const loadMonthlyCommissionBreakdown = async () => {
        try {
            personalDispatch(window.shell.actions.sys.processStart('loadMonthlyCommissionBreakdown'));
            const monthlyCommissionBreakdown = await window.shell.gateway.getMonthlyCommissionBreakdown({
                    associateId: associate?.id,
                    startDate: startDate,
                    endDate: endDate,
                    hideCustomerOwnerShipments: hideCustomerOwnerShipments ? hideCustomerOwnerShipments : null,
                    showCustomerOwnerId: associateFilter?.agencyCustomerOwner?.id
                }
            );
            setMonthlyCommissionBreakdown(monthlyCommissionBreakdown);
        } catch (e) {
            console.log(e);
        } finally {
            personalDispatch(window.shell.actions.sys.processComplete('loadMonthlyCommissionBreakdown'));
        }
    };

    const loadAssociateCommissionRates = async () => {
        try {
            personalDispatch(window.shell.actions.sys.processStart('loadAssociateCommissionRates'));
            const rates = await window.shell.gateway.getMonthlyCommissionRate(
                associate?.id,
                startDate,
                endDate
            );
            setAssociateCommissionRates(rates);
        } catch (e) {
            console.log(e);
        } finally {
            personalDispatch(window.shell.actions.sys.processComplete('loadAssociateCommissionRates'));
        }
    };

    useEffect(() => {
        if (associate) {
            loadGrossMarginBreakdown();
        }
    }, [associateFilter]);

    useEffect(() => {
        if (associateCommissionRates && payPeriods && associate) {
            loadPeriodConfigurations();
        }
    }, [associateCommissionRates]);

    useEffect(() => {
        if (startDate && endDate && associate) {
            loadAssociateCommissionRates();
            loadGrossMarginBreakdown();
            loadMonthlyCommissionBreakdown();
        }
    }, [associate, startDate, endDate]);

    const loadPeriodConfigurations = () => {
        const periodConfigurations =
            payPeriods
                .map((pp) => {
                    // Large ass date comparison due to shitty timezone conversion. Be careful messing with this.
                    return associateCommissionRates.find(
                        (commRate) =>
                            commRate.commissionDate.toMoment().utc().year() ===
                            pp.startDate.toMoment().utc().year() &&
                            commRate.commissionDate.toMoment().utc().month() ===
                            pp.startDate.toMoment().utc().month() &&
                            commRate.commissionDate.toMoment().utc().date() ===
                            pp.startDate.toMoment().utc().date()
                    );

                })
                .map(periodConfigurationMap);
        setPeriodConfigurations(periodConfigurations);
    };

    const handleMonthYearChange = (monthYearValue) => {
        const date =
            monthYearValue && monthYearValue.isValid()
                ? monthYearValue.toDate()
                : null;

        let [utcStartDate, utcEndDate] = computeUtcDateRange(date);
        setMonthYear(date);
        setStartDate(utcStartDate);
        setEndDate(utcEndDate);
    };

    const handleAssociateChange = (associate) => {
        setAssociate(associate);
    };

    const onSelectAssociate = (e, type) => {
        const associate = e?.target?.value ? e?.target?.value : e;
        if (!associate?.id) {
            setAssociateFilter(null);

            return;
        }
        switch (type) {
            case "agency customer owner":
                setAssociateFilter({agencyCustomerOwner: associate});
                break;
            case "agency any":
                setAssociateFilter({agencyAny: associate});
                break;
            case "any":
                setAssociateFilter({any: associate});
                break;
            default:
                setAssociateFilter(null);
        }
    };

    const handleHideCustomerOwnedShipments = (e) => {
        if (e.target.value) {
            setHideCustomerOwnerShipments(e.target.value);
        } else {
            setHideCustomerOwnerShipments(false);
        }
    };

    const handleSelectionOfAgency = async (e) => {
        if (e.target.value) {
            const targetAssociate = await window.shell.gateway.getAssociate(e.target.value, false);
            setAssociate(targetAssociate);
        } else {
            setAssociate(null);
        }

        setHideCustomerOwnerShipments(false);
        setAssociateFilter(null);
    };

    const handleWarClick = () => setShowWarDialog(!showWarDialog);
    const showWarLink = () => monthYear && !agencyMode && (isHeadOfAgency || isAdmin);

    return (
        canView ? <FullWidthLayout
            SideNav={navMenu ? navMenu : ReportNav}
            title={!agencyMode ? "Monthly Gross Margin Report" : "Agency GM Report"}
        >
            <Grid container spacing={2}>
                {agencyMode &&
                    <Grid container item xs={12}>
                        <Grid container item spacing={2}>
                            {isAdmin &&
                                <Grid item xs={12} md={2}>
                                    <TgfAgencyComboBox
                                        label={null}
                                        onChange={handleSelectionOfAgency}
                                        value={associate?.id}
                                        franchiseMode={true}
                                        isDisabled={false}
                                    />
                                </Grid>
                            }
                            <Grid
                                item xs={12} md={2}
                                style={{
                                    alignItems: "center",
                                    display: "flex",
                                }}
                            >
                                <TgfAgencyAssociatesComboBox
                                    placeholder={'Filter by customer owners...'}
                                    onChange={(e) => onSelectAssociate(e, "agency customer owner")}
                                    selectedAgencyAssociate={associateFilter?.agencyCustomerOwner ?? null}
                                    activeOnly={true}
                                    visibilityPermissionIdFilter={2}
                                    actionRoleId={3}
                                    roleFilter={TgfAgencyAssociateComboBoxFilters.customerOwners}
                                    agencyId={!agencyMode ? associate?.id : associate?.parentAssociateId}
                                    disabled={Boolean(associateFilter?.agencyAny || hideCustomerOwnerShipments)}
                                />
                                <InfoIconTooltip
                                    title={
                                        <>
                                            <p>
                                                Selecting a user here will filter the page's information below to just
                                                their customers' shipment information.
                                            </p>
                                            <p>
                                                Inactive users will not display as an option on this dropdown, which is
                                                why working with the back office when terminating a Restricted user is
                                                important as they can make the transition/reassigning of accounts an
                                                easy process.
                                            </p>
                                        </>
                                    }
                                    style={{alignSelf: "center"}}
                                />
                            </Grid>
                            <Grid
                                item xs={12} md={2} style={{display: "flex", alignItems: "center", alignSelf: "center"}}
                            >
                                <Checkbox
                                    value={hideCustomerOwnerShipments}
                                    id={"hideCustomerOwnerShipments"}
                                    name={"hideCustomerOwnerShipments"}
                                    label={"Hide customer owner shipments"}
                                    onChange={handleHideCustomerOwnedShipments}
                                    disabled={associateFilter?.agencyCustomerOwner || associateFilter?.agencyAny}
                                />
                                <InfoIconTooltip
                                    title={
                                        <>
                                            <p>
                                                Checking this box will hide all information below involving customers
                                                who have a Customer Owner assigned to them - even those involving an
                                                inactive Restricted user.
                                            </p>
                                            <p>
                                                Working with the back office when terminating a Restricted user is
                                                important as they can make the transition/reassigning of accounts an
                                                easy process.
                                            </p>
                                        </>
                                    }
                                />
                            </Grid>
                        </Grid>
                    </Grid>

                }
                <Grid container item xs={12} md={isAdmin ? 3 : 2}>
                    <AppCard>
                        <Grid container item spacing={2}>
                            <Grid item xs={12} md={isAdmin && !agencyMode ? 6 : 12}>
                                <label htmlFor="MonthYear">Month/Year:</label>
                                <AppMonthYear
                                    id="monthYear"
                                    openTo="year"
                                    views={["year", "month"]}
                                    inputVariant="outlined"
                                    value={monthYear}
                                    onChange={handleMonthYearChange}
                                />
                            </Grid>
                            {(isAdmin && !agencyMode) && (
                                <Grid item xs={12} md={6}>
                                    <label htmlFor="associate">Associate</label>
                                    <CrmAssociateDropDown
                                        id={"associate"}
                                        autoSelectFirst={false}
                                        onChangeAssociate={handleAssociateChange}
                                    />
                                </Grid>
                            )}
                        </Grid>
                    </AppCard>
                </Grid>
                {showWarLink() &&
                    <Grid
                        container item xs={1} spacing={2} style={{
                        display: "flex",
                        alignItems: "center",
                        fontWeight: "bold",
                        color: "blue",
                        textDecoration: "underline",
                        fontSize: 24,
                        flex: 1,
                        textAlign: "center",
                        cursor: "pointer"
                    }}
                    >
                        <Grid item xs={12} onClick={handleWarClick}>WAR</Grid>
                        {showWarDialog &&
                            <AgentRankDialog
                                date={monthYear}
                                defaultAssociate={associate ?? currentAssociate}
                                onClose={handleWarClick}
                            />
                        }
                    </Grid>
                }
                <Grid container xs={4} spacing={1} className={"margin-container"}>
                    <Grid item xs={12}>
                        <TotalMarginTable
                            date={monthYear}
                            grossMarginData={grossMarginBreakdown}
                        />
                    </Grid>
                    <Grid item xs={12}>
                        <TotalMarginPercentage
                            date={monthYear}
                            grossMarginData={grossMarginBreakdown}
                        />
                    </Grid>
                </Grid>
                <Grid container xs={4} spacing={1} className={"margin-container"}>
                    <Grid item xs={12}>
                        <TotalMarginPaceTable
                            date={monthYear}
                            grossMarginData={grossMarginBreakdown}
                        />
                    </Grid>
                    <Grid item xs={12}>
                        <TotalRevenuePaceTable
                            date={monthYear}
                            grossMarginData={grossMarginBreakdown}
                        />
                    </Grid>
                </Grid>
                <Grid item xs={12} style={{display: "flex"}}>
                    <GrossMarginTable
                        date={monthYear}
                        grossMarginData={grossMarginBreakdown}
                        agencyMode={agencyMode}
                    />
                </Grid>
                {!agencyMode &&
                    <>
                        <Grid item xs={12} md={5}>
                            <CommissionBreakdownCard commissionBreakdown={monthlyCommissionBreakdown} />
                        </Grid>
                        <Grid item xs={12} md={4}>
                            <PayConfigurationCard periodConfigurations={periodConfigurations} />
                        </Grid>
                        <Grid item xs={12} md={3}>
                            <LegendCheckMark />
                        </Grid>
                    </>
                }
                {agencyMode &&
                    <Grid item xs={12} md={12}>
                        <LegendCheckMark agencyMode={agencyMode} />
                    </Grid>
                }
                <Grid item xs={12}>
                    <Adjustments
                        associate={associate}
                        payPeriods={payPeriods}
                        startDate={payPeriods[0].startDate}
                        endDate={payPeriods[1].startDate}
                        agencyMode={agencyMode}
                        isAdmin={isAdmin}
                    />
                </Grid>
                <Grid item xs={12}>
                    <ShipmentListing
                        associate={associate}
                        payPeriods={payPeriods}
                        startDate={startDate}
                        endDate={endDate}
                        agencyMode={agencyMode}
                        associateCommissionRates={associateCommissionRates}
                        associateFilter={associateFilter}
                        hideCustomerOwnerShipments={hideCustomerOwnerShipments}
                    />
                </Grid>
            </Grid>
        </FullWidthLayout> : <AccessDeniedPage />
    );
};

const periodConfigurationMap = (commissionRate) => {
    const hasCommissionRate = commissionRate && commissionRate.id > 0;

    if (!hasCommissionRate) {
        return {
            paymentType: "N/A",
            commissionPercentage: "N/A",
            showFeeInformation: false,
        };
    }

    const isFeeCommissionType =
        commissionRate.commissionType === CommissionTypeNames.Fee;

    const commissionTypeKey = isFeeCommissionType
        ? CommissionTypeNames.Fee
        : CommissionTypeNames.Margin;

    const paymentType = CommissionTypeLabels.find(
        (label) => label.value === commissionTypeKey
    );

    const commissionPercentage = isFeeCommissionType
        ? commissionRate.feePercent
        : commissionRate.marginPercent;

    return {
        paymentType: paymentType.label,
        commissionPercentage: PercentFormatter.format(commissionPercentage),
        showFeeInformation: !isFeeCommissionType,
    };
};

export default MonthlyGrossMarginPage;
