import {filterBy} from "@progress/kendo-data-query";
import {ComboBox} from "@progress/kendo-react-dropdowns";
import {Label} from "@progress/kendo-react-labels";
import PropTypes from "prop-types";
import {useEffect, useState} from "react";


/**
 * Please read: use the TgfFormPrimitiveComboBoxInput when you have a primitive array of data ["option 1", "option 2"]
 * if you have a complex set of data: [{id: 1, display: "option 1"}...], than use the original combobox
 **/
const TgfComboBox = (props) => {
    const {
        value,
    } = props;

    const [data, setData] = useState(props.data);
    const [isOpen, setIsOpen] = useState(false);

    useEffect(() => {
        setData(props.data);
    }, [props.data]);

    const filterData = (filter) => {
        const data = props.data.slice();
        return filterBy(data, filter);
    };
    const onFilterChange = (event) => {
        setData(filterData(event.filter));
    };

    const onBlur = () => {
        setData(props.data);
        setIsOpen(false);
    };

    const onClose = () => {
        setData(props.data);
        setIsOpen(false);
    };

    const onFocus = () => {
        setIsOpen(true);
    };

    const onOpen = () => {
        setIsOpen(true);
    };

    return (
        <>
            {props.label && <Label editorId={props.id}>{props.label}</Label>}
            <ComboBox
                {...props}
                className={"tgf-kendo-combobox"}
                value={value}
                data={data}
                opened={isOpen}
                onBlur={onBlur}
                onOpen={onOpen}
                onClose={onClose}
                onFocus={onFocus}
                onFilterChange={onFilterChange}
                label={null} // we set this to null, because label is used in the Label component up above.
                filterable={props.filterable ?? true}
            />
        </>
    );
};

TgfComboBox.propTypes = {
    // Will show a label if one provided.
    label: PropTypes.string,
    // Id of the input
    id: PropTypes.string.isRequired,
    // Name of the input, this is used to reference in the event handler.
    name: PropTypes.string.isRequired,
    // List of data for the dropdown IE: ["option 1", "option 2"]
    data: PropTypes.array.isRequired,
    // The reference key to the value we want to display from the object/s.
    textField: PropTypes.string,
    // The reference key to the value we want to be chosen and stored as our actual form value from the object/s.
    dataItemKey: PropTypes.string,
    // The current value of the input
    value: PropTypes.any,
    // A reference to your onChange event handler.
    onChange: PropTypes.func.isRequired,
    // Bool to disable the input if needed.
    disabled: PropTypes.bool,
    // Bool to make the input filterable.
    filterable: PropTypes.bool,
    // Tab index to allow users to cycle through the inputs in order using the tab button.
    tabIndex: PropTypes.number,

};

export default TgfComboBox;