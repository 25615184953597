import React from "react";
import ConfirmationModal from "../../../../../core/kendo/dialog/ConfimationModal";

export const DeletePersonnelConfirmationModal = (props) => {
    const {
        onDelete,
        onClose,
        personnel,
    } = props;

    return (
        <ConfirmationModal
            title={"Delete Personnel Entry"}
            description={`Are you sure you want to delete the personnel record for ${personnel.firstName} ${personnel.lastName}?`}
            action={onDelete}
            onClose={onClose}
            confirmBtnText={"YES"}
            cancelBtnText={"NO"}
        />
    );
};
