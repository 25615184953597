import React, {useEffect} from "react";
import TgfDialog from "../../../../core/kendo/dialog/TgfDialog";
import "./agencyUser.scss";
import {
    TgfFormComponent
} from "../../../../core/kendo/form/TgfFormComponent/components/TgfFormComponent/TgfFormComponent";
import {agencyUserSchema} from "./formValidationSchemas/agencyUserSchema";
import _ from "lodash";
import {Grid} from "@material-ui/core";
import {
    TgfFormDropdownListInput
} from "../../../../core/kendo/form/TgfFormComponent/components/inputs/TgfFormDropdownListInput";
import {TgfFormCustom} from "../../../../core/kendo/form/TgfFormComponent/components/custom/TgfFormCustom";
import {
    TgfFormGenericButton
} from "../../../../core/kendo/form/TgfFormComponent/components/buttons/TgfFormGenericButton";
import {TgfFormSubmitButton} from "../../../../core/kendo/form/TgfFormComponent/components/buttons/TgfFormSubmitButton";
import {useTgfRequest} from "../../../../core/hooks/useTgfRequest";

export const EditAgencyUserModal = (props) => {
    const {
        closeEditAgencyUserModal,
        loadAgencyAccessFilters,
        loadHeadOfAgencyCount,
        agencyAccessFilters,
        headOfAgencyCount,
        openDeactivationModal,
        selectedAssociate
    } = props;

    // initial load
    useEffect(() => {
        loadAgencyAccessFilters();
        loadHeadOfAgencyCount();
    }, []);

    useEffect(() => {
        if (selectedAssociate.isHeadOfAgency) {
            selectedAssociate.accessFilterId = 0;
        }
    }, [agencyAccessFilters]);

    const [loadFormData] = useTgfRequest({
        onRequest: async () => {
            return {
                entity: _.cloneDeep(selectedAssociate),
                additionalData: {
                    headOfAgency: headOfAgencyOptions,
                    accessFilter: agencyAccessFilters,
                }
            };
        },
        bubbleError: true
    });

    const headOfAgencyOptions = [
        {id: true, display: "Yes"},
        {id: false, display: "No"},
    ];

    const [handleSubmit] = useTgfRequest({
        onRequest: async (values) => {
            if (values) {
                const newAssociateValues = _.cloneDeep(values?.entity);

                newAssociateValues.actionRoleId = newAssociateValues.isHeadOfAgency ? 2 : 3;
                if (newAssociateValues.isHeadOfAgency) newAssociateValues.accessFilterId = 1;

                await window.shell.gateway.updateAssociateSecurityContext(
                    newAssociateValues.id,
                    newAssociateValues.actionRoleId,
                    newAssociateValues.accessFilterId,
                );

                closeEditAgencyUserModal();
            }
        },
        successToast: "Agency user changes saved.",
        errorToast: "Error. Please contact IT.",
        bubbleError: true
    });

    return (
        <TgfDialog
            title={`Edit Agency User`}
            onClose={closeEditAgencyUserModal}
            width={600}
        >
            <TgfFormComponent
                loadFormData={loadFormData}
                validationSchema={agencyUserSchema}
                onSubmit={handleSubmit}
            >
                <Grid container spacing={2} alignItems={"center"}>
                    <Grid item xs={12}>
                        <TgfFormCustom
                            render={(tgfFormContext) => {
                               return  <>
                                    <label className="modal-label">
                                        User:
                                    </label>
                                    {' '}{tgfFormContext.values?.entity?.firstName}{' '}{tgfFormContext.values?.entity?.lastName}
                                </>
                            }

                            }
                        />
                    </Grid>
                    <Grid item xs={12}>
                        <TgfFormDropdownListInput
                            label="Head of Agency"
                            name="entity.isHeadOfAgency"
                            dataOptionsPath="additionalData.headOfAgency"
                            disabledHandler={(tgfFormContext) => {
                                // Not demote-able if they are the only Head of Agency or a Queen.
                                return tgfFormContext.values?.entity?.isHeadOfAgency && (headOfAgencyCount === 1 || tgfFormContext.values?.entity?.roleName === 'Queen');
                            }}
                        />
                    </Grid>
                    <Grid item xs={12}>
                        <TgfFormDropdownListInput
                            label="Visibility Permission"
                            name="entity.accessFilterId"
                            dataOptionsPath="additionalData.accessFilter"
                            disabledHandler={(tgfFormContext) => tgfFormContext.values?.entity?.isHeadOfAgency}
                            visibleHandler={(tgfFormContext) => !tgfFormContext.values?.entity?.isHeadOfAgency}
                        />
                    </Grid>
                    <Grid item xs={12}>
                        <TgfFormGenericButton
                            onClick={(tgfFormContext) => {
                                openDeactivationModal();
                            }}
                            disabledHandler={(tgfFormContext) => tgfFormContext.values?.entity?.isHeadOfAgency}
                            visibleHandler={(tgfFormContext) => !tgfFormContext.values?.entity?.isHeadOfAgency}
                        >DEACTIVATE CRM ACCESS FOR THIS USER</TgfFormGenericButton>
                    </Grid>
                    <Grid item xs={2}>
                        <TgfFormSubmitButton
                            disabledFunction={(tgfFormContext) => {
                                const disabled = (!tgfFormContext.canEdit || !tgfFormContext.isValid || !tgfFormContext.isDirty) ||
                                    (tgfFormContext.initialValues?.entity?.isHeadOfAgency === tgfFormContext.values?.entity?.isHeadOfAgency &&
                                        tgfFormContext.initialValues?.entity?.accessFilterId === tgfFormContext?.values?.entity?.accessFilterId);
                                return disabled;
                            }}
                        >SAVE</TgfFormSubmitButton>
                    </Grid>
                    <Grid item xs={2}>
                        <TgfFormGenericButton
                            onClick={(tgfFormContext) => {
                                closeEditAgencyUserModal();
                            }}
                        >CANCEL</TgfFormGenericButton>
                    </Grid>
                </Grid>
            </TgfFormComponent>
        </TgfDialog>
    )
        ;
};
