import React from "react";
import AppMuiCard from "../../../../../../core/components/cards/AppCard";
import {Grid} from "@material-ui/core";
import TgfTextBox from "../../../../../../core/kendo/form/inputs/TgfTextBox";
import {useDispatch} from "react-redux";
import TgfButtonBasic from "../../../../../../core/kendo/buttons/TgfButtonBasic/TgfButtonBasic";

const DatCredsCard = (props) => {
    const personalDispatch = useDispatch();

    const handleTextBoxChange = (e) => {
        props.setFormValues({
            ...props.formValues,
            datCredentials: {
                ...props.formValues.datCredentials,
                username: e.target.value
            }
        });
        props.setIsDirty(true);
    }

    const verifyDatCredentials = async () => {
        try {
            personalDispatch(window.shell.actions.sys.processStart('verifyDatCredentials'));
            const result = await window.shell.gateway.verifyDatCredentials(props.formValues?.datCredentials?.username)
            if(result === true) {
                personalDispatch(await window.shell.actions.sys.sendSnackbarMessage({content: "Credentials successfully authenticated"}))
            } else {
                personalDispatch(await window.shell.actions.sys.sendSnackbarMessage({
                    content: 'Failed to authenticate',
                    color: 'error'
                }));
            }

        } catch (e) {
            console.log(e);
        } finally {
            personalDispatch(window.shell.actions.sys.processComplete('verifyDatCredentials'));
        }
    }


    return (
        <AppMuiCard title={"Dat Privilege - Credentials"}>
                <Grid container spacing={2} alignItems={"flex-end"}>
                    <Grid item xs={12}>
                        <TgfTextBox
                            label={"Email / Username"}
                            id={"username"}
                            name={"username"}
                            value={props.formValues?.datCredentials?.username}
                            onChange={handleTextBoxChange}
                            maxLength={50}
                        />
                    </Grid>
                    {/*Filling space to push button to end.*/}
                    <Grid item style={{flexGrow: 1, height: 65}}></Grid>
                    <Grid item>
                        <TgfButtonBasic
                            onClick={verifyDatCredentials}
                        >VERIFY</TgfButtonBasic>
                    </Grid>
                </Grid>
        </AppMuiCard>
    )
}

export default DatCredsCard;