import {Grid} from "@material-ui/core";
import React from "react";
import TgfDialog from "../../../../../core/kendo/dialog/TgfDialog";
import TgfButtonBasic from "../../../../../core/kendo/buttons/TgfButtonBasic/TgfButtonBasic";

export const SameDayPickupWarningModal = (props) => {

    const renderTimeZone = (timeZone) => {
        if(timeZone?.timeZoneId) {
            return new Intl.DateTimeFormat('en-US', {
                dateStyle: 'short',
                timeStyle: 'short',
                hour12: true,
                timeZone: timeZone.timeZoneId
            }).format(new Date());
        } else {
            return "No date/time provided.";
        }
    }

    return (
        <TgfDialog title={props?.title}
                   onClose={props.onClose}
                   width={"60vw"}>
            <Grid container spacing={2} justifyContent={"center"}>
                <Grid item xs={12} style={{textAlign: "center"}}>
                    <p><strong>IMPORTANT</strong></p>
                    <p>Based off the selected Pickup Date, this is a same-day pickup.</p>
                    <p>For same-day pickups, the selected "Ready Time" for the Shipper MUST be a minimum of 1 hour in the future from the time the shipment is booked. <br/>Otherwise, pickup issues can likely occur.</p>
                    <p>Remember to select the time of the involved shipper's time zone.</p>
                    <p><strong><span style={{color: "#2b55ff"}}>Shipper's Current Date and Time (based off entered zip/postal code):</span> {renderTimeZone(props.locationTimeZones.originTimeZone)} (per Google)</strong></p>

                </Grid>
                <Grid item>
                <TgfButtonBasic
                        onClick={props.onClose}
                        themeColor={"error"}
                    >
                        GO BACK
                    </TgfButtonBasic>
                </Grid>
                <Grid item>
                    <TgfButtonBasic
                        onClick={() => {props.stepForward(); props.onClose();}}
                    >
                        CONTINUE
                    </TgfButtonBasic>
                </Grid>
            </Grid>
        </TgfDialog>
    );
};