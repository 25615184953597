export const MONTHS = {
    JANUARY: { name: "January", number: 1, abbreviation: "Jan" },
    FEBRUARY: { name: "February", number: 2, abbreviation: "Feb" },
    MARCH: { name: "March", number: 3, abbreviation: "Mar" },
    APRIL: { name: "April", number: 4, abbreviation: "Apr" },
    MAY: { name: "May", number: 5, abbreviation: "May" },
    JUNE: { name: "June", number: 6, abbreviation: "Jun" },
    JULY: { name: "July", number: 7, abbreviation: "Jul" },
    AUGUST: { name: "August", number: 8, abbreviation: "Aug" },
    SEPTEMBER: { name: "September", number: 9, abbreviation: "Sep" },
    OCTOBER: { name: "October", number: 10, abbreviation: "Oct" },
    NOVEMBER: { name: "November", number: 11, abbreviation: "Nov" },
    DECEMBER: { name: "December", number: 12, abbreviation: "Dec" }
};