import React from "react";
import {Tooltip} from "@material-ui/core";
import {useTgfFormContext} from "../../hooks/useFormContext";
import TgfButtonBasic from "../../../../buttons/TgfButtonBasic/TgfButtonBasic";
import PropTypes from "prop-types";
import useUniqueId from "../../../../../hooks/useUniqueId";

export const TgfFormResetButton = (props) => {
    const tgfFormContext = useTgfFormContext();
    const uniqueId = useUniqueId("tgf-form-reset-button");
    const disabled = (props.disabledHandler ?
        props.disabledHandler(tgfFormContext)
        : (!tgfFormContext.canEdit || !tgfFormContext.isDirty));
    const visible = (props.visibleHandler ? props.visibleHandler(tgfFormContext) : true);

    const internalHandleClick = async () => {
        if(props.onClick) {
            await props.onClick(tgfFormContext);
        } else {
            await tgfFormContext.handleReset();
        }
    }

    let button = (
        <TgfButtonBasic
            id={props.id ?? uniqueId}
            themeColor={props?.color ?? "warning"}
            onClick={internalHandleClick}
            disabled={disabled}
        >
            {props.children}
        </TgfButtonBasic>
    );

    if (props.tooltip && !disabled) button = <Tooltip title={props.tooltip}>{button}</Tooltip>

    return visible ? button : null;
};

TgfFormResetButton.propTypes = {
    /**
     * Function that can determine whether the button should be disabled.
     * It receives the form context and returns a boolean value indicating whether the button should be disabled.
     * If not provided, the button will be disabled if the form cannot be edited, is invalid, or is not dirty.
     *
     * @type {Function|null}
     * @param {Object} formContext - The context of the form (includes form validation, edit mode, etc.).
     * @returns {boolean} - Whether the button should be disabled.
     */
    disabledHandler: PropTypes.func,

    /**
     * Function that determines whether the button should be visible or not.
     * It receives the form context and returns a boolean value indicating whether the button should be visible.
     * If not provided, the button will always be visible.
     *
     * @type {Function|null}
     * @param {Object} formContext - The context of the form (includes form validation, edit mode, etc.).
     * @returns {boolean} - Whether the button should be visible.
     */
    visibleHandler: PropTypes.func,

    /**
     * Text or element to be displayed as a tooltip when hovering over the button.
     * The tooltip will only be shown if the button is not disabled.
     *
     * @type {string|null}
     */
    tooltip: PropTypes.string,

    /**
     * Function to handle the button click event.
     * It receives the form context as an argument and is called when the button is clicked.
     * If not provided, the default `handleReset` function of the form context will be invoked.
     *
     * @type {Function|null}
     * @param {Object} formContext - The context of the form (includes form validation, edit mode, etc.).
     * @returns {Promise} - A promise for handling the edit click action.
     */
    onClick: PropTypes.func,

    /**
     * The child elements to be displayed inside the button.
     * Typically, this will be text.
     *
     * @type {React.ReactNode}
     */
    children: PropTypes.node.isRequired,
};