import React, {useEffect, useRef} from 'react';
import {Route, Switch} from 'react-router-dom';

import AgingShipmentHistoryPage from './areas/address-book/AgingShipmentHistoryPage';
import BillingSettingsPage from './areas/address-book/BillingSettingsPage';
import BillingSettingsPageNew from './areas/address-book/BillingSettingsPageNew/BillingSettingsPage';
import ContactsPage from './areas/address-book/ContactsPage';
import DetailsPage from './areas/address-book/DetailsPage';
import AddressBookDocumentsPage from './areas/address-book/DocumentsPage';
import FinancialHealthPage from './areas/address-book/FinancialHealthPage';
import AddressBookListingPage from './areas/address-book/ListingPage';
import NotesPage from './areas/address-book/NotesPage';
import AddressBookCompanyOutcomeStreamPage from './areas/address-book/OutcomeStreamPage/OutcomeStreamPage';
import ShipmentHistoryPage from './areas/address-book/ShipmentHistoryPage';
import CarrierCompliancePage from './areas/carriers/CompliancePage';
import CarrierDetailPage from './areas/carriers/DetailsPage';
import CarrierDocumentsPage from './areas/carriers/DocumentsPage';
import CarrierHistoryPage from './areas/carriers/HistoryPage';
import CarrierNotesPage from './areas/carriers/NotesPage';
import HomePage from './areas/home/HomePage';
import ARShipmentFinancialsPage from "./areas/receivables/ar-shipment-financials";
import InvoiceMemoLineItemsPage from "./areas/receivables/invoice-memo-line-items";
import InvoiceMemosPage from "./areas/receivables/invoice-memos";
import PaymentApplicationsPage from "./areas/receivables/payment-applications";
import PaymentsPage from "./areas/receivables/payments";
import AuthorizedRoute from './areas/securityV2/AuthorizedRoute';
import AdministratorRoute from './areas/securityV2/AdministratorRoute';
import LoginPage from './areas/security/LoginPage';
import AccountingPage from "./areas/shipments/AccountingPage/AccountingPage";
import ShipmentAuditingPage from './areas/shipments/AuditingPage/AuditingPage';
import ShipmentContentsPage from './areas/shipments/ContentsPage/ContentsPage';
import ShipmentCreateDocsPage from './areas/shipments/CreateDocsPage/CreateDocsPage';
import ShipmentDetailsPage from './areas/shipments/DetailsPage/DetailsPage';
import ShipmentDocumentsPage from './areas/shipments/DocumentsPage/DocumentsPage';
import ShipmentDuplicationPage from './areas/shipments/DuplicationPage/DuplicationPage';
import NewShipmentFinancialPage from './areas/shipments/FinancialPageNew/FinancialPage';
import ShipmentListingPage from './areas/shipments/ListingPage/ListingPage';
import ShipmentLoadTrackingPage from './areas/shipments/LoadTrackingPage/LoadTrackingPage';
import MultiTransPage from "./areas/shipments/MultiTransPage/MultiTransPage";
import ShipmentMultipleStopsPage from './areas/shipments/MultipleStopsPage';


//Reporting route section
import CompanyGrossMarginPage from './areas/admin/CompanyGrossMargin';
import CarriersList from './areas/reporting/AllCarriers';
import CustomerReport from './areas/reporting/CustomerReport';
import InvoiceAging from './areas/reporting/InvoiceAging';
import InvoiceAgingSummaryPage from './areas/reporting/InvoiceAgingSummary/InvoiceAgingSummaryPage';
import LaneLookup from './areas/reporting/LaneLookUp';
import LastShipmentReport from "./areas/reporting/LastShipmentReport/LastShipmentReport";
import MissingCreditAppPage from './areas/reporting/MissingCreditApp';
import MissingPrimaryContactsPage from './areas/reporting/MissingPrimaryContacts';
import MonthlyGrossMarginPage from './areas/reporting/MonthlyGrossMargin';
import Reports from './areas/reporting/Reports';
import SearchLoads from './areas/reporting/SearchLoads';

//Admin route section
import Admin from './areas/admin';
import AutomatedInvoiceQueuePage from './areas/admin/AutomatedInvoiceQueue/AutomatedInvoiceQueuePage';
import AdminCommissionCenterPage from './areas/admin/CommissionCenter/CommissionsCenterPage';
import CommissionSummary from './areas/admin/CommissionsSummary';
import CustomerPaymentsPage from './areas/admin/CustomerPayments';
import DashboardPage from './areas/admin/Dashboard';
import HomeSettingsPage from "./areas/admin/HomeSettingsPage";
import InvoicingExceptionWorkflowQueuePage
    from './areas/admin/InvoicingExceptionWorkflowQueue/InvoicingExceptionWorkflowQueuePage';
import BOLs from './areas/bols';
import CarrierListingPage from './areas/carriers/CarrierListing/CarrierListingPage';
import Error404Page from './areas/errors/Error404Page';
import Help from './areas/help';
import ReportCommissionCenterPage from './areas/reporting/CommissionCenter/CommissionCenterPage';
import AgencyCommissionCenterPage from './areas/agency-admin/CommissionCenter/CommissionCenterPage';
import GlobalSnackbar from './crm/components/GlobalSnackbar';
import GlobalSpinner from './crm/components/GlobalSpinner';

import './App.scss';
import CollectionsPage from "./areas/address-book/CollectionsPage/CollectionsPage";
import FromToHistoryPage from "./areas/address-book/FromToHistory/FromToHistoryPage";
import NotesAutofillPage from "./areas/address-book/NoteAutofillPage/NotesAutofillPage";
import CollectorsAssignment from "./areas/admin/CollectorsAssignment/CollectorsAssignment";
import LockedDatesPage from "./areas/admin/LockedDates/LockedDatesPage";
import LockedDatesArBillingPage from "./areas/admin/LockedDatesArBilling/LockedDatesArBillingPage";
import ManageUsersDetailsPage from "./areas/admin/ManageUsers/DetailsPage/ManageUsersDetailsPage";
import ManageUsersListingPage from "./areas/admin/ManageUsers/ListingPage/ManageUsersListingPage";
import ManualInvoicingReportPage from "./areas/admin/ManualInvoiceingReport/ManualInvoicingReportPage";
import RiskReviewReport from "./areas/admin/RiskReviewReport/RiskReviewReport";
import StandardReportsPage from "./areas/admin/StandardReports/StandardReportsPage";
import AutoProcessedCarrierInvoicesReportPage
    from "./areas/admin/TeraCrunch/AutoProcessedCarrierInvoicesReport/AutoProcessedCarrierInvoicesReportPage";
import ClosedReportPage from "./areas/admin/TeraCrunch/Closed/ClosedReportPage";
import DoNotPayReportPage from "./areas/admin/TeraCrunch/DoNotPayReport/DoNotPayReportPage";
import QaFailedReportPage from "./areas/admin/TeraCrunch/QaFailedReport/QaFailedReportPage";
import QaManualReviewReportPage from "./areas/admin/TeraCrunch/QaManualReviewReport/QaManualReviewReportPage";
import UserSessions from "./areas/admin/UserSessions/UserSessions";
import AgentRewardsProgramReport from "./areas/reporting/agentRewardsProgramReport";
import BadDebtReport from "./areas/admin/badDebtReport/BadDebtReport";
import OpenLoadsBoardPage from "./areas/open-load-board/open-load-board";
import AutoInvoiceReport from "./areas/reporting/AutoInvoiceReport/AutoInvoiceReport";
import MarginContribution from "./areas/reporting/MarginContribution/MarginContribution";
import VoidShipmentReport from "./areas/reporting/VoidShipmentReport/VoidShipmentReport";
import SecurityBlanket from "./areas/security/SecurityBlanket";
import ComponentBuilder from "./core/ComponentBuilder";
import './tgf-kendo-theme/dist/scss/index.scss';

import {AgencyAdmin, AgencyUsers, CustomerAssignment, LeadAssignment} from "./areas/agency-admin";
import DoubleBrokeredReportPage from "./areas/admin/TeraCrunch/DoubleBrokeredReport/DoubleBrokeredReportPage";
import AssignmentPage from "./areas/address-book/AssignmentPage/AssignmentPage";
import LtlQuote from "./areas/ltl-quote/LtlQuote";
import {CustomerQuote} from "./areas/ltl-quote/CustomerQuote/CustomerQuote";
import QuoteOnly from "./areas/ltl-quote/qoute-only/QuoteOnly";
import LTLAutotrack from "./areas/shipments/LTLAutotrack/LTLAutotrack";
import ProductsPage from "./areas/address-book/ProductsPage/ProductsPage";
import ShipmentHistoryReport from "./areas/reporting/ShipmentHistoryReport/ShipmentHistoryReport";
import CarrierContactReport from "./areas/reporting/CarrierContactReport/CarrierContactReport";
import AgencyMonthlyGM from "./areas/agency-admin/MonthlyGM/AgencyMonthlyGM";
import BusinessDaysPage from "./areas/admin/BusinessDays/BusinessDaysPage";
import AppBackToTopButton from "./core/components/AppButton/AppBackToTopButton";
import PersonnelDirectoryReport from "./areas/admin/PersonnelDirectoryReport/PersonnelDirectoryReport";

const Noop = (props) => null;

const App = (props) => {

    const {
        featureFlags,
        getFeatureState,
    } = props;

    const ref = useRef(window);

    useEffect(() => {
        getFeatureState();
    }, []);

    return (
        <React.Fragment>
            <Switch>

                <Route path={'/login'} component={LoginPage} />

                <SecurityBlanket>
                    <Switch>
                        <AuthorizedRoute exact path={'/'} component={HomePage} />
                        <AuthorizedRoute path={'/logout'} component={Noop} />

                        <AuthorizedRoute
                            path={'/address-book/listing/:categoryTypeId?'} component={AddressBookListingPage}
                        />
                        <AuthorizedRoute path={'/address-book/:id/details'} component={DetailsPage} />
                        <AuthorizedRoute path={'/address-book/:id/documents'} component={AddressBookDocumentsPage} />
                        <AuthorizedRoute path={'/address-book/:id/contacts'} component={ContactsPage} />
                        <AuthorizedRoute path={'/address-book/:id/notes'} component={NotesPage} />
                        <AuthorizedRoute path={'/address-book/:id/billing'} component={BillingSettingsPage} />
                        <AuthorizedRoute path={'/address-book/:id/billing-new'} component={BillingSettingsPageNew} />
                        <AuthorizedRoute path={'/address-book/:id/shipment-history'} component={ShipmentHistoryPage} />
                        <AuthorizedRoute
                            path={'/address-book/:id/aging-shipment-history'} component={AgingShipmentHistoryPage}
                        />
                        <AuthorizedRoute path={'/address-book/:id/financial-health'} component={FinancialHealthPage} />
                        <AuthorizedRoute path={'/address-book/:id/from-to-history'} component={FromToHistoryPage} />
                        <AuthorizedRoute
                            path={'/address-book/:id/outcome-stream'} component={AddressBookCompanyOutcomeStreamPage}
                        />
                        <AuthorizedRoute path={'/address-book/:id/collections'} component={CollectionsPage} />
                        <AuthorizedRoute path={'/address-book/:id/notes-autofill'} component={NotesAutofillPage} />
                        <AuthorizedRoute path={'/address-book/:id/assignment'} component={AssignmentPage} />
                        <AuthorizedRoute path={'/address-book/:id/products'} component={ProductsPage} />

                        <AuthorizedRoute path={'/carrier/listing'} component={CarrierListingPage} />
                        <AuthorizedRoute path={'/carrier/:id/details'} component={CarrierDetailPage} />
                        <AuthorizedRoute path={'/carrier/:id/compliance'} component={CarrierCompliancePage} />
                        <AuthorizedRoute path={'/carrier/:id/documents'} component={CarrierDocumentsPage} />
                        <AuthorizedRoute path={'/carrier/:id/history'} component={CarrierHistoryPage} />
                        <AuthorizedRoute path={'/carrier/:id/notes'} component={CarrierNotesPage} />

                        <AuthorizedRoute path={'/open-loads-board/shipments'} component={OpenLoadsBoardPage} />
                        <AuthorizedRoute path={'/open-loads-board/dat-postings'} component={OpenLoadsBoardPage} />
                        <AuthorizedRoute path={'/open-loads-board/its-postings'} component={OpenLoadsBoardPage} />

                        <AuthorizedRoute path={'/shipment/listing/:trackedSet'} component={ShipmentListingPage} />
                        <AuthorizedRoute path={'/shipment/:id/create-docs'} component={ShipmentCreateDocsPage} />
                        <AuthorizedRoute path={'/shipment/:id/details'} component={ShipmentDetailsPage} />
                        <AuthorizedRoute path={'/shipment/:id/duplicate'} component={ShipmentDuplicationPage} />
                        <AuthorizedRoute path={'/shipment/:id/contents'} component={ShipmentContentsPage} />
                        <AuthorizedRoute path={'/shipment/:id/load-tracking'} component={ShipmentLoadTrackingPage} />
                        <AuthorizedRoute path={'/shipment/:id/documents'} component={ShipmentDocumentsPage} />
                        <AuthorizedRoute path={'/shipment/:id/ltl-autotrack'} component={LTLAutotrack} />

                        <AuthorizedRoute path={'/shipment/listing/:trackedSet'} component={ShipmentListingPage} />
                        <AuthorizedRoute path={'/shipment/:id/create-docs'} component={ShipmentCreateDocsPage} />
                        <AuthorizedRoute path={'/shipment/:id/details'} component={ShipmentDetailsPage} />
                        <AuthorizedRoute path={'/shipment/:id/duplicate'} component={ShipmentDuplicationPage} />
                        <AuthorizedRoute path={'/shipment/:id/contents'} component={ShipmentContentsPage} />
                        <AuthorizedRoute path={'/shipment/:id/load-tracking'} component={ShipmentLoadTrackingPage} />
                        <AuthorizedRoute path={'/shipment/:id/documents'} component={ShipmentDocumentsPage} />
                        <AuthorizedRoute path={'/shipment/:id/ltl-autotrack'} component={LTLAutotrack} />

                        <AuthorizedRoute path={'/shipment/:id/financial'} component={NewShipmentFinancialPage} />


                        <AuthorizedRoute path={'/shipment/:id/auditing'} component={ShipmentAuditingPage} />
                        <AuthorizedRoute path={'/shipment/:id/multiple-stops'} component={ShipmentMultipleStopsPage} />
                        <AuthorizedRoute path={'/shipment/:id/accounting'} component={AccountingPage} />
                        <AuthorizedRoute path={'/shipment/:id/multi-transactions'} component={MultiTransPage} />

                        <AuthorizedRoute exact path={'/bols'} component={BOLs} />
                        <AuthorizedRoute exact path={'/support/help'} component={Help} />

                        <AuthorizedRoute exact path={'/reports'} component={Reports} />
                        <AuthorizedRoute path={'/reports/customer-report'} component={CustomerReport} />
                        <AuthorizedRoute path={'/reports/invoice-aging'} component={InvoiceAging} />
                        <AuthorizedRoute path={'/reports/invoice-aging-summary'} component={InvoiceAgingSummaryPage} />
                        <AuthorizedRoute path={'/reports/lane-lookup'} component={LaneLookup} />
                        <AuthorizedRoute path={'/reports/search-loads'} component={SearchLoads} />
                        <AuthorizedRoute path={'/reports/monthly-gross-margin'} component={MonthlyGrossMarginPage} />
                        <AuthorizedRoute path={'/reports/carriers-list'} component={CarriersList} />
                        <AuthorizedRoute path={'/reports/missing-credit-app'} component={MissingCreditAppPage} />
                        <AuthorizedRoute path={'/reports/commission-center'} component={ReportCommissionCenterPage} />
                        <AuthorizedRoute
                            path={'/reports/missing-primary-contacts'} component={MissingPrimaryContactsPage}
                        />
                        <AuthorizedRoute path={'/reports/margin-contribution'} component={MarginContribution} />
                        <AuthorizedRoute path={'/reports/last-shipment-report'} component={LastShipmentReport} />
                        <AuthorizedRoute path={'/reports/shipment-history-report'} component={ShipmentHistoryReport} />
                        <AuthorizedRoute path={'/reports/carrier-contact-report'} component={CarrierContactReport} />
                        <AuthorizedRoute path={'/reports/cancelled-voided-report'} component={VoidShipmentReport} />
                        {featureFlags?.arReleaseHidden === false &&
                            <AuthorizedRoute path={'/reports/locked-shipments-report'} component={AutoInvoiceReport} />
                        }
                        <AuthorizedRoute
                            path={'/reports/agent-rewards-programs-report'} component={AgentRewardsProgramReport}
                        />

                        <AdministratorRoute exact path={'/admin'} component={Admin} />
                        <AdministratorRoute path={'/admin/commission-summary'} component={CommissionSummary} />
                        <AdministratorRoute exact path={'/admin/home-settings'} component={HomeSettingsPage} />
                        <AdministratorRoute path={'/admin/commission-center'} component={AdminCommissionCenterPage} />
                        <AdministratorRoute path={'/admin/customer-payments'} component={CustomerPaymentsPage} />
                        <AdministratorRoute path={'/admin/dashboard/:statusSet'} component={DashboardPage} />
                        <AdministratorRoute exact path={'/admin/manage-users'} component={ManageUsersListingPage} />
                        <AdministratorRoute path={'/admin/manage-users-new/:id'} component={ManageUsersDetailsPage} />
                        <AdministratorRoute path={'/admin/company-gross-margin'} component={CompanyGrossMarginPage} />
                        <AdministratorRoute
                            path={'/admin/automated-invoice-queue'} component={AutomatedInvoiceQueuePage}
                        />
                        {featureFlags?.arReleaseHidden === false &&
                            <AdministratorRoute
                                path={'/admin/manual-invoicing-report'} component={ManualInvoicingReportPage}
                            />
                        }
                        {featureFlags?.arReleaseHidden === true &&
                            <AdministratorRoute
                                path={'/admin/invoicing-exception-queue'}
                                component={InvoicingExceptionWorkflowQueuePage}
                            />
                        }
                        <AdministratorRoute path={'/admin/qa-manual-review'} component={QaManualReviewReportPage} />
                        <AdministratorRoute path={'/admin/do-not-pay'} component={DoNotPayReportPage} />
                        <AdministratorRoute path={'/admin/double-brokered'} component={DoubleBrokeredReportPage} />
                        <AdministratorRoute path={'/admin/qa-failed'} component={QaFailedReportPage} />
                        <AdministratorRoute
                            path={'/admin/auto-processed'} component={AutoProcessedCarrierInvoicesReportPage}
                        />
                        <AdministratorRoute path={'/admin/closed'} component={ClosedReportPage} />

                        <AdministratorRoute path={'/admin/standard-reports'} component={StandardReportsPage} />
                        <AdministratorRoute path={'/admin/user-sessions'} component={UserSessions} />
                        <AdministratorRoute path={'/admin/locked-dates'} component={LockedDatesPage} />
                        <AdministratorRoute path={'/admin/business-days'} component={BusinessDaysPage} />
                        <AdministratorRoute path={'/admin/locked-dates-billing'} component={LockedDatesArBillingPage} />
                        <AdministratorRoute path={'/admin/collectors-assignment'} component={CollectorsAssignment} />
                        <AdministratorRoute path={'/admin/bad-debt-report'} component={BadDebtReport} />
                        <AdministratorRoute path={'/admin/risk-review-report'} component={RiskReviewReport} />
                        <AdministratorRoute
                            path={'/admin/personnel-directory-report'} component={PersonnelDirectoryReport}
                        />

                        <AdministratorRoute path={'/receivables/invoice-memos'} component={InvoiceMemosPage} />
                        <AdministratorRoute
                            path={'/receivables/invoice-memo-line-items'} component={InvoiceMemoLineItemsPage}
                        />
                        <AdministratorRoute path={'/receivables/payments'} component={PaymentsPage} />
                        <AdministratorRoute
                            path={'/receivables/payment-applications'} component={PaymentApplicationsPage}
                        />
                        <AdministratorRoute
                            path={'/receivables/ar-shipment-financials/:id'} component={ARShipmentFinancialsPage}
                        />

                        <AuthorizedRoute exact path={'/ltl-quote'} component={LtlQuote} />
                        <AuthorizedRoute path={'/ltl-quote/customer'} component={CustomerQuote} />
                        <AuthorizedRoute path={'/ltl-quote/quote-only'} component={QuoteOnly} />

                        {/* Franchise Mode Routes */}
                        <AuthorizedRoute exact path={'/agency'} component={AgencyAdmin} />
                        <AuthorizedRoute path={'/agency/users'} component={AgencyUsers} />
                        <AuthorizedRoute path={'/agency/agency-commission'} component={AgencyCommissionCenterPage} />
                        <AuthorizedRoute path={'/agency/agency-gm'} component={AgencyMonthlyGM} />
                        <AuthorizedRoute path={'/agency/customer-assignment'} component={CustomerAssignment} />
                        <AuthorizedRoute path={'/agency/lead-assignment'} component={LeadAssignment} />
                        {/* end Franchise Mode Routes */}

                        <Route component={Error404Page} />
                    </Switch>
                </SecurityBlanket>
            </Switch>
            <GlobalSnackbar />
            <GlobalSpinner />
            <AppBackToTopButton scrollref={ref} />
        </React.Fragment>
    );
};

export default ComponentBuilder.wrap(App)
    .stateToProps((state, ownProps) => ({featureFlags: state.utility.featureFlags}))
    .dispatchToProps((shell, dispatch, getState) => {
        return {
            async getFeatureState() {
                dispatch(shell.actions.sys.processStart("App.featureFlags.load"));
                dispatch(await shell.actions.utility.loadFeatureFlags());
                dispatch(shell.actions.sys.processComplete("App.featureFlags.load"));
            },
        };
    })
    .build();
