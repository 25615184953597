import {useMemo} from 'react';
import {findByPath} from "../utils/FormUtils";

// Tgf form specific custom hook for extracting the value and fieldError from form context.
export const useTgfFormField = (name, tgfFormContext) => {
    return useMemo(() => ({
        value: findByPath(tgfFormContext.values, name),
        fieldError: findByPath(tgfFormContext.fieldErrors, name),
    }), [name, tgfFormContext.values, tgfFormContext.fieldErrors]);
};

