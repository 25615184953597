import {useDispatch} from "react-redux";

export const useToast = () => {
    const personalDispatch = useDispatch()

    const toastSuccess =  (message) => {
        (async () => personalDispatch(await window.shell.actions.sys.sendSnackbarMessage({ content: message, color: 'success'})))();

    }

    const toastError = (message) => {
        (async () => personalDispatch(await window.shell.actions.sys.sendSnackbarMessage({ content: message, color: 'error',})))();
    }

    return {
        toastSuccess,
        toastError
    }
}