import React, {useEffect, useRef} from 'react';
import PropTypes from 'prop-types';
import {Grid, Typography} from '@material-ui/core';
import AppCard from '../../../../core/components/cards/AppCard';
import {makeStyles} from "@material-ui/core/styles";
import AppTooltip from "../../../../core/components/AppTooltip";

const useStyles = makeStyles((theme) => ({
    root: {
        display: 'flex'
    },
    configContainer: {
        '&:last-child': {
            marginTop: theme.spacing(2)
        }
    },
    periodHeader: {
        borderBottom: `1px solid ${theme.palette.tgfGrey.fieldBorder}`,
    }
}));

const PayConfigurationCard = (props) => {
    const classes = useStyles();
    const ref = useRef();

    const {
        periodConfigurations
    } = props;

    useEffect(() => {
        if (periodConfigurations) {
            ref?.current?.refresh();
        }
    }, [periodConfigurations]);

    return (
        <AppCard title="Pay Configuration">
            {periodConfigurations &&
                <Grid container spacing={1}>
                    {
                        periodConfigurations.map((config, index) =>
                            <PeriodConfigurationDetail key={index} config={config} index={index} classes={classes} />)
                    }
                </Grid>
            }
        </AppCard>
    );
};

const PeriodConfigurationDetail = ({config, index, classes}) => {
    return (
        <Grid container className={classes.configContainer}>
            <Grid item xs={12} className={classes.periodHeader}>
                <Typography variant="h4">
                    {
                        index === 0 ?
                            'First Period' : 'Second Period'
                    }
                </Typography>
            </Grid>
            <Grid item xs={12} md={6}>
                <Typography variant="h6">Payment Type</Typography>
            </Grid>
            <Grid container item xs={12} md={6} justify={'flex-end'}>
                <Typography variant="h6">{config.paymentType}</Typography>
            </Grid>
            <Grid item xs={12} md={6}>
                <Typography variant="h6">Percentage</Typography>
            </Grid>
            <Grid container item xs={12} md={6} justify={'flex-end'}>
                <Typography variant="h6" color="primary">{config.commissionPercentage}</Typography>
            </Grid>
            {
                config.showFeeInformation &&
                <React.Fragment>
                    <Grid item xs={12} md={6}>
                        <Typography variant="h6">Minimum TGF Fee </Typography>
                    </Grid>
                    <Grid container item xs={12} md={6} justify={'flex-end'}>
                        <Typography variant="h6" color="primary"><AppTooltip
                            title={'For all shipments, Tallgrass collects this minimum fee for every $1000 of carrier cost.'}
                        ><span>$10.00</span></AppTooltip></Typography>
                    </Grid>
                </React.Fragment>
            }
        </Grid>
    );
};

PayConfigurationCard.propTypes = {
    paymentPercentage: PropTypes.number,
    paymentType: PropTypes.string
};

export default PayConfigurationCard;