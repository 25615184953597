import React from "react";
import {Button} from "@progress/kendo-react-buttons";
import "./TgfButtonBasic.css";

const TgfButtonBasic = React.forwardRef((props, ref) => {

    return (
            <Button
                {...props}
                ref={ref}
                style={props.style ?? {fontWeight: 500, padding: "6px 12px", minWidth: 75}}
                themeColor={props.themeColor ?? "primary"}
                className={`tgf-button ${props.className}`}
            >{props.children}</Button>
    )
})

export default TgfButtonBasic