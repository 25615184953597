import React, {forwardRef, useCallback, useEffect} from "react";
import TgfDataGrid from "../../../../../core/kendo/dataGrid/TgfDataGrid";
import {useDispatch} from "react-redux";
import {Grid} from "@material-ui/core";
import AppIconButton from "../../../../../core/components/AppButton/AppIconButton";
import {CheckCircle, FindInPage as FindInPageIcon} from "@material-ui/icons";
import DateUtils from "../../../../../core/utils/dateUtils/dateUtils";
import {PERSONNEL_ROLE_TYPES} from "../../../../../constants/personnel/personnel-role-types";
import {validateCanEditPersonnelRecords} from "../../../../../hubs/personaV2/selectors/validateCanEditPersonnelRecords";

const editCell = (props, onEditButtonClick, disabled) => {
    return <td>
        <AppIconButton
            Icon={FindInPageIcon}
            disabled={disabled ?? false}
            onClick={() => onEditButtonClick(props.dataItem)}/>
    </td>
}

const activeCell = (props) => {
    return <td className={"center"}>
        {!props.dataItem[props.field]
            ? <CheckCircle color={'primary'} fontSize={'small'}/>
            : null}
    </td>
}

const birthdayCell = (props) => {
    let formattedBirthday = "";
    if(props.dataItem?.birthdayMonth) formattedBirthday += `${DateUtils.getMonthNameByNumber(props.dataItem.birthdayMonth)}`;
    if(props.dataItem?.birthdayDay) formattedBirthday += ` ${props.dataItem.birthdayDay}`;
    return <td><span>{formattedBirthday}</span></td>
}

const PersonnelDirectoryListing = forwardRef((props, ref) => {

    const {llcFilter, roleFilter, showInactivePersonnel, hideTgfPersonnel} = props.filters;
    const canEditPersonnel = validateCanEditPersonnelRecords();
    const personalDispatch = useDispatch();

    const loadPersonnelDirectoryReportListing = async (odata) => {
        try {
            personalDispatch(window.shell.actions.sys.processStart('loadPersonnelDirectoryReportListing'));
            const data = await window.shell.gateway.getPageOfPersonnelDirectoryReport(odata);
            return data

        } catch (e) {
            console.log(e);
        } finally {
            personalDispatch(window.shell.actions.sys.processComplete('loadPersonnelDirectoryReportListing'));
        }
    }

    const onClickEditPersonnel = (data) => {
        props.setSelectedPersonnel(data);
        props.setShowPersonnelEditModal(true);
    };



    const gridColumns = [
        {
            title: 'First Name',
            field: 'firstName',
            type: 'text',
            width: 90
        },
        {
            title: 'Last Name',
            field: 'lastName',
            type: 'text',
            width: 90
        },
        {
            title: 'ID',
            field: 'crmId',
            type: 'text',
            width: 45
        },
        {
            title: 'EXT',
            field: 'businessPhoneExt',
            type: 'text',
            width: 45
        },
        {
            title: 'TGF Email',
            field: 'businessEmailAddress',
            type: 'text',
            width: 290
        },
        {
            title: 'Role',
            field: 'role',
            type: 'text',
            width: 115,
        },
        {
            title: 'Involved LLC',
            field: 'involvedLlc',
            type: 'text',
        },
        {
            title: 'TGF Personnel Position',
            field: 'position',
            type: 'text',
        },
        {
            title: 'Start Date',
            field: 'startDate',
            type: 'date',
            width: 80
        },
        {
            title: 'Birthday',
            field: 'birthdayMonth',
            type: 'custom',
            cell: (props) => birthdayCell(props),
            width: 90
        },
        {
            title: 'Location',
            field: 'displayedLocation',
            type: 'text',
            width: 160
        },
        {
            title: 'Active',
            field: 'isMarkedInactive',
            type: 'custom',
            cell: (props) => activeCell(props),
            width: 50
        },
        // edit / view button.
        {
            title: ' ',
            field: '',
            type: 'custom',
            cell: (props) => editCell(props, onClickEditPersonnel, !canEditPersonnel),
            width: 40,
            sortable: false
        },
    ];

    const applyAdditionalFilters = useCallback((odata) => {
        odata = odata ?? {
            top: 20,
            skip: 0,
        };
        const filter = {};
        if (llcFilter) filter.involvedLlc = llcFilter;
        if (roleFilter) filter.role = roleFilter;
        if (!showInactivePersonnel) filter.isMarkedInactive = false;
        if (hideTgfPersonnel) filter.role = {ne: PERSONNEL_ROLE_TYPES.TGF_PERSONNEL};

        odata.filter = {
            queenId: props.associateId,
            id: {
                ne: props.associateId
            }
        };

        odata.filter.and = filter;
        return odata;
    }, [llcFilter, roleFilter, showInactivePersonnel, hideTgfPersonnel]);

    useEffect(() => {
        ref.current.refresh();
    }, [applyAdditionalFilters]);

    return (
        <Grid container spacing={2}>
            <Grid item xs={12}>
                <TgfDataGrid
                    ref={ref}
                    onLoadDataPage={loadPersonnelDirectoryReportListing}
                    onApplyAdditionalDataStateAsOdata={applyAdditionalFilters}
                    gridColumns={gridColumns}
                    // sort={[{field: "blockedDate", dir: "desc"}]}
                    pageable={{
                        pageSize: 20,
                        buttonCount: 10,
                        pageSizes: [5, 10, 20, 50, 100, 500]
                    }}
                    skipInitialLoad={true}
                />
            </Grid>
        </Grid>
    )
})

export default PersonnelDirectoryListing
