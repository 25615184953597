import React from "react";
import {useTgfFormContext} from "../../hooks/useFormContext";
import {Label} from "@progress/kendo-react-labels";
import InfoIconTooltip from "../../../../../components/Tooltips/InfoIconTooltip/InfoIconTooltip";
import FieldError from "../../../../../../crm/components/inputs/FieldError";
import {useTgfFormField} from "../../hooks/useTgfFormField";
import PropTypes from 'prop-types';
import useUniqueId from "../../../../../hooks/useUniqueId";
import TgfAssociateComboBox from "../../../inputs/specialty/TgfAssociateComboBox";
import {ASSOCIATE_ROLES} from "../../../../../../constants/Associate/associate-roles";

export const TgfFormAssociateComboBoxInput = (props) => {
    const tgfFormContext = useTgfFormContext();
    const uniqueId = useUniqueId("tgf-form-combo-box-input");
    const { value, fieldError } = useTgfFormField(props.name, tgfFormContext);
    const disabled = (props.disabledHandler ? props.disabledHandler(tgfFormContext) : !tgfFormContext.canEdit);
    const visible = (props.visibleHandler ? props.visibleHandler(tgfFormContext) : true);

    const internalHandleChange = (e) => {
        if(props.onChange) {
            props.onChange(e, tgfFormContext.values, tgfFormContext.setValues); // (e, values, setValues)
            tgfFormContext.setIsDirty(true);
        } else {
            tgfFormContext.handleChange(props.name, e.target.value)
        }
    }

    return visible ? (
        <>
            <Label editorId={props.id ?? uniqueId}>{props.label}</Label>
            {props.tooltip && <InfoIconTooltip title={props.tooltip} />}
            <TgfAssociateComboBox
                {...props}
                id={props.id ?? uniqueId}
                label={undefined} // ensure label doesn't get passed down. We are using the one above.
                value={value}
                onChange={internalHandleChange}
                disabled={disabled}
                includeRoles={props?.includeRoles ?? [ASSOCIATE_ROLES.QUEEN.name]}
            />
            <FieldError shouldRender={tgfFormContext.canEdit}>{fieldError}</FieldError>
        </>
    ) : null;
};

TgfFormAssociateComboBoxInput.propTypes = {
    /**
     * The field name (string) is required.
     * This is the identifier used to fetch the value and field errors from the form context.
     *
     * @type {string}
     */
    name: PropTypes.string.isRequired,

    // /**
    //  * The path (string) to the data options for the dropdown list.
    //  * This path will be used to retrieve the dropdown options from the forms values.additionalData
    //  * Example dataOptionsPath={"additionalData.myDataOptionsList"}
    //  * @type {string}
    //  */
    // dataOptionsPath: PropTypes.string.isRequired,

    /**
     * Label text for the textarea (string).
     * This will be displayed next to the text area to describe the field.
     *
     * @type {string}
     */
    label: PropTypes.string,

    /**
     * Tooltip text (optional string).
     * This will be displayed as a tooltip when the user hovers over the input.
     *
     * @type {string}
     */
    tooltip: PropTypes.string,

    /**
     * A function to handle the disabled state (optional).
     * This function is passed the `formContext` and should return a boolean
     * indicating whether the field should be disabled or not.
     *
     * If not provided, the default behavior is to disable the field when
     * the form is **not** in edit mode.
     *
     * @type {Function}
     * @param {Object} formContext - The current form context`.
     * @returns {boolean} - A boolean indicating whether the field should be disabled.
     */
    disabledHandler: PropTypes.func,

    /**
     * Function that determines whether the input should be visible or not.
     * It receives the form context and returns a boolean value indicating whether the button should be visible.
     * If not provided, the input will always be visible.
     *
     * @type {Function}
     * @param {Object} formContext - The context of the form (includes form validation, edit mode, etc.).
     * @returns {boolean} - Whether the button should be visible.
     */
    visibleHandler: PropTypes.func,

    /**
     * A change handler function (optional).
     * This function is called when the input value changes.
     *
     * It receives:
     * - The change event (`e`)
     * - The form's current `values` object
     * - The form's `setValues` function to update the form state
     *
     * If not provided, the default `handleChange` from the form context will be used
     * to update the value.
     *
     * @type {Function}
     * @param {Object} e - The event object from the input change.
     * @param {Object} values - The current form values object.
     * @param {Function} setValues - A function to update the form values.
     */
    onChange: PropTypes.func,

    /**
     * An array of associate roles.
     * If provided, the combobox will filter by the provided roles. defaults to ["Queen"]
     *
     * @type {Array<string>}
     */
    includeRoles: PropTypes.arrayOf(PropTypes.string),
};
