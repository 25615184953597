import {useCallback, useEffect, useMemo, useState} from "react";
import useFieldValidator from "../../../../../../crm/components/customHooks/form/validation/useFieldValidator2.0";
import {useTgfRequest} from "../../../../../hooks/useTgfRequest";
import {TgfFormContext} from "../../context/tgfFormContext";
import {assignValue} from "../../utils/FormUtils";
import PropTypes from "prop-types";
import _ from "lodash";
import {isEmptyObject} from "../../../../../utils/ObjUtils/objUtils";

export const TgfFormComponent = ({reloadDeps = [], ...props}) => {
    const [isValid, setIsValid] = useState(true);
    const [isUsingEditButton, setIsUsingEditButton] = useState(false);
    const [canEdit, setCanEdit] = useState(false);
    const [isDirty, setIsDirty] = useState(false);
    const [values, setValues] = useState({});
    const [initialValues, setInitialValues] = useState({});
    const fieldErrors = useFieldValidator(props.validationSchema || null, values, setIsValid);

    const [getFormData, getFormDataIsLoading, getFormDataError] = useTgfRequest({
        onRequest: async () => {
            const result = await props?.loadFormData();
            setInitialValues(_.cloneDeep(result));
            setValues(_.cloneDeep(result));
            setIsDirty(false);
        },
    });

    useEffect(() => {
        getFormData()
    }, [...reloadDeps]);

    useEffect(() => {
        if(isUsingEditButton) {
            setCanEdit(false);
        } else if(!isEmptyObject(initialValues) && !isUsingEditButton) {
            setCanEdit(true);
        }
    }, [isUsingEditButton, initialValues]);

    const [submitForm, submitFormLoading, submitFormError ] = useTgfRequest({
        onRequest: async () => {
            await props.onSubmit(values);
            await getFormData(); // reload.
        },
    });


    useEffect(() => {
        if(_.isEqual(values, initialValues)) setIsDirty(false);
    }, [values, initialValues]);

    const handleChange = useCallback((path, value) => {
        assignValue(values, path, value);
        setValues({ ...values });
        setIsDirty(true);
    }, [values]);

    const handleSubmit = useCallback(async () => {
        await submitForm();
    }, [submitForm]);

    const handleReset = useCallback(async () => {
        setValues(_.cloneDeep(initialValues));
        setIsDirty(false);
    }, [initialValues]);

    const context = useMemo(() => ({
        values,
        setValues,
        fieldErrors,
        isValid,
        setIsValid,
        initialValues,
        setInitialValues,
        canEdit,
        setCanEdit,
        isDirty,
        setIsDirty,
        handleSubmit,
        handleChange,
        handleReset,
        isUsingEditButton,
        setIsUsingEditButton,
    }), [values, initialValues, isValid, fieldErrors, canEdit, isDirty]);

    return (
        (getFormDataIsLoading && props?.loadingFallback) ?
             props.loadingFallback
        :
        <TgfFormContext.Provider value={context}>
            {props.children}
        </TgfFormContext.Provider>

    )
};

TgfFormComponent.propTypes = {
    /**
     * A component or element that will be displayed while the form data is loading.
     * We already have a loading spinner that runs, so unless there is a direct desire to display something else while loading,
     * I wouldn't worry about making use of this.
     *
     * @type {React.ReactNode}
     */
    loadingFallback: PropTypes.node,

    /**
     * Am async function that returns the form data (optional. See Note).
     * This function should fetch the necessary data to populate the form.
     * NOTE - if the form does not require a fecth to the server (write only), then simply have your
     * loadFormData function return your initial form data shape.
     *
     * @type {Function}
     * @returns {Promise<Object>} A promise that resolves with the form data.
     */
    loadFormData: PropTypes.func.isRequired,

    /**
     * An array of dependencies that will trigger a re-fetch of the form data
     * whenever any of the values in the array change. This is passed to the `useEffect`
     * hook that handles reloading the form data.
     *
     * @type {Array<*>}
     */
    reloadDeps: PropTypes.array,

    /**
     * A validation schema for the form (optional).
     * If provided, this will be used to validate the form fields. Can be null if no validation is needed.
     *
     * @type {Object|null}
     */
    validationSchema: PropTypes.object,

    /**
     * An async function that will be invoked when the form is submitted.
     * This function receives the form values as an argument.
     *
     * @type {Function}
     * @param {Object} values - The form values to submit.
     */
    onSubmit: PropTypes.func.isRequired,

    /**
     * The child components of the form. These components will consume the form context
     * (e.g., `values`, `fieldErrors`, `handleChange`, etc.) to interact with the form.
     *
     * @type {React.ReactNode}
     */
    children: PropTypes.node.isRequired,
};