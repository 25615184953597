import * as React from "react";
import {dateConverter} from "../../../../areas/open-load-board/includes/listingCells/multiUseListingCells";

const DateCell = (cellProps) => {
    let date = null;
    if (cellProps.dataItem[cellProps.field] !== null) {
        // ignores / does not expect a valid time to be present.

        // UTC date string  => JS date obj w local time zone.
        date = new Date(cellProps.dataItem[cellProps.field]);
        // JS date obj w local time zone => JS Date w local time zone, but setting from UTC converted prev date obj values.
        date = new Date(date.getUTCFullYear(), date.getUTCMonth(), date.getUTCDate());

        date = new Intl.DateTimeFormat('en-US').format(new Date(date)).split('/'); // display as mm/dd/yyyy
        date = dateConverter(date);
    }

    return (
        <td>
            {date !== null ? date : ""}
        </td>
    );
};

export default DateCell;
