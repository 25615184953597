import React, {useEffect, useState} from 'react';
import {Grid} from '@material-ui/core';

import FullWidthLayout from '../../../core/layouts/FullWidthLayout';
import AppCard from '../../../core/components/cards/AppCard';
import AppMonthYear from '../../../core/components/inputs/AppDatePicker/AppMonthYear';
import GrossMarginTable from '../../../crm/components/associate/GrossMarginTable';
import LeftNav from '../LeftNav';
import computeMonthDateRange from '../../../core/utils/computeMonthDateRange';
import TotalMarginTable from "../../reporting/MonthlyGrossMargin/includes/TotalMarginTable";
import TotalMarginPercentage from "../../reporting/MonthlyGrossMargin/includes/TotalMarginPercentage";
import TotalMarginPaceTable from "../../../crm/components/tables/TotalMarginPaceTable";
import TotalRevenuePaceTable from "../../../crm/components/tables/TotalRevenuePaceTable";
import "./CompanyGrossMargin.scss";
import {usePageTitle} from "../../../crm/components/customHooks/misc/usePageTitle";
import {ref} from "yup";
import {useDispatch} from "react-redux";

const CompanyGrossMarginPage = (props) => {
    const personalDispatch = useDispatch();

    usePageTitle("TGF: Company GM Report");
    const [selectedDate, setSelectedDate] = React.useState(new Date());
    const [grossMarginBreakdown, setGrossMarginBreakdown] = useState();

    useEffect(() => {
        if (grossMarginBreakdown) {
            ref?.current?.refresh();
        }
    }, [grossMarginBreakdown]);

    const loadGrossMarginBreakdown = async (startDate, endDate) => {
        try {
            personalDispatch(window.shell.actions.sys.processStart('loadGrossMarginBreakdown'));
            const grossMarginBreakdown = await window.shell.gateway.getAssociateGrossMarginBreakdown({
                    startDate: startDate,
                    endDate: endDate,
                }
            );
            setGrossMarginBreakdown(grossMarginBreakdown);
        } catch (e) {
            console.log(e);
        } finally {
            personalDispatch(window.shell.actions.sys.processComplete('loadGrossMarginBreakdown'));
        }
    };

    React.useEffect(() => {
        const [startDate, endDate] = computeMonthDateRange(selectedDate);
        loadGrossMarginBreakdown(startDate, endDate);
    }, [selectedDate]);

    const handleMonthYearChange = (monthYearValue) => {
        if (monthYearValue.isValid()) {
            setSelectedDate(monthYearValue.toDate());
        }
    };

    return (
        <FullWidthLayout SideNav={LeftNav} title="Company Monthly Gross Margin Report">
            <Grid container spacing={2}>

                <Grid item xs={12} md={3}>
                    <AppCard>
                        <Grid container spacing={2}>
                            <Grid item xs={12} md={12}>
                                <label htmlFor="MonthYear">Month/Year:</label>
                                <AppMonthYear
                                    id="monthYear"
                                    openTo="year"
                                    views={["year", "month"]}
                                    inputVariant="outlined"
                                    value={selectedDate}
                                    onChange={handleMonthYearChange}
                                />
                            </Grid>

                        </Grid>
                    </AppCard>

                </Grid>
                <Grid container xs={4} spacing={1} className={"margin-container"}>
                    <Grid item xs={12}>
                        <TotalMarginTable
                            date={selectedDate}
                            grossMarginData={grossMarginBreakdown}
                        />
                    </Grid>
                    <Grid item xs={12}>
                        <TotalMarginPercentage
                            date={selectedDate}
                            grossMarginData={grossMarginBreakdown}
                        />
                    </Grid>
                </Grid>
                <Grid container xs={4} spacing={1} className={"margin-container"}>
                    <Grid item xs={12}>
                        <TotalMarginPaceTable
                            date={selectedDate}
                            grossMarginData={grossMarginBreakdown}
                        />
                    </Grid>
                    <Grid item xs={12}>
                        <TotalRevenuePaceTable
                            date={selectedDate}
                            grossMarginData={grossMarginBreakdown}
                        />
                    </Grid>
                </Grid>

                <Grid item xs={12}>
                    <GrossMarginTable
                        date={selectedDate}
                        grossMarginData={grossMarginBreakdown}
                    />
                </Grid>
            </Grid>

        </FullWidthLayout>
    );
};

export default CompanyGrossMarginPage;