import {Grid} from "@material-ui/core";
import AppMuiCard from "../../../../../core/components/cards/AppCard";
import React, {useEffect} from "react";
import TgfButtonBasic from "../../../../../core/kendo/buttons/TgfButtonBasic/TgfButtonBasic";
import {ShipmentContent} from "./includes/ShipmentContent/ShipmentContent";

export const ShipmentContents = (props) => {
    const {
        type,
        formValues,
        formData,
        setFormValues,
        setIsDirty,
        shipmentContentsAreValid,
        setShipmentContentsAreValid,
        defaultShipmentContent,
        activeStepIdx,
        disableAll = false,
        setStackableHasBeenToggled,
        setHazmatHasBeenToggled
    } = props;

    const handleAddShipmentContentsRow = () => {
        const newKey = Object.keys(formValues.shipmentContents).length;
        formValues.shipmentContents[newKey] = {...defaultShipmentContent};
        shipmentContentsAreValid.push(false);
        setShipmentContentsAreValid([...shipmentContentsAreValid]);
        setFormValues({
            ...formValues,
        });
        setIsDirty(true);
    };

    const calcTotalVolume = ({dimensionsLength, dimensionsWidth, dimensionsHeight, unitCount}) => {
        return (((dimensionsLength * dimensionsWidth * dimensionsHeight) / 1728) * unitCount);
    };

    const calcTotals = () => {
        let totalWeight = 0;
        let totalDensity = 0.00;
        let totalVolume = 0;

        for (let key in formValues.shipmentContents) {
            const {weightLbs = 0 } = formValues.shipmentContents[key];
            totalWeight += weightLbs;
            totalVolume += calcTotalVolume(formValues.shipmentContents[key]);
        }
        totalDensity = (totalWeight / totalVolume) || 0.00;

        setFormValues({
            ...formValues,
            totalWeight: totalWeight,
            totalDensity: totalDensity,
            totalVolume: totalVolume,
        });
    };

    useEffect(() => {
        calcTotals();
    }, [formValues.shipmentContents]);

    return (
        <AppMuiCard title={'Shipment Contents'}>
            <Grid container spacing={2} alignItems={"center"} justifyContent={"space-between"}>
                {Object.keys(formValues.shipmentContents).map((key, idx) => {
                    return <ShipmentContent
                        type={type}
                        key={key}
                        idx={idx}
                        id={key}
                        shipmentContentsAreValid={shipmentContentsAreValid}
                        setShipmentContentsAreValid={setShipmentContentsAreValid}
                        formValues={formValues}
                        formData={formData}
                        setFormValues={setFormValues}
                        setIsDirty={setIsDirty}
                        customerId={formValues.customer?.companyId}
                        activeStepIdx={activeStepIdx}
                        disableAll={disableAll}
                        setStackableHasBeenToggled={setStackableHasBeenToggled}
                        setHazmatHasBeenToggled={setHazmatHasBeenToggled}
                    />;
                })}
                {(activeStepIdx === 1 && !disableAll)  ?
                    <Grid item>
                        <TgfButtonBasic
                            onClick={handleAddShipmentContentsRow}>
                            Add Row +
                        </TgfButtonBasic>
                    </Grid>
                    :
                    <Grid item></Grid>
                }
                <Grid item className={"shipment-contents__totals"}>
                    <Grid container spacing={2} alignItems={"center"}>
                        <Grid item>
                            <span style={{fontWeight: "bold"}}>Total Weight (lbs) = {formValues.totalWeight.toLocaleString()}</span>
                        </Grid>
                        <Grid item>
                            <span style={{fontWeight: "bold"}}>Total Density = {formValues.totalDensity.toFixed(2)}</span>
                        </Grid>
                        <Grid item>
                            <span
                                style={{fontWeight: "bold"}}>Total Volume (cubic feet) = {formValues.totalVolume.toFixed(2)}</span>
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
        </AppMuiCard>
    );
};