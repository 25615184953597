import React from "react";
import "./agencyUser.scss";
import {Grid, Typography} from "@material-ui/core";
import {useTgfRequest} from "../../../../core/hooks/useTgfRequest";
import ConfirmationModal from "../../../../core/kendo/dialog/ConfimationModal";

export const DeactivateUserModal = (props) => {
    const {
        reloadGrid,
        closeEditAgencyUserModal,
        closeDeactivationModal,
        selectedAssociate,
    } = props;

    const [modalAction] = useTgfRequest({
        onRequest: async () => {
            await window.shell.gateway.deactivateAgencyOpsAssociate(selectedAssociate.id);
            closeDeactivationModal();
            closeEditAgencyUserModal();
            await reloadGrid();
        },
        successToast: "Agency user changes saved.",
        errorToast: "Error. Please contact IT."
    });

    const modalDescription = (
        <Grid container spacing={2} alignItems={"center"}>
            <Grid item xs={12}>
                <Typography variant="body">
                    <label className={"modal-label"}>
                        User:
                    </label>
                    {' '}{selectedAssociate?.firstName}{' '}{selectedAssociate?.lastName}
                </Typography>
            </Grid>
            <Grid item xs={12}>
                <Typography variant="body">
                    Click the "Confirm Deactivation" button below to log this user out of the CRM
                    and{' '}
                    deactivate their access.
                </Typography>
            </Grid>
            <Grid item xs={12}>
                <Typography variant="h6" style={{color: "#dc3545", fontWeight: "bold"}}>
                    This deactivation process should only be used to quickly remove the user's CRM
                    access
                    when the need arises. Tallgrass personnel MUST be notified if the user is being
                    permanently terminated. Keep in mind that only Tallgrass personnel can reactivate a
                    user's CRM access.
                </Typography>
            </Grid>
            <Grid item xs={12}>
                <Typography
                    variant="h6"
                    style={{paddingTop: 7, paddingBottom: 10, color: "#007bff", fontWeight: "bold"}}
                >
                    If this user is a Restricted user and they are being permanently terminated, this
                    could affect your visibility to what customers they were the owners of or assigned
                    to.
                    Let the back office know immediately in such situations so they can make the
                    transition/reassigning of accounts an easy process.
                </Typography>
            </Grid>
        </Grid>
    )

    return (
        <ConfirmationModal
            title={"Deactivate CRM Access for User"}
            description={modalDescription}
            action={modalAction}
            onClose={closeDeactivationModal}
            confirmBtnText={"CONFIRM DEACTIVATION"}
            cancelBtnText={"CANCEL"}
            width={800}
            textAlign={"left"}
            buttonAlign={"flex-start"}
            disabled={(selectedAssociate.isHeadOfAgency)}
        />
    );
};
