import FullWidthLayout from "../../../core/layouts/FullWidthLayout";
import LeftNav from "../LeftNav";
import {Grid} from "@material-ui/core";
import React, {useEffect, useMemo, useRef, useState} from "react";
import {Checkbox} from "@progress/kendo-react-inputs";
import PersonnelDirectoryListing from "./includes/PersonnelDirectoryListing/PersonnelDirectoryListing";
import {useDispatch} from "react-redux";
import {Add} from "@material-ui/icons";
import AppButton from "../../../core/components/AppButton/AppMuiButton";
import PersonnelEditModal from "./includes/PersonnelEditModal/PersonnelEditModal";
import {PERSONNEL_ROLE_TYPES} from "../../../constants/personnel/personnel-role-types";
import {objValuesToArray} from "../../../core/utils/dataUtils/objValuesToArray";
import TgfPrimitiveComboBox from "../../../core/kendo/form/inputs/TgfPrimitiveComboBox";
import {validateCanEditPersonnelRecords} from "../../../hubs/personaV2/selectors/validateCanEditPersonnelRecords";
import useIsAdministrator from "../../../hubs/personaV2/selectors/useIsAdministrator";
import AccessDeniedPage from "../../errors/AccessDeniedPage";

const PersonnelDirectoryReport = (props) => {
    const canEditPersonnel = validateCanEditPersonnelRecords();
    const isAdministrator = useIsAdministrator();
    const personalDispatch = useDispatch();
    const [showInactivePersonnel, setShowInactivePersonnel] = useState(false);
    const [hideTgfPersonnel, setHideTgfPerssonel] = useState(false); // hide personnel that have role marked = "TGF Personnel"
    const [llcFilter, setLlcFilter] = useState(null);
    const [llcFilterOptions, setLlcFilterOptions] = useState([]);
    const [roleFilter, setRoleFilter] = useState(null);
    const [showPersonnelEditModal, setShowPersonelEditModal] = useState(false);
    const [selectedPersonnel, setSelectedPersonnel] = useState(null);
    const roleFilterOptions = useMemo(() => objValuesToArray(PERSONNEL_ROLE_TYPES), []);

    const loadPageDependencies = async () => {
        try {
            personalDispatch(window.shell.actions.sys.processStart('loadCustomerCreditStatus'));
            const filter =  {isMarkedInactive: showInactivePersonnel}
            const personnelLlcNames = await window.shell.gateway.getAllPersonnelRecordInvolvedLlcNames(filter);
            setLlcFilterOptions(personnelLlcNames);
        } catch (e) {
            console.log(e);
            personalDispatch(await window.shell.actions.sys.sendSnackbarMessage({
                content: 'Failed To Load Personnel Page Dependencies',
                color: 'error',
            }));
        } finally {
            personalDispatch(window.shell.actions.sys.processComplete('loadCustomerCreditStatus'));
        }
    };

    useEffect(() => {
        loadPageDependencies();
    }, [showInactivePersonnel]);

    const handleLlcFilterChange = (e) => {
        setHideTgfPerssonel(false);
        setLlcFilter(e.target.value);
    }

    const handleRoleFilterChange = (e) => {
        setHideTgfPerssonel(false);
        setRoleFilter(e.target.value);
    }

    const ref = useRef();

    const reloadData = () => {
        ref.current.refresh();
    }

    const handleAddPersonnelClick = () => {
        setShowPersonelEditModal(true);
    }

    const onCloseDialog = () => {
        setShowPersonelEditModal(false);
        setSelectedPersonnel(null);
        reloadData();
    }

    return (
        isAdministrator ?
            <FullWidthLayout SideNav={LeftNav} title={`Personnel Directory`} className={"personnel-directory"}>
                <Grid container spacing={2}>
                    <Grid item xs={2}>
                        <TgfPrimitiveComboBox
                            id={"llcFilter"}
                            name={"llcFilter"}
                            value={llcFilter}
                            onChange={handleLlcFilterChange}
                            data={llcFilterOptions}
                            placeholder={"Filter by Involved LLC..."}
                        />
                    </Grid>
                    <Grid item xs={2}>
                        <TgfPrimitiveComboBox
                            id={"roleFilter"}
                            name={"roleFilter"}
                            value={roleFilter}
                            onChange={handleRoleFilterChange}
                            data={roleFilterOptions}
                            placeholder={"Filter by Role at TGF..."}
                        />
                    </Grid>
                    <Grid item>
                        <Checkbox
                            value={showInactivePersonnel}
                            id={"showInactivePersonnel"}
                            name={"showInactivePersonnel"}
                            label={"Include Inactive Records"}
                            onChange={(e) => setShowInactivePersonnel(e.target.value)}
                        />
                    </Grid>
                    <Grid item>
                        <Checkbox
                            value={hideTgfPersonnel}
                            id={"hideTgfPersonnel"}
                            name={"hideTgfPersonnel"}
                            label={"Hide TGF Personnel"}
                            onChange={(e) => setHideTgfPerssonel(e.target.value)}
                            disabled={(roleFilter || llcFilter)}
                        />
                    </Grid>
                    <Grid item>
                        {canEditPersonnel &&
                        <AppButton
                            startIcon={<Add />}
                            onClick={handleAddPersonnelClick}
                        >
                            ADD PERSONNEL
                        </AppButton>
                        }
                    </Grid>
                    <Grid item xs={12}>
                        <PersonnelDirectoryListing
                            ref={ref}
                            reloadData={reloadData}
                            setShowPersonnelEditModal={setShowPersonelEditModal}
                            setSelectedPersonnel={setSelectedPersonnel}
                            filters={{
                                roleFilter,
                                llcFilter,
                                showInactivePersonnel,
                                hideTgfPersonnel
                            }}
                        />
                    </Grid>
                    {showPersonnelEditModal &&
                        <PersonnelEditModal
                            onCloseDialog={onCloseDialog}
                            personnel={selectedPersonnel}
                        />
                    }
                </Grid>
            </FullWidthLayout> : <AccessDeniedPage />
    )
}

export default PersonnelDirectoryReport;