const {Period1, Period2} = require("@tgf-crm/business/src/associate/PayPeriodDates");

class PayPeriods {

    constructor(startDate, endDate) {
        this.startDate = startDate;
        this.endDate = endDate;
    }

    buildFromDate(date) {
        const month = date?.getUTCDate() >= Period1.Start ?
            date.getUTCMonth() + 1 :
            date.getUTCMonth();

        const payPeriods = PayPeriods.buildFromMonth(month, date.getUTCFullYear());
        return payPeriods.find(pp => pp.includes(date));
    };

    static buildFromMonth(month, year) {
        year = !year ? new Date().getUTCFullYear() : year;
        let period1Start = new Date(Date.UTC(year, month, Period1.Start));
        period1Start = period1Start.toMoment().utc().subtract(1, 'month').toDate();
        const period1End = new Date(Date.UTC(year, month, Period1.End, 11, 59, 59, 999));
        const period2Start = new Date(Date.UTC(year, month, Period2.Start));
        const period2End = new Date(Date.UTC(year, month, Period2.End, 11, 59, 59, 999));

        return [
            new PayPeriods(period1Start, period1End),
            new PayPeriods(period2Start, period2End)
        ];
    };

    includes(targetDate) {
        return this.startDate <= targetDate &&
            targetDate <= this.endDate;
    }

    toString() {
        const startText = this.startDate.toMoment().utc().format('L');
        const endText = this.endDate.toMoment().utc().format('L');
        return `${startText} - ${endText}`;
    }
}

module.exports = PayPeriods;
