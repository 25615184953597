import {Grid} from "@material-ui/core";
import AppMuiCard from "../../../../../core/components/cards/AppCard";
import "./BookingConfirmationPage.scss";
import {Link} from "react-router-dom";
import React from "react";
import {useSelector} from "react-redux";
import TgfButtonBasic from "../../../../../core/kendo/buttons/TgfButtonBasic/TgfButtonBasic";
import AppDateTextUTC from "../../../../../core/components/text/AppDateTextUTC";


export const BookingConfirmationPage = ({bookedShipmentData, handleReset}) => {

    const openLinksInNewTab = useSelector((state) => state.persona.associate.openLinksInNewTab);

    return (
        <Grid item xs={12} md={12} lg={6}>
            <AppMuiCard>
                <Grid container className={"booking-confirmation-card"}>
                    <Grid item xs={12} md={12} lg={12}>
                        <h2 style={{color: "#708c37"}}>Your shipment has been booked!</h2>
                    </Grid>
                    <Grid item xs={12} md={12} lg={12}>
                        <p>For tracking and terminal information received from GlobalTranz, check out your shipment's
                            LTL Autotrack tab.</p>
                    </Grid>
                    <Grid item xs={12}>
                        <Grid container>
                            <Grid item xs={12} md={4} lg={4}>

                                <span>TGF Shipment # {" "}
                                    <Link to={`/shipment/${bookedShipmentData?.shipment?.bolNumber}/details`}
                                          target={openLinksInNewTab ? "_blank" : ""}>{bookedShipmentData?.shipment?.bolNumber}</Link>
                                </span>
                            </Grid>
                            <Grid item xs={12} md={4} lg={8}>
                                <span>Pickup Date: <span className={"highlighted"}><AppDateTextUTC
                                    value={bookedShipmentData?.shipment?.bolDate}/></span></span>
                            </Grid>
                            <Grid item xs={12} md={12} lg={4}>
                                <span>GTZ Shipment # <span
                                    className={"highlighted"}>{bookedShipmentData?.globalTranzBooking?.BOLNumber}</span></span>
                            </Grid>
                            <Grid item xs={12} md={12} lg={8}>
                                <span>Carrier: <span
                                    className={"highlighted"}>{bookedShipmentData?.globalTranzBooking?.CarrierName}</span> </span>
                            </Grid>
                        </Grid>
                    </Grid>
                    <Grid item xs={12} md={12} lg={12}>
                        <p>Click the TGF Shipment above to be taken to the shipment where you can review all details and
                            generate documents.</p>
                    </Grid>
                    <Grid item xs={12} md={12} lg={12}>
                        <div className={'warning-text'}>
                            Remember - the Tallgrass Freight Bill of Lading must ALWAYS be used for LTL shipments.
                        </div>
                        <br/>
                    </Grid>
                    <Grid item xs={12}>
                        <TgfButtonBasic
                            onClick={handleReset}
                        >
                            BOOK ANOTHER SHIPMENT
                        </TgfButtonBasic>
                    </Grid>
                </Grid>
            </AppMuiCard>
        </Grid>
    )
}