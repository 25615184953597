/**
 * Maps an object of key-value pairs into an array of objects suitable for a dropdown list.
 * Each object in the array will have an `id` and `display` field based on the specified keys from the original object.
 *
 * @param {Object} obj - The object containing the items to be mapped (e.g., {key: {id, name}}).
 * @param {string} [idKey="id"] - The key to be used for the `id` field in the resulting array (default is "id").
 * @param {string} [displayKey="name"] - The key to be used for the `display` field in the resulting array (default is "name").
 *
 * @returns {Array<{id: *, display: string}>} An array of objects with `id` and `display` properties.
 *
 * @example
 * // Example 1: Using Product Types
 * const PRODUCT_TYPES = {
 *     ELECTRONICS: {id: 101, name: "Electronics"},
 *     CLOTHING: {id: 102, name: "Clothing"},
 *     GROCERIES: {id: 103, name: "Groceries"}
 * };
 * const productOptions = mapObjToDropdownOptions(PRODUCT_TYPES, "id", "name");
 * console.log(productOptions);
 * // Output: [{id: 101, display: "Electronics"}, {id: 102, display: "Clothing"}, {id: 103, display: "Groceries"}]
 *
 * // Example 2: Using Custom Object with Different Keys
 * const COUNTRY_CODES = {
 *     US: {code: "US", country: "United States"},
 *     CA: {code: "CA", country: "Canada"},
 * };
 * const countryOptions = mapObjToDropdownOptions(COUNTRY_CODES, "code", "country");
 * console.log(countryOptions);
 * // Output: [{id: "US", display: "United States"}, {id: "CA", display: "Canada"}]
 */
export const mapObjToDropdownOptions = (obj, idKey = "id", displayKey = "name") => {
    return Object.values(obj).map(item => ({
        id: item[idKey],
        display: item[displayKey]
    }));
}
