import React, {useEffect, useRef} from 'react';
import {Grid} from '@material-ui/core';
import AppCard from "../../../../core/components/cards/AppCard/AppMuiCard";
import InfoIconTooltip from "../../../../core/components/Tooltips/InfoIconTooltip/InfoIconTooltip";
import {useDispatch} from "react-redux";
import TgfDataGrid from "../../../../core/kendo/dataGrid/TgfDataGrid";
import {
    CurrencyCell,
    RepPaidCell,
    ShipmentNumberCell
} from "../../../open-load-board/includes/listingCells/multiUseListingCells";
import PayPeriods from "../includes/PayPeriods";
import {EmptyDataGrid} from "../../../../core/kendo/dataGrid/EmptyDataGrid";

const ShipmentListing = (props) => {

    const {
        associate,
        payPeriods,
        startDate,
        endDate,
        agencyMode,
        associateCommissionRates,
        associateFilter,
        hideCustomerOwnerShipments
    } = props;

    const ref = useRef();

    // We are using the PayPeriods class pulled from PayPeriod class node modules.
    // We must use the .getUTCDate in this PayPeriods class instead of the .getDate() in the PayPeriod node modules
    // because Dates in Gateway are in UTC timezone, but we must use it in date form in order to process the Commissions accurately.
    const payPeriodClass = new PayPeriods(startDate, endDate);

    const personalDispatch = useDispatch();

    useEffect(() => {
        if (associateCommissionRates) {
            ref?.current?.refresh();
        }
    }, [associateCommissionRates, associateFilter, associate, hideCustomerOwnerShipments]);

    const applyAdditionalFilters = (odata) => {
        odata = odata ?? {
            top: 20,
            skip: 0
        };

        if (startDate && endDate) {
            odata.filter.agencyId = associate?.id;
            odata.filter.pickupDate = {
                ge: startDate.toISOString(), le: endDate.toISOString()
            };
        }

        if (associateFilter) {
            odata.filter.ownerAssociateId = associateFilter?.agencyCustomerOwner?.id;
            odata.filter.hasOwnerAssigned = true;
        }

        if (hideCustomerOwnerShipments) {
            odata.filter.hasOwnerAssigned = false;
        }

        return odata;
    };

    const loadShipmentListing = async (odata) => {
        if (!associate) {
            return EmptyDataGrid;
        } else {
            try {
                personalDispatch(window.shell.actions.sys.processStart('loadShipmentListing'));
                return await window.shell.gateway.getAssociateMonthlyGmShipments(odata, associate?.id);
            } catch (e) {
                console.log(e);
            } finally {
                personalDispatch(window.shell.actions.sys.processComplete('loadShipmentListing'));
            }
        }
    };

    const GridColumns = [
        {
            field: 'bolNumber',
            title: 'Ship #',
            type: 'custom',
            width: 60,
            cell: ShipmentNumberCell
        },
        {
            field: 'adjustedCarrierCost',
            title: 'Carrier Cost',
            type: 'custom',
            sortable: true,
            cell: (props) => CurrencyCell(props.dataItem?.adjustedCarrierCost ?? ''),
            width: 90,
        },
        {
            field: 'tgfFee',
            title: 'TGF Fee',
            type: 'custom',
            sortable: true,
            cell: (props) => CurrencyCell(props.dataItem?.tgfFee ?? ''),
            width: 80,
        },
        {
            field: 'adjustedCustomerCost',
            title: 'Customer Cost',
            type: 'custom',
            sortable: true,
            cell: (props) => CurrencyCell(props.dataItem?.adjustedCustomerCost ?? ''),
            width: 90,
        },
        {
            field: 'totalMargin',
            title: 'Total Margin',
            type: 'custom',
            sortable: true,
            cell: (props) => CurrencyCell(props.dataItem?.totalMargin ?? ''),
            width: 80,
        },
        {
            field: 'freightCategory',
            title: 'Type',
            width: 60,
        },
        {
            title: 'Carrier Name',
            field: 'carrierName',
            type: 'link',
            url: (props) => `/carrier/${props.carrierMcNumber}/compliance`,
        },
        {
            field: 'thirdPartyName',
            title: 'Third Party Name',
            type: 'custom',
            sortable: true,
            cell: (props) => <td><span>{props.dataItem.thirdPartyName ?? ''}</span></td>,
        },
        {
            field: 'pickupDate',
            title: 'Pickup Date',
            type: 'date',
            width: 90,
        },
        {
            field: 'customerName',
            title: 'Customer Name',
            type: 'link',
            url: (props) => `/address-book/${props.customerId}/details`,
        },
        {
            field: 'earnedPayout',
            title: 'Earned Payout',
            type: 'custom',
            sortable: true,
            cell: (props) => CurrencyCell(props.dataItem?.earnedPayout ?? ''),
            width: 90,
        },
        {
            field: 'actualDeliveryDate',
            title: 'Locked Date',
            type: 'date',
            width: 90,
        },
        {
            field: 'repPaidIndex',
            title: 'Rep Paid',
            type: 'custom',
            cell: RepPaidCell,
            sortable: true,
            width: 60
        }
    ];

    return (
        <AppCard
            title={
                <div style={{display: 'flex', alignItems: 'center'}}>
                    Shipments {
                    agencyMode &&
                    <InfoIconTooltip
                        title={
                            `This information will change depending on the filter and checkbox above being used.
                            Otherwise, it will provide information for your entire agency.`
                        }
                        style={{marginTop: "1px"}}
                    />
                }
                </div>
            }
        >
            <Grid item xs={12}>
                <TgfDataGrid
                    className={"open-load-board_shipment-listing-grid"}
                    ref={ref}
                    onApplyAdditionalDataStateAsOdata={applyAdditionalFilters}
                    onLoadDataPage={loadShipmentListing}
                    gridColumns={GridColumns}
                    sort={[{field: "bolNumber", dir: "asc"}]}
                    pageable={{
                        pageSize: 20,
                        buttonCount: 10,
                        pageSizes: [5, 10, 20, 50, 100, 500]
                    }}
                />
            </Grid>
        </AppCard>
    );
};

export default ShipmentListing;
