import React, {useEffect, useState} from 'react';
import Core from '@atomos/core';
import * as Yup from "yup";
import {Add} from '@material-ui/icons';
import {Button, Grid} from '@material-ui/core';
import ComponentBuilder from '../../../core/ComponentBuilder';

import AppButton from '../../../core/components/AppButton';
import FullWidthLayout from '../../../core/layouts/FullWidthLayout';
import FormFactor from "../../../core/FormFactor/FormFactor";
import LeftNav from '../CarrierNav';

import AppDialog from '../../../core/components/AppDialog';
import AppValidationFailure from '../../../core/components/AppValidationFailure';
import AppInput from '../../../core/components/inputs/AppInput/AppMuiInput';
import AppMuiSimpleTable from '../../../core/components/AppTable/AppMuiSimpleTable';
import "./NotesPage.scss";
import {usePageTitle} from "../../../crm/components/customHooks/misc/usePageTitle";
import AppMuiCard from "../../../core/components/cards/AppCard";
import InfoIconTooltip from "../../../core/components/Tooltips/InfoIconTooltip/InfoIconTooltip";
import {Label} from "@progress/kendo-react-labels";
import TgfTextArea from "../../../core/kendo/form/inputs/TgfTextArea";
import {useDispatch} from "react-redux";
import useHasCarrierPrivilege from "../../../hubs/personaV2/selectors/useHasCarrierPrivilege";

const LoadProcessName = 'Carrier.CarrierNote.Load';

const NewNoteSchema = Yup.object().shape({
    note: Yup.string().required('Note is required.')
});

const Columns = [
    {
        title: 'Note',
        headerPropMap(index, columns) {
            return {width: '65%'};
        },
        cellPropMap(note, index, data, columns) {
            return {
                style: {overflowWrap: 'break-word'}
            };
        },
        valueMap(note, index, data, columns) {
            return note.content
        }
    },
    {
        title: 'Date',
        headerPropMap(index, columns) {
            return {width: '15%', style: {textAlign: 'center'}};
        },
        valueMap(note, index, data, columns) {
            return note.createDate.toMoment().format('L LT');
        }
    },
    {
        title: 'Associate',
        headerPropMap(index, columns) {
            return {width: '20%', style: {textAlign: 'center'}};
        },
        cellPropMap(note, index, data, columns) {
            return {
                style: {textAlign: 'center'}
            };
        },
        valueMap(note, index, data, columns) {
            return note.associate ?
                `${note.associate.firstName}  ${note.associate.lastName}` :
                '[BLANK]'
        }
    }
];
const renderNewNoteForm = ({values, errors, isValid, isDirty, setFieldValue, submitForm}) => {

    const newNoteActions = [
        {
            title: 'Cancel',
            action: values.onClose
        },
        {
            title: 'Save',
            action: submitForm,
            disabled: !isValid || !isDirty
        }
    ];

    const handleNoteChange = (e) => setFieldValue('note', e.target.value);

    return (
        <AppDialog
            title="New Note"
            open={true}
            onClose={values.onClose}
            width='sm'
            actionButtons={newNoteActions}
        >
            <Grid container>
                <Grid item xs={12}>
                    <AppInput
                        autoFocus
                        id="carrierNote"
                        rows="10"
                        cols="45"
                        inputProps={{
                            maxLength: 1000
                        }}
                        multiline={true}
                        onChange={handleNoteChange}/>
                    <AppValidationFailure message={errors.note}/>
                </Grid>
            </Grid>
        </AppDialog>
    );
};

const CarrierNotesPage = (props) => {
    const {
        carrier,
        carrierNotes,
        dispose,
        load,
        match,
        saveNote,
        sendSnackbarMessage
    } = props;

    const personalDispatch = useDispatch();
    const hasCarrierPrivilege = useHasCarrierPrivilege();
    const mcNumber = match.params.id;
    usePageTitle(carrier?.name ? `Carr: ${carrier.name.substring(0, 20)}` : null);

    const sortedCarrierNotes = carrierNotes
        .slice()
        .sort(DescendingNoteSort)

    const [isAddingNote, setIsAddingNote] = useState(false);

    React.useEffect(() => {
        load(mcNumber);

        return () => {
            dispose();
        }
    }, [mcNumber, dispose, load]);

    const handleNewNoteClose = (e) =>
        setIsAddingNote(false);

    const newNoteInitialValues = {
        note: '',
        onClose: handleNewNoteClose
    };

    const handleNewNoteSubmit = (values, formFactor) => {
        setIsAddingNote(false);
        saveNote(mcNumber, values.note)
            .then(() => {
                sendSnackbarMessage({content: 'Note saved.'});
            });
    };

    const handleNewNoteOpen = () => setIsAddingNote(true);

    const makeTableFixed = (baseProps) =>
        Core.Utils.merge({}, baseProps, {
            style: {tableLayout: 'fixed'},
            width: '100%'
        });

    const title = carrier ?
        `Carrier - Notes - ${carrier.name} (${mcNumber})` :
        'Carrier - Notes';

    const [complianceNote, setComplianceNote] = useState(null);
    const [initialComplianceNote, setInitialComplianceNote] = useState(null);
    const [editComplianceNote, setEditComplianceNote] = useState(false);
    const [complianceNoteIsDirty, setComplianceNoteIsDirty] = useState(false);

    useEffect(() => {
        if(carrier) {
            setComplianceNote(carrier?.complianceNote);
            setInitialComplianceNote(carrier?.complianceNote);
        }
    }, [carrier]);

    const handleComplianceNoteChange = (e) => {
        setComplianceNote(e.target.value);
        setComplianceNoteIsDirty(true);
    }

    const handleSaveComplianceNote = async () => {
        try {
            personalDispatch(window.shell.actions.sys.processStart('saveComplianceNote'));
            await window.shell.gateway.saveCarrierComplianceNote(mcNumber, complianceNote);
            // reload
            await load(mcNumber);

            setEditComplianceNote(false);
            setComplianceNoteIsDirty(false);
            personalDispatch(await window.shell.actions.sys.sendSnackbarMessage({ content: 'Compliance note saved' }));
        } catch (e) {
            console.log(e);
            personalDispatch(await window.shell.actions.sys.sendSnackbarMessage({
                content: 'DAT save failed',
                color: 'error'
            }));
        } finally {
            personalDispatch(window.shell.actions.sys.processComplete('saveComplianceNote'));
        }
    }

    const handleResetComplianceNote = () => {
        setComplianceNote(initialComplianceNote);
        setEditComplianceNote(false);
        setComplianceNoteIsDirty(false);
    }

    const complianceAlertTooltipText = (
        <>
            <p>Our Carrier Compliance team enters these notes when there is important information about the carrier they need our users to be aware of.</p>
            <p>If notes are entered here, they will be displayed via a “Compliance Alert Note” tooltip above the carrier name on both the Details page and the Duplicate page of shipments this carrier is involved in.</p>
        </>
    )


    return (
        <FullWidthLayout SideNav={LeftNav} title={title} className="carrier-styles">
            <Grid container spacing={1}>
                <Grid item xs={12}>
                    <AppButton startIcon={<Add/>} onClick={handleNewNoteOpen}>New Note</AppButton>
                </Grid>
                <Grid item>
                    <AppMuiCard title={<span>Compliance Alert Note <InfoIconTooltip title={complianceAlertTooltipText} maxWidth={400}/> </span>}>
                        <Grid container spacing={2}>
                            <Grid item xs={12}>
                                <Label editorId={"complianceNote"}>Compliance Alert Note (400 max characters)</Label>
                                <TgfTextArea
                                    id={"complianceNote"}
                                    name={"complianceNote"}
                                    value={complianceNote}
                                    onChange={handleComplianceNoteChange}
                                    disabled={!editComplianceNote}
                                    maxLength={400}
                                    rows={5}
                                />
                            </Grid>
                            <Grid item>
                                <Button
                                    variant="contained"
                                    color="primary"
                                    type={"button"}
                                    disabled={(editComplianceNote || !hasCarrierPrivilege)}
                                    onClick={() => setEditComplianceNote(true)}
                                >
                                    EDIT
                                </Button>
                            </Grid>
                            <Grid item>
                                <Button
                                    variant="contained"
                                    color="primary"
                                    type={"button"}
                                    disabled={Boolean(!editComplianceNote || !complianceNoteIsDirty)}
                                    onClick={handleSaveComplianceNote}
                                >
                                    SAVE
                                </Button>
                            </Grid>
                            <Grid item>
                                <Button
                                    variant="outlined"
                                    color="primary"
                                    type={"button"}
                                    disabled={!editComplianceNote}
                                    onClick={handleResetComplianceNote}
                                >
                                    CANCEL
                                </Button>
                            </Grid>
                        </Grid>
                    </AppMuiCard>
                </Grid>
                <Grid item xs={12}>
                    <AppMuiSimpleTable
                        columns={Columns}
                        tablePropMap={makeTableFixed}
                        data={sortedCarrierNotes}
                    />
                </Grid>
                <Grid item xs={12}>
                    {isAddingNote && <FormFactor
                        initialValues={newNoteInitialValues}
                        schema={NewNoteSchema}
                        onSubmit={handleNewNoteSubmit}>
                        {renderNewNoteForm}
                    </FormFactor>
                    }
                </Grid>
            </Grid>
        </FullWidthLayout>
    );
};

const DescendingNoteSort = (noteA, noteB) => {
    if (noteA.createDate > noteB.createDate)
        return -1;
    else if (noteB.createDate > noteA.createDate) {
        return 1;
    } else return 0;
};

export default ComponentBuilder
    .wrap(CarrierNotesPage)
    .stateToProps((state, ownProps) => ({
        carrier: state.carrier.modification.carrier,
        carrierNotes: state.carrier.modification.carrierNotes
    }))
    .dispatchToProps((shell, dispatch, getState) => {
        return {
            async load(mcNumber) {

                dispatch(shell.actions.sys.processStart(LoadProcessName));

                const actions = await Promise.all([
                    shell.actions.carrier.modification.loadCarrierNotes(mcNumber),
                    shell.actions.carrier.modification.loadCarrier(mcNumber)
                ]);
                actions.forEach(dispatch);

                dispatch(shell.actions.sys.processComplete(LoadProcessName));
            },
            async saveNote(mcNumber, note) {

                dispatch(shell.actions.sys.processStart(LoadProcessName));

                dispatch(await shell.actions.carrier.modification.saveCarrierNote(mcNumber, note));

                dispatch(shell.actions.sys.processComplete(LoadProcessName));
            },
            async dispose() {
                dispatch(await shell.actions.carrier.modification.dispose())
            },
            async sendSnackbarMessage(message) {
                dispatch(await shell.actions.sys.sendSnackbarMessage(message));
            }
        }
    })
    .build();