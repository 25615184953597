import {Grid, IconButton} from '@material-ui/core';
import React, {forwardRef, useEffect} from "react";
import {useDispatch} from "react-redux";
import TgfDataGrid from "../../../../core/kendo/dataGrid/TgfDataGrid";
import {editUserCell} from "../../../open-load-board/includes/listingCells/multiUseListingCells";
import {CheckCircle, FindInPage} from "@material-ui/icons";
import InfoIconTooltip from "../../../../core/components/Tooltips/InfoIconTooltip/InfoIconTooltip";
import {HeaderInfoIconTooltipCell} from "../../../../core/kendo/dataGrid/cells/HeaderInfoIconTooltipCell";

const IsCheckedCell = (props) => {
    return (
        props.isChecked ?
            <td style={{textAlign: "center"}}>
                <CheckCircle color={'primary'} fontSize={'small'} />
            </td>
            :
            <td></td>
    );
};

const InvolvedCustomersCell = (props) => {
    const {
        associateId,
        onClickOpenInvolvedCustomers,
    } = props;

    return (
        <td style={{textAlign: "center"}}>
            <IconButton
                color={'primary'}
                onClick={() => onClickOpenInvolvedCustomers(associateId)}
            >
                <FindInPage color={'primary'} fontSize={'small'} />
            </IconButton>
        </td>
    );
};

const InvolvedLeadsCell = (props) => {
    const {
        associateId,
        onClickOpenInvolvedLeads,
    } = props;

    return (
        <td style={{textAlign: "center"}}>
            <IconButton
                color={'primary'}
                onClick={() => onClickOpenInvolvedLeads(associateId)}
            >
                <FindInPage color={'primary'} fontSize={'small'} />
            </IconButton>
        </td>
    );
};

export const AgencyUsersGrid = forwardRef(function AgencyUsersGrid(props, ref) {
    const {
        applyAdditionalFilters,
        setEditAgencyUserModalOpen,
        setSelectedAssociate,
        loadAgencyAccessFilters,
        agencyAccessFilters,
        setOpenInvolvedCustomers,
        setOpenInvolvedLeads,
    } = props;

    const personalDispatch = useDispatch();

    const loadAgencyAssociates = async (odata) => {
        try {
            personalDispatch(window.shell.actions.sys.processStart('loadAgencyAssociate'));
            const results = await window.shell.gateway.getPageOfAgencyAssociates(odata);
            results.items.forEach((associate) => {
                if (associate.isHeadOfAgency) {
                    associate.accessFilter = 'Head of Agency';
                    associate.accessFilterId = 0;
                }
            });
            return results;

        } catch (e) {
            console.log(e);
        } finally {
            personalDispatch(window.shell.actions.sys.processComplete('loadAgencyAssociate'));
        }
    };

    const onClickEditAssociate = (associate) => {
        setSelectedAssociate(associate);
        setEditAgencyUserModalOpen(true);
    };

    const onClickOpenInvolvedCustomers = (associate) => {
        setSelectedAssociate(associate);
        setOpenInvolvedCustomers(true);
    };

    const onClickOpenInvolvedLeads = (associate) => {
        setSelectedAssociate(associate);
        setOpenInvolvedLeads(true);
    };

    useEffect(() => {
        loadAgencyAccessFilters();
    }, []);

    const gridColumnInfoTooltipCell = (title, tooltip) => (
        <span className="k-cell-inner">
            <span className="k-link">
                <span className="k-column-title">{title}</span>
                <span className="k-column-title" style={{display: "flex", marginLeft: "-2px"}}>
                    <InfoIconTooltip title={tooltip} />
                </span>
            </span>
        </span>
    );

    const gridColumns = [
        {title: 'Last Name', field: 'lastName', type: 'text'},
        {title: 'First Name', field: 'firstName', type: 'text'},
        {title: 'CRM ID', field: 'systemId', className: "center", type: 'text', width: 80},
        {title: 'EXT', field: 'businessPhoneExt', className: "center", type: 'text', width: 80},
        {title: 'Email', field: 'emailAddress', type: 'text'},
        {
            title: <HeaderInfoIconTooltipCell
                headerTitle={'Head of Agency'}
                tooltipTitle={'This column advises users who have a Visibility Permission of Head of Agency.'}
            />,
            field: 'isHeadOfAgency',
            type: 'custom',
            cell: (props) => <IsCheckedCell isChecked={props.dataItem?.actionRoleId === 2} />,
            width: 150,
        },
        {
            title: <HeaderInfoIconTooltipCell
                headerTitle={'Visibility Permission'}
                tooltipTitle={'Displays the Visibility Permission of the user.'}
            />,
            field: 'accessFilter',
            type: 'text',
            width: 150,
        },
        {
            headerCell: () => gridColumnInfoTooltipCell(
                'Involved Customers',
                'Displays all customers the user has visibility of along with whether or not they are the Customer Owner.'
            ),
            field: '',
            type: 'custom',
            cell: (props) =>
                props.dataItem?.actionRoleId === 2 ? <td></td> :
                    <InvolvedCustomersCell
                        associateId={props.dataItem}
                        onClickOpenInvolvedCustomers={onClickOpenInvolvedCustomers}
                    />,
            width: 150,
            sortable: false,
        },
        {
            headerCell: () => gridColumnInfoTooltipCell(
                'Involved Leads',
                <>
                    <p>
                        Displays all leads the user has visibility of along with whether or not they are the Customer
                        Owner.
                    </p>
                    <p>
                        For leads, we also use the term "Customer Owner" for Franchise Mode settings.
                    </p>
                </>
            ),
            field: '',
            type: 'custom',
            cell: (props) =>
                props.dataItem?.actionRoleId === 2 ? <td></td> :
                    <InvolvedLeadsCell
                        associateId={props.dataItem}
                        onClickOpenInvolvedLeads={onClickOpenInvolvedLeads}
                    />,
            width: 150,
            sortable: false,
        },
        {field: 'isActive', title: 'Account Active', type: 'boolean', width: 80},
        {
            title: ' ',
            field: '',
            type: 'custom',
            cell: (props) => editUserCell(
                props,
                onClickEditAssociate,
            ),
            width: 40,
            sortable: false,
        },
    ];

    return (
        <Grid container spacing={2}>
            <Grid item xs={12}>
                <TgfDataGrid
                    ref={ref}
                    onLoadDataPage={loadAgencyAssociates}
                    gridColumns={gridColumns}
                    onApplyAdditionalDataStateAsOdata={applyAdditionalFilters}
                    sort={[{field: "isHeadOfAgency", dir: "desc"}, {field: "firstName", dir: "asc"}]}
                />
            </Grid>
        </Grid>
    );
});
