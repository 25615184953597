import {useState} from "react";
import {useDispatch} from "react-redux";
import {v4 as uuidv4} from "uuid";
import {useToast} from "./useToast";

/**
 * Custom React hook that wraps an asynchronous request function.
 * This hook manages the state for loading, error, and request execution.
 * It also dispatches Redux actions to handle process start and completion.
 *
 * Optionally, it can trigger toast notifications on success or error, and bubble errors up if required.
 *
 * @param {Object} options - The options object to configure the hook.
 * @param {Function} options.onRequest - The async function to be executed, typically a data fetching or processing function.
 * @param {string|null} [options.successToast=null] - Optional message that will be shown in a toast on success.
 * @param {string|null} [options.errorToast=null] - Optional message that will be shown in a toast on error.
 * @param {boolean} [options.bubbleError=false] - Whether to throw the error back up if an error occurs (useful for custom error handling).
 * @param {string} [options.processName=uuidv4()] - Optional unique process name. Defaults to a UUID if not provided.
 *
 * @returns {[Function, boolean, string|null]} - Returns an array with:
 *    - `sendRequest` (Async Function): A function that triggers the request. You should call this function to initiate the async request.
 *    - `isLoading` (boolean): A state indicating if the request is in progress.
 *    - `error` (string|null): A state containing the error message, if any error occurs during the request.
 *
 * Example usage:
 * ```javascript
 * const [sendRequest, isLoading, error] = useTgfRequest({ onRequest: requestFn, successToast: "Success!", errorToast: "Something went wrong!" });
 *
 * const handleRequest = async () => {
 *     const result = await sendRequest();
 *     // Handle the result here
 * };
 * ```
 */
export const useTgfRequest = ({onRequest, successToast = null, errorToast = null, bubbleError = false, processName = uuidv4()}) => {
    const dispatch = useDispatch();
    const toast = useToast();

    const processStart = window.shell.actions.sys.processStart(processName);
    const processComplete = window.shell.actions.sys.processComplete(processName);

    // State hooks for managing loading and error states
    const [error, setError] = useState(null);
    const [isLoading, setIsLoading] = useState(false);

    /**
     * The function that executes the provided `onRequest` async function.
     * This function sets the loading state, dispatches Redux actions for process start and completion,
     * handles any errors, triggers toast notifications, and optionally bubbles up errors.
     *
     * @param {...any} args - Arguments to pass to the `onRequest` function.
     *
     * @returns {Promise<any>} - The result from the executed `onRequest` function.
     *
     * @throws {Error} - Optionally throws an error if `bubbleError` is set to true.
     */
    const sendRequest = async (...args) => {
        setIsLoading(true);   // Set loading state to true when request starts
        setError(null);        // Reset previous errors

        try {
            dispatch(processStart);  // Dispatch process start action
            const result = await onRequest(...args);  // Execute the provided async function (request)
            if(successToast) toast.toastSuccess(successToast);  // Show success toast if message provided
            return result;  // Return the result (if any)
        } catch (err) {
            console.error("TGF Request Error:", err);  // Log the error for debugging
            if(errorToast) toast.toastError(errorToast);  // Show error toast if message provided
            setError(err.message || "An error occurred");  // Set the error state
            if(bubbleError) throw new Error(err);  // Optionally throw the error if bubbleError is true
        } finally {
            dispatch(processComplete);  // Dispatch process completion action
            setIsLoading(false);  // Set loading state to false once the request completes (success or failure)
        }
    };

    // Return the request function, loading state, and error state
    return [sendRequest, isLoading, error];
};
