import {Grid} from "@material-ui/core";
import TgfDialog from "../../../../../core/kendo/dialog/TgfDialog";
import * as yup from "yup";
import {
    TgfFormSubmitButton
} from "../../../../../core/kendo/form/TgfFormComponent/components/buttons/TgfFormSubmitButton";
import {
    TgfFormResetButton
} from "../../../../../core/kendo/form/TgfFormComponent/components/buttons/TgfFormResetButton";
import {
    TgfFormTextBoxInput
} from "../../../../../core/kendo/form/TgfFormComponent/components/inputs/TgfFormTextBoxInput";
import {
    TgfFormComponent
} from "../../../../../core/kendo/form/TgfFormComponent/components/TgfFormComponent/TgfFormComponent";
import {TgfFormEditButton} from "../../../../../core/kendo/form/TgfFormComponent/components/buttons/TgfFormEditButton";
import {useTgfRequest} from "../../../../../core/hooks/useTgfRequest";
import {
    TgfFormPhoneNumberInput
} from "../../../../../core/kendo/form/TgfFormComponent/components/inputs/TgfFormPhoneNumberInput";
import {
    TgfFormDateOnlyPickerInput
} from "../../../../../core/kendo/form/TgfFormComponent/components/inputs/TgfFormDateOnlyPickerInput";
import {
    TgfFormPrimitiveComboBoxInput
} from "../../../../../core/kendo/form/TgfFormComponent/components/inputs/TgfFormPrimitiveComboBoxInput";
import React, {useMemo, useState} from "react";
import {PERSONNEL_ROLE_TYPES} from "../../../../../constants/personnel/personnel-role-types";
import {objValuesToArray} from "../../../../../core/utils/dataUtils/objValuesToArray";
import {
    TgfFormPrimitiveDropdownListInput
} from "../../../../../core/kendo/form/TgfFormComponent/components/inputs/TgfFormPrimitiveDropdownListInput";
import {
    TgfFormToggleSwitch
} from "../../../../../core/kendo/form/TgfFormComponent/components/toggles/TgfFormToggleSwitch";
import {createRange} from "../../../../../core/utils/numberUtils/createRange";
import {mapObjToDropdownOptions} from "../../../../../core/utils/dataUtils/mapToDropdownOptions";
import {MONTHS} from "../../../../../constants/date/months";
import {TgfFormCustom} from "../../../../../core/kendo/form/TgfFormComponent/components/custom/TgfFormCustom";
import {ImportAssociateInfoModal} from "../ImportAssociateInfoModal/ImportAssociateInfoModal";
import {
    TgfFormGenericButton
} from "../../../../../core/kendo/form/TgfFormComponent/components/buttons/TgfFormGenericButton";
import {DeletePersonnelConfirmationModal} from "../DeletePersonnelConfimationModal/DeletePersonnelConfirmationModal";
import _ from "lodash";
import {
    TgfFormStateProvinceComboBoxInput
} from "../../../../../core/kendo/form/TgfFormComponent/components/inputs/TgfFormStateProvinceComboBoxInput";
import {
    TgfFormComboBoxInput
} from "../../../../../core/kendo/form/TgfFormComponent/components/inputs/TgfFormComboBoxInput";
import {validateCanEditPersonnelRecords} from "../../../../../hubs/personaV2/selectors/validateCanEditPersonnelRecords";

const defaultPersonnel = {
    address1: null,
    address2: null,
    birthdayDay: null,
    birthdayMonth: null,
    birthdayYear: null,
    businessEmailAddress: null,
    businessPhoneExt: null,
    city: null,
    crmId: null,
    displayedLocation: null,
    emergencyContactPerson: null,
    emergencyContactPhone: null,
    firstName: null,
    id: null,
    involvedLlc: null,
    isMarkedInactive: false,
    lastName: null,
    markedInactiveDate: null,
    personalPhone: null,
    personalEmailAddress: null,
    position: null,
    role: null,
    startDate: null,
    state: null,
    zipPostal: null,
}

const PersonnelEditModal = (props) => {
    const canEditPersonnel = validateCanEditPersonnelRecords();
    const [importAssociateInfoModalOpen, setImportAssociateInfoModalOpen] = useState(false);
    const [deleteConfirmationModalOpen, setDeleteConfirmationModalOpen] = useState(false);
    const roleFilterOptions = useMemo(() => objValuesToArray(PERSONNEL_ROLE_TYPES), []);

    const [handleDelete] = useTgfRequest({
        onRequest: async (tgfFormContext) => {
            await window.shell.gateway.deletePersonnelRecordById(tgfFormContext.values.entity.id);
            props.onCloseDialog();
        }
    })

    const [loadFormData] = useTgfRequest({
        onRequest: async () => {
            let personnel = {};
            if(props.personnel?.id) personnel = await window.shell.gateway.getPersonnelDetailsById(props.personnel?.id);
            const llcNames = await window.shell.gateway.getAllAssociateLlcNames();
            return {
                entity: {
                    ...defaultPersonnel,
                    ...personnel
                },
                additionalData: {
                    llcNames: llcNames,
                    roleFilterOptions: roleFilterOptions,
                    birthdayDayOptions: createRange(1, 31),
                    birthdayMonthOptions: mapObjToDropdownOptions(MONTHS, "number", "abbreviation")
                }
            }
        }
    })

    const [handleSubmit] = useTgfRequest({
        onRequest: async (values) => {
            await window.shell.gateway.savePersonnelDetails(values.entity);
            if(!values.entity.id) props.onCloseDialog();
        },
        successToast: "Personnel details saved.",
        errorToast: "Failed to save personnel details.",
    })

    const bookingFormValidationSchema = yup.object().shape({
        entity: yup.object().shape({
            address1: yup.string().max(75).nullable(),
            address2: yup.string().max(75).nullable(),
            businessEmailAddress: yup.string().max(50).matches(/^$|^[^\s@]+@[^\s@]+\.[^\s@]+$/, 'Email is invalid.').nullable(),
            personalEmailAddress: yup.string().max(50).matches(/^$|^[^\s@]+@[^\s@]+\.[^\s@]+$/, 'Email is invalid.').nullable(),
            role: yup.string().required(),
            city: yup.string().max(25).nullable(),
            zipPostal: yup.string().max(12).nullable(),
            displayedLocation: yup.string().max(35).nullable(),
            emergencyContactPerson: yup.string().max(50).nullable(),
            emergencyContactPhone: yup.string().nullable(),
            firstName: yup.string().max(20).required(),
            lastName: yup.string().max(20).required(),
            position: yup.string().max(35).nullable(),
            birthdayDay: yup.string().test({
                test(value) {
                    if(this.parent.birthdayMonth && !value) {
                        return this.createError({message: "Required if Birthday Month Chosen"});
                    }
                    return true
                }
            }).nullable(),
            birthdayYear: yup.string().max(4).nullable(),
            crmId: yup.string().max(5).nullable(),
            businessPhoneExt: yup.string().max(4).nullable(),
        })
    });

    const handleRoleChange = (e, values, setValues) => {
        if(e.value === PERSONNEL_ROLE_TYPES.TGF_PERSONNEL) {
            values.entity.involvedLlc = null;
        }

        values.entity.role = e.value;
        setValues(_.cloneDeep(values));
    }

    const handleBirthdayMonthChange = (e, values, setValues) => {
        if(!e.target.value?.id) {
            values.entity.birthdayDay = "";
        }
        values.entity.birthdayMonth = e.target.value?.id;
        setValues(_.cloneDeep(values));
    }

    return (
        <TgfDialog title={"Personnel Entry"}
                   onClose={props.onCloseDialog}
                   width={1000}
        >
                    <TgfFormComponent
                        loadFormData={loadFormData}
                        validationSchema={bookingFormValidationSchema}
                        onSubmit={handleSubmit}
                    >
                        <Grid container spacing={2}>
                            <Grid item xs={2}>
                                <TgfFormTextBoxInput
                                    label={"First Name"}
                                    name={"entity.firstName"}
                                    tabIndex={1}
                                    autoFocus={true}
                                />
                            </Grid>
                            <Grid item xs={2}>
                                <TgfFormTextBoxInput
                                    label={"Last Name"}
                                    name={"entity.lastName"}
                                    tabIndex={2}
                                />
                            </Grid>
                            <Grid item xs={1}>
                                <TgfFormTextBoxInput
                                    label={"CRM ID"}
                                    name={"entity.crmId"}
                                    tabIndex={3}
                                />
                            </Grid>
                            <Grid item xs={1}>
                                <TgfFormTextBoxInput
                                    label={"Ext"}
                                    name={"entity.businessPhoneExt"}
                                    tabIndex={4}
                                />
                            </Grid>
                            <Grid item xs={4}>
                                <TgfFormTextBoxInput
                                    label={"Personal Email"}
                                    name={"entity.personalEmailAddress"}
                                    tabIndex={11}
                                />
                            </Grid>
                            <Grid item xs={2}>
                                <TgfFormPhoneNumberInput
                                    label={"Personal Phone"}
                                    name={"entity.personalPhone"}
                                    tabIndex={12}
                                />
                            </Grid>
                            <Grid item xs={4}>
                                <TgfFormTextBoxInput
                                    label={"TGF Email"}
                                    name={"entity.businessEmailAddress"}
                                    tabIndex={5}
                                />
                            </Grid>
                            <Grid item xs={2}>
                                <TgfFormDateOnlyPickerInput
                                    label={"Start Date"}
                                    name={"entity.startDate"}
                                    allowClear={true}
                                    tabIndex={6}
                                />
                            </Grid>
                            <Grid item xs={6}>
                                <TgfFormTextBoxInput
                                    label={"Mailing Address Line 1"}
                                    name={"entity.address1"}
                                    tabIndex={13}
                                />
                            </Grid>
                            <Grid item xs={6}>
                                <TgfFormPrimitiveComboBoxInput
                                    label={"Involved LLC (if not TGF Personnel)"}
                                    name={"entity.involvedLlc"}
                                    dataOptionsPath={"additionalData.llcNames"}
                                    disabledHandler={formContext => {
                                        return (formContext.values.entity?.role === PERSONNEL_ROLE_TYPES.TGF_PERSONNEL
                                        || !formContext.canEdit)
                                        }
                                    }
                                    tabIndex={7}
                                />
                            </Grid>
                            <Grid item xs={6}>
                                <TgfFormTextBoxInput
                                    label={"Mailing Address Line 2"}
                                    name={"entity.address2"}
                                    tabIndex={14}
                                />
                            </Grid>
                            <Grid item xs={3}>
                                <TgfFormPrimitiveDropdownListInput
                                    label={"Role at TGF"}
                                    name={"entity.role"}
                                    dataOptionsPath={"additionalData.roleFilterOptions"}
                                    onChange={(e, values, setValues) => {
                                        handleRoleChange(e, values, setValues);
                                    }}
                                    tabIndex={8}
                                />
                            </Grid>
                            <Grid item xs={3}>
                                <TgfFormTextBoxInput
                                    label={"TGF Personnel Position"}
                                    name={"entity.position"}
                                    disabledHandler={formContext => formContext.values.entity?.role !== PERSONNEL_ROLE_TYPES.TGF_PERSONNEL
                                || !formContext.canEdit}
                                    tabIndex={9}
                                />
                            </Grid>
                            <Grid item xs={2}>
                                <TgfFormTextBoxInput
                                    label={"City"}
                                    name={"entity.city"}
                                    tabIndex={15}
                                />
                            </Grid>
                            <Grid item xs={2}>
                                <TgfFormStateProvinceComboBoxInput
                                    label={"State"}
                                    name={"entity.state"}
                                    tabIndex={16}
                                />
                            </Grid>
                            <Grid item xs={2}>
                                <TgfFormTextBoxInput
                                    label={"Zip/Postal Code"}
                                    name={"entity.zipPostal"}
                                    tabIndex={17}
                                />
                            </Grid>
                            <Grid item xs={6}>
                                <Grid container spacing={2}>
                                    <Grid item xs={3}>
                                        <TgfFormToggleSwitch
                                            label={"Show Inactive"}
                                            name={"entity.isMarkedInactive"}
                                        />
                                    </Grid>
                                    <Grid item xs={4}>
                                        <TgfFormDateOnlyPickerInput
                                            label={"Inactive Date"}
                                            name={"entity.markedInactiveDate"}
                                            allowClear={true}
                                        />
                                    </Grid>
                                    <Grid item xs={5}>
                                        <TgfFormTextBoxInput
                                            label={"Displayed Location"}
                                            name={"entity.displayedLocation"}
                                            tabIndex={10}
                                        />
                                    </Grid>
                                </Grid>
                            </Grid>

                            <Grid item xs={2}>
                                <TgfFormComboBoxInput
                                    label={"Birthday Month"}
                                    name={"entity.birthdayMonth"}
                                    dataOptionsPath={"additionalData.birthdayMonthOptions"}
                                    onChange={handleBirthdayMonthChange}
                                    tabIndex={18}
                                />
                            </Grid>
                            <Grid item xs={2}>
                                <TgfFormPrimitiveDropdownListInput
                                    label={"Birthday Day"}
                                    name={"entity.birthdayDay"}
                                    dataOptionsPath={"additionalData.birthdayDayOptions"}
                                    disabledHandler={(formContext) => !formContext.values.entity?.birthdayMonth
                                        || !formContext.canEdit}
                                    tabIndex={19}
                                />
                            </Grid>
                            <Grid item xs={2}>
                                <TgfFormTextBoxInput
                                    label={"Birthday Year"}
                                    name={"entity.birthdayYear"}
                                    tabIndex={20}
                                />
                            </Grid>
                            <Grid item xs={6}>
                                {!props.personnel?.id &&
                                    <TgfFormCustom render={(tgfFormContext) => {
                                        return (
                                            <div style={{display: "flex", justifyContent: "center", alignItems: "center", height: "100%"}}>
                                                    <span
                                                        onClick={() => setImportAssociateInfoModalOpen(true)}
                                                        style={{
                                                            color: "blue",
                                                            textDecoration: "underline",
                                                            cursor: "pointer"
                                                        }}
                                                    >
                                                    Import Users Available Information
                                                    </span>
                                            </div>
                                        )
                                    }}/>
                                }
                            </Grid>
                            <Grid item xs={4}>
                                <TgfFormTextBoxInput
                                    label={"Emerg. Contact Name / Relationship"}
                                    name={"entity.emergencyContactPerson"}
                                    tabIndex={21}
                                />
                            </Grid>
                            <Grid item xs={2}>
                                <TgfFormPhoneNumberInput
                                    label={"Emerg. Contact Phone"}
                                    name={"entity.emergencyContactPhone"}
                                    tabIndex={22}
                                />
                            </Grid>
                            {(props.personnel?.id) &&
                                <Grid item>
                                    <TgfFormEditButton
                                        visibleHandler={(formContext) => canEditPersonnel}
                                    >
                                        EDIT
                                    </TgfFormEditButton>
                                </Grid>
                            }
                            <Grid item>
                                <TgfFormSubmitButton
                                    visibleHandler={(formContext) => canEditPersonnel}
                                >
                                    SAVE
                                </TgfFormSubmitButton>
                            </Grid>
                            <Grid item>
                                <TgfFormResetButton
                                    visibleHandler={(formContext) => canEditPersonnel}
                                >
                                    RESET
                                </TgfFormResetButton>
                            </Grid>
                            {props.personnel?.id &&
                                <Grid item>
                                    <TgfFormGenericButton
                                        color={"error"}
                                        onClick={(tgfFormContext) => setDeleteConfirmationModalOpen(true)}
                                        visibleHandler={(formContext) => canEditPersonnel}
                                    >
                                        DELETE
                                    </TgfFormGenericButton>
                                </Grid>
                            }
                        </Grid>
                        {importAssociateInfoModalOpen &&
                            <TgfFormCustom
                                render={(tgfFormContext) => (
                                    <ImportAssociateInfoModal
                                        parentFormContext={tgfFormContext}
                                        onClose={() => setImportAssociateInfoModalOpen(false)}
                                    />
                                )}
                            />
                        }
                        {deleteConfirmationModalOpen &&
                            <TgfFormCustom
                                render={(tgfFormContext) => (
                                    <DeletePersonnelConfirmationModal
                                        onDelete={() => handleDelete(tgfFormContext)}
                                        onClose={(() => setDeleteConfirmationModalOpen(false))}
                                        personnel={tgfFormContext.values.entity}
                                    />
                                )}
                            />

                        }
                    </TgfFormComponent>
        </TgfDialog>
    );

};

export default PersonnelEditModal;
