import {useMemo} from 'react';
import {findByPath} from "../utils/FormUtils";

// Tgf form specific custom hook for extracting a data options list from values.additionalData.
export const useTgfFormDataList = (path, tgfFormContext) => {
    return useMemo(() => ({
        data: findByPath(tgfFormContext.values, path),
    }), [path, tgfFormContext.values.additionalData]);
};

// only needs to re-initialize when path or additionalData changes.

