import TgfDialog from "../../../../../core/kendo/dialog/TgfDialog";
import {
    TgfFormComponent
} from "../../../../../core/kendo/form/TgfFormComponent/components/TgfFormComponent/TgfFormComponent";
import {
    TgfFormAssociateComboBoxInput
} from "../../../../../core/kendo/form/TgfFormComponent/components/inputs/TgFormAssociateComboBoxInput";
import {Grid} from "@material-ui/core";
import {
    TgfFormSubmitButton
} from "../../../../../core/kendo/form/TgfFormComponent/components/buttons/TgfFormSubmitButton";
import {
    TgfFormGenericButton
} from "../../../../../core/kendo/form/TgfFormComponent/components/buttons/TgfFormGenericButton";
import {useTgfRequest} from "../../../../../core/hooks/useTgfRequest";
import {ASSOCIATE_ROLES} from "../../../../../constants/Associate/associate-roles";
import {PERSONNEL_ROLE_TYPES} from "../../../../../constants/personnel/personnel-role-types";
import _ from "lodash";

export const ImportAssociateInfoModal = (props) => {

    const loadFormData = async () => {
        return {
            selectedAssociateId: null
        }
    }

    const injectImportedAssociate = async (associate, parentFormValues) => {
        if(associate) {
            parentFormValues.entity.firstName = associate?.firstName;
            parentFormValues.entity.lastName = associate?.lastName;
            parentFormValues.entity.crmId = associate?.systemId;
            parentFormValues.entity.businessPhoneExt = associate?.businessPhoneExt;
            parentFormValues.entity.businessEmailAddress = associate?.emailAddress;
        }
        if(associate.roleId === ASSOCIATE_ROLES.QUEEN.id && parentFormValues.role !== PERSONNEL_ROLE_TYPES.TGF_PERSONNEL) {
            parentFormValues.entity.involvedLlc = associate?.llcName;
        }
        if(associate.roleId === ASSOCIATE_ROLES.JACK.id && parentFormValues.role !== PERSONNEL_ROLE_TYPES.TGF_PERSONNEL) {
            const parentAssociate = await window.shell.gateway.getAssociate(associate.parentAssociateId);
            if(parentAssociate) parentFormValues.entity.involvedLlc = parentAssociate?.llcName;
        }
    }

    const [handleSubmit] = useTgfRequest({
        onRequest: async (values) => {
            const associate = await window.shell.gateway.getAssociate(values.selectedAssociateId);
            const parentFormValuesClone = _.cloneDeep(props.parentFormContext.values);
            await injectImportedAssociate(associate, parentFormValuesClone);
            props.parentFormContext.setValues(parentFormValuesClone);
            props.parentFormContext.setIsDirty(true);
            props.onClose();
        },
        successToast: "Successfully imported associate details",
        errorToast: "Failed to import associate details"
    })

    return (
        <TgfDialog
          title={"Import Associate Info"}
          width={500}
          onClose={props.onClose}
        >
            <TgfFormComponent
                loadFormData={loadFormData}
                onSubmit={handleSubmit}
            >
                <Grid container spacing={2}>
                    <Grid item xs={12}>
                        <TgfFormAssociateComboBoxInput
                            name={"selectedAssociateId"}
                            placeholder={"Search Active Users..."}
                            includeRoles={[ASSOCIATE_ROLES.QUEEN.name, ASSOCIATE_ROLES.JACK.name, ASSOCIATE_ROLES.ACE.name]}
                        />
                    </Grid>
                    <Grid item>
                        <TgfFormSubmitButton>
                            CONFIRM
                        </TgfFormSubmitButton>
                    </Grid>
                    <Grid item>
                        <TgfFormGenericButton onClick={props.onClose}>
                            CANCEL
                        </TgfFormGenericButton>
                    </Grid>
                </Grid>
            </TgfFormComponent>
        </TgfDialog>
    )

}