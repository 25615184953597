import {useTgfFormContext} from "../../hooks/useFormContext";
import PropTypes from 'prop-types';

/**
 * A component that provides access to the form context through a render prop.
 * This is useful when none of the pre-existing form components meet the user's needs
 * and they need full flexibility to render their custom fields with the form context.
 *
 * @param {Object} props - The component's props.
 * @param {Function} props.render - A render prop function that receives the form context.
 * The render function is called with an object that includes the `tgfFormContext`,
 * which can then be used to customize the field rendering.
 *
 * @returns {React.Element} The rendered output based on the provided render function.
 */
export const TgfFormCustom = ({ render }) => {
    const tgfFormContext = useTgfFormContext();

    return render(tgfFormContext);
};

TgfFormCustom.propTypes = {
    /**
     * A render prop function that provides access to the form context.
     * The render function receives an object with the following properties:
     * - `tgfFormContext`: The form context.
     *
     * The user should implement the render function to return any custom
     * input or field components that need to interact with the form context.
     *
     * @type {Function}
     * @param {Object} props - The input props passed to the render function.
     * @returns {React.Element} The custom element to render.
     */
    render: PropTypes.func.isRequired,
};
