import {TgfFormContext} from "../context/tgfFormContext";
import {useContext} from "react";

export const useTgfFormContext = () => {
    const context = useContext(TgfFormContext)
    if (!context) {
        throw new Error(
            `TgfForm compound components cannot be rendered outside the TgfFormComponent`,
        )
    }
    return context
}