import customerPayments from './customerPayments';
import customerReport from './customerReport';
import invoiceAging from './invoiceAging';
import laneLookup from './laneLookup';
import missingPrimaryContacts from './missingPrimaryContacts';
import searchLoads from './searchLoads';
import missingCreditApp from './missingCreditApp';
import standardReports from "./standardReports";

export default {
    missingCreditApp,
    customerPayments,
    customerReport,
    invoiceAging,
    laneLookup,
    searchLoads,
    missingPrimaryContacts,
    standardReports
};