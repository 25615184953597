import React, {useState} from 'react';
import { Grid } from '@material-ui/core';
import ComponentBuilder from '../../../core/ComponentBuilder';
import useIsAdministrator from "../../../hubs/personaV2/selectors/useIsAdministrator";
import FullWidthLayout from '../../../core/layouts/FullWidthLayout';
import InvoiceAgingListingTable from './includes/InvoiceAgingListingTable';
import CrmAssociateDropDown from "../../../crm/components/CrmAssociateDropDown";
import StatusTypeNames from '../../../hubs/shipment/StatusTypeNames';
import ReportNav from "../ReportNav";
import AppMuiCheckbox from '../../../core/components/inputs/AppCheckbox';
import CrmCollectionAssociateDropDown from '../../../crm/components/CrmCollectionAssociateDropDown';
import CrmDocumentQuickViewDrawer from '../../../crm/components/CrmDocumentQuickViewDrawer';
import {usePageTitle} from "../../../crm/components/customHooks/misc/usePageTitle";

const LoadProcessName = 'Reporting.InvoiceAging.Loaded';

const InvoiceAging = (props) => {

  const {
    dispose,
    isAdmin,
    load,
    invoiceAgingListing = [],
    invoiceAgingCount,
    modifyingAssociate,
    shipmentStatusTypes
  } = props;

  usePageTitle("TGF: Invoice Aging");

  const [offset, setOffset] = React.useState(0);
  const [limit, setLimit] = React.useState(20);
  const [sort, setSort] = React.useState([['daysPastDue', 'desc']]);
  const [order, setOrder] = React.useState('desc');
  const [orderBy, setOrderBy] = React.useState('daysPastDue');
  // If the user is an ace, initialize empty, otherwise the selected associate is always the
  // modifying associate (queen).
  const [associate, setAssociate] = React.useState(isAdmin ? null : modifyingAssociate);
  const [limitToPaidDiscrepancy, setLimitToPaidDiscrepancy] = React.useState(false);
  const [hideDisputeStatusShipments, setHideDisputeStatusShipments] = React.useState(false);
  const [limitToDisputeClaimShipments, setLimitToDisputeClaimShipments] = React.useState(false);
  const [hideClaimStatusShipments, setHideClaimAStatusShipments] = useState(false);
  const [selectedCollectionPersonnelId, setSelectedCollectionPersonnelId] = React.useState(undefined);
  const [docQuickViewShipmentId, setDocQuickViewShipmentId] = React.useState(null);

  const customerPaidStatus = shipmentStatusTypes
    .find(type => type.name === StatusTypeNames.CustomerPaid);
  // Capture status objects for payments pending and dispute.
  const payPendingStatus = shipmentStatusTypes
    .find(type => type.name === StatusTypeNames.PaymentsPending);
  const disputeStatus = shipmentStatusTypes
    .find(type => type.name === StatusTypeNames.Dispute);
  const claimStatus = shipmentStatusTypes
      .find(type => type.name === StatusTypeNames.Claim);

  const customerPaidStatusId = customerPaidStatus.id;
  const disputeStatusId = disputeStatus.id;
  const claimStatusId = claimStatus.id;
  const associateId = associate?.id;

  React.useEffect(() => {
    const loadOptions = {
      limitToPaidDiscrepancy,
      hideDisputeStatusShipments,
      hideClaimStatusShipments,
      limitToDisputeClaimShipments,
      associateId,
      customerPaidStatusId,
      disputeStatusId,
      claimStatusId,
      collectionAssociateId: selectedCollectionPersonnelId,
      offset,
      limit,
      sort
    };

    load(loadOptions);

    return () => dispose();
  }, [limitToPaidDiscrepancy, hideDisputeStatusShipments, limitToDisputeClaimShipments, associateId, customerPaidStatusId,
              disputeStatusId, selectedCollectionPersonnelId, offset, limit, sort, dispose, load]);

  // Add additional flags to the table's data to make it
  // easier to display.
  const tableData = invoiceAgingListing
    .map(shipment => {
      shipment.isDisputed = shipment.statusId === disputeStatus?.id;
      shipment.isPaymentPending = shipment.statusId === payPendingStatus?.id;
      shipment.isPastDue = shipment.daysPastDue > 0;
      return shipment;
    });

  //Handles when the user changes pages within the table.
  const handlePageChange = (e, page) => {
    setOffset(page * limit);
  };

  // Handles when the user clicks on column headers for sorting.
  const handleSortChange = (column) => {
    const changeOrder = (order === 'asc' && sort[0][0] === column) ? 'desc' : 'asc';

    setSort([[column, changeOrder]]);
    setOrder(changeOrder);
    setOrderBy(column);
  };
  const handleChangeRowsPerPage = (e) => {
    setOffset(0);
    setLimit(e.target.value);
  };
  const handleChangeAssociate = (selectedAssociate) => {
    setAssociate(selectedAssociate);
    setOffset(0);
  };

  const handlePaidDiscrepancyFilterChange = (e) => {
    setLimitToPaidDiscrepancy(e.target.checked);
  };

  const handleHideDisputeAndClaimStatusShipmentsChange = (e) => {
    setHideDisputeStatusShipments(e.target.checked);
    setHideClaimAStatusShipments(e.target.checked);
    if (limitToDisputeClaimShipments) setLimitToDisputeClaimShipments(false);
  };

  const handleDisputeClaimFilterChange = (e) => {
    setLimitToDisputeClaimShipments(e.target.checked);
    if (hideDisputeStatusShipments) setHideDisputeStatusShipments(false);
    if (hideClaimStatusShipments )  setHideClaimAStatusShipments(false);
  };

  const handleCollectionsPersonnelChange = (associate) => {

    // This allows for numbers, null (unassigned), and undefined (don't filter by collection personnel)
    const associateId = associate ?
      associate.id : undefined;
    setSelectedCollectionPersonnelId(associateId);
  };

  const handleDocumentQuickViewClick = (shipment) => {
    setDocQuickViewShipmentId(shipment.bolNumber);
  };

  const handleDocumentQuickViewClose = () => {
    setDocQuickViewShipmentId(null);
  };

  return (
    <FullWidthLayout SideNav={ReportNav} title="Invoice Aging">
      <Grid container spacing={1} direction="column">
        {
          isAdmin &&
          <Grid item>
            <Grid container spacing={1} alignItems="flex-end">
              <Grid item xs={12} md={2}>
                <label htmlFor="associate">Associate</label>
                <CrmAssociateDropDown
                  id={'associate'}
                  onChangeAssociate={handleChangeAssociate}
                  autoSelectFirst={false}
                />
              </Grid>
              <Grid item xs={12} md={2}>
                <CrmCollectionAssociateDropDown
                  useUnassignedOption
                  label={'Collection Personnel'}
                  onChange={handleCollectionsPersonnelChange}
                />
              </Grid>
              <Grid item xs={12} md={8}>
                <AppMuiCheckbox
                  checked={limitToPaidDiscrepancy}
                  label={'Show Only Paid Discrepancies'}
                  handleChange={handlePaidDiscrepancyFilterChange}
                />
                <AppMuiCheckbox
                  checked={hideDisputeStatusShipments}
                  label={'Hide Dispute and Claim Status Shipments'}
                  handleChange={handleHideDisputeAndClaimStatusShipmentsChange}
                /><AppMuiCheckbox
                  checked={limitToDisputeClaimShipments}
                  label={'Show Only Dispute and Claim Shipments'}
                  handleChange={handleDisputeClaimFilterChange}
                />
              </Grid>
            </Grid>
          </Grid>
        }
        <Grid item>
          <InvoiceAgingListingTable
            count={invoiceAgingCount}
            data={tableData}
            orderBy={orderBy}
            order={order}
            rowsPerPage={limit}
            page={offset / limit}
            onChangePage={handlePageChange}
            onChangeRowsPerPage={handleChangeRowsPerPage}
            onSort={handleSortChange}
            onDocumentQuickViewClick={handleDocumentQuickViewClick}
          />
        </Grid>
      </Grid>
      {
        docQuickViewShipmentId &&
        <CrmDocumentQuickViewDrawer
          shipmentId={docQuickViewShipmentId}
          onClose={handleDocumentQuickViewClose}
        />
      }
    </FullWidthLayout>
  )
}

export default ComponentBuilder
  .wrap(InvoiceAging)
  .stateToProps((state, ownProps) => {
    return {
      isAdmin: useIsAdministrator(),
      invoiceAgingListing: state.reporting.invoiceAging.listing,
      invoiceAgingCount: state.reporting.invoiceAging.invoiceAgingCount,
      modifyingAssociate: state.persona.modifyingAssociate,
      shipmentStatusTypes: state.support.shipmentStatusTypes
    };
  })
  .dispatchToProps((shell, dispatch, getState) => {
    return {
      async load(options) {
        dispatch(shell.actions.sys.processStart(LoadProcessName));
        dispatch(await shell.actions.reporting.invoiceAging.load(options));
        dispatch(shell.actions.sys.processComplete(LoadProcessName));
      },
      async dispose() {
        dispatch(await shell.actions.reporting.invoiceAging.dispose());
      }
    }
  })
  .build()
