import React from "react";
import {Grid} from "@material-ui/core";
import PropTypes from "prop-types";
import TgfDialog from "./TgfDialog";
import TgfButtonBasic from "../buttons/TgfButtonBasic/TgfButtonBasic";

/**
 * A confirmation modal component that displays a message with two buttons for user interaction.
 * It provides a clear, interactive dialog for confirmation or cancellation actions.
 *
 * This component is designed to be reusable across your application where a simple confirmation is needed.
 * It displays:
 * - A **title** at the top (e.g., "Are you sure?")
 * - A **description** in the body (e.g., "Do you want to delete this item?")
 * - Two buttons:
 *   - **Confirm** (default text: "YES"), which triggers an action passed via the `action` prop.
 *   - **Cancel** (default text: "NO"), which triggers the `onClose` prop, typically to close the modal.
 *
 * You can control the alignment of the text and buttons to fit your design needs:
 * - **textAlign**: Align the description text to the left, center, or right.
 * - **buttonAlign**: Align the buttons to the start, center, or end.
 *
 * It can be used in various contexts where the user needs to confirm or reject an action, such as deleting an item, submitting a form, or confirming a decision.
 *
 * @component
 */
const ConfirmationModal = (props) => {
    const {
        title,
        onClose,
        description,
        action,
        confirmBtnText,
        cancelBtnText,
        width = 500,
        textAlign = "center", // options: 'left', 'center', 'right'. default to center.
        buttonAlign = "center", // options: 'flex-start', 'center', 'flex-end'. defaults to center.
        disabled = false,
    } = props;

    return (
        <TgfDialog
            title={title}
            onClose={onClose}
            width={width}
        >
            <Grid container spacing={2} justifyContent={buttonAlign}>
                <Grid item xs={12} style={{ textAlign }}>
                    <span>{description}</span>
                </Grid>
                <Grid item>
                    <TgfButtonBasic
                        onClick={action}
                        disabled={disabled}
                    >
                        {confirmBtnText ?? "YES"}
                    </TgfButtonBasic>
                </Grid>
                <Grid item>
                    <TgfButtonBasic
                        themeColor={"base"}
                        onClick={onClose}
                    >
                        {cancelBtnText ?? "NO"}
                    </TgfButtonBasic>
                </Grid>
            </Grid>
        </TgfDialog>
    );
};

/**
 * Prop types validation for the ConfirmationModal component.
 */
ConfirmationModal.propTypes = {
    /** The title of the modal. */
    title: PropTypes.string.isRequired,

    /** The description to display inside the modal. Can be either a string or React element. */
    description: PropTypes.oneOfType([PropTypes.string, PropTypes.element]).isRequired,

    /** The action function to be called when the confirm button is clicked. */
    action: PropTypes.func.isRequired,

    /** The function to close the modal (typically called when the cancel button is clicked). */
    onClose: PropTypes.func.isRequired,

    /** Text for the confirmation button. Defaults to "YES" if not provided. */
    confirmBtnText: PropTypes.string,

    /** Text for the cancel button. Defaults to "NO" if not provided. */
    cancelBtnText: PropTypes.string,

    /** Width of the modal in pixels. Defaults to 500px if not provided. */
    width: PropTypes.number,

    /** Text alignment for the modal description. Can be "left", "center", or "right". Defaults to "center". */
    textAlign: PropTypes.string,

    /** Alignment for the buttons. Can be "flex-start", "center", or "flex-end". Defaults to "center". */
    buttonAlign: PropTypes.string,
};

export default ConfirmationModal;
