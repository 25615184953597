import React from "react";
import AppIconButton from "../../../../core/components/AppButton/AppIconButton";
import {CheckCircle, Edit, Info} from "@material-ui/icons";
import {Link} from "react-router-dom";
import PostAge from "./PostAge";
import InfoIconTooltip from "../../../../core/components/Tooltips/InfoIconTooltip/InfoIconTooltip";
import {useSelector} from "react-redux";
import AppCurrencyText from "../../../../core/components/text/AppCurrencyText";
import {makeStyles} from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
    greenPeriod: {
        color: theme.palette.tgfGreen.main
    },
    bluePeriod: {
        color: theme.palette.tgfBlue.main
    },
    blackPeriod: {
        color: theme.palette.tgfBlack.main
    },
    lessThanZero: {
        color: theme.palette.error.main
    }
}));

export const LoadFlagsHeaderCell = ({loadType}) => {
    return (
        <div style={{display: "flex", alignItems: "end", justifyContent: "space-between"}}>
            <span>Load Flags</span>
            <InfoIconTooltip
                title={`This information is dependent upon how the shipment is built in the CRM and does not reflect how it may be posted in ${loadType}.  If you see "POSTING", it's because there is no shipment involved with the posting.`}
            />
        </div>
    );
};

export const EstMilesHeaderCell = () => {
    return (
        <div style={{display: "flex", alignItems: "end", justifyContent: "space-between"}}>
            <span>Est. Miles</span>
            <InfoIconTooltip
                title={`The miles that are displayed are the approximate driving miles according to Google Maps and are based off the involved From and To cities and states.`}
            />
        </div>
    );
};


// Multi use Cells
export const loadDetailsCell = (props, onClickLoadDetails) => {
    return <td className={props.dataItem.isCovered ? "is-covered" : null}>
        <AppIconButton
            Icon={Info}
            onClick={() => onClickLoadDetails(props)}
            disabled={!props.dataItem?.bolNumber}
        />
    </td>;
};

// TODO we are going to pass in the whole row/record when we click the edit button.
export const editCell = (props, onClickEditButton) => {
    return (
        <td className={props.dataItem.isCovered === 1 ? "is-covered" : null}>
            <AppIconButton
                Icon={Edit}
                tooltip={`Edit Load`}
                onClick={() => onClickEditButton(props.dataItem)}
            />
        </td>
    );
};

export const editUserCell = (props, onClickEditButton) => {
    return (
        <td>
            <AppIconButton
                Icon={Edit}
                tooltip={'Edit Agency User'}
                onClick={() => onClickEditButton(props.dataItem)}
            />
        </td>
    );
};

export const ShipmentNumberCell = (cellProps) => {
    const openLinksInNewTab = useSelector((state) => state.persona.associate.openLinksInNewTab);
    const bolNumber = cellProps.dataItem?.bolNumber;
    return <td>
        {bolNumber
            ?
            <Link
                className={"tgf-grid-link-cell"} to={`/shipment/${bolNumber}/details`}
                target={openLinksInNewTab ? "_blank" : ""}
            >
                {bolNumber}
            </Link>
            :
            <span style={{color: "red"}}>POSTING</span>
        }
    </td>;
};

export const CurrencyCell = (amount) => {
    const classes = useStyles();

    return <td>
        <AppCurrencyText value={amount} className={parseFloat(amount) < 0 ? classes.lessThanZero : null} />
    </td>;
};

export const RepPaidCell = (cellProps) => {
    const classes = useStyles();

    let repPaidColor;
    switch (cellProps?.dataItem?.repPaidIndex) {
        case 0:
            repPaidColor = "green";
            break;
        case 1:
            repPaidColor = "blue";
            break;
        case 2:
            repPaidColor = "black";
            break;
        default:
            return <td></td>;
    }

    return (
        <td>
            <div style={{justifyContent: "center", display: "flex"}}>
                <CheckCircle className={classes[`${repPaidColor}Period`]} />
            </div>
        </td>
    );
};

export const dateConverter = (date) => {
    return date.map(((value, index) => {
        if (index !== 2) {
            return value.padStart(2, "0");
        } else {
            return value;
        }
    })).join('/');
};

export const PayPeriodCell = (cellProps) => {
    const startDate = new Date(cellProps?.dataItem?.startDate)
        .toLocaleDateString('en-US', {timeZone: 'UTC'}).split('/');
    const endDate = new Date(cellProps?.dataItem?.endDate)
        .toLocaleDateString('en-US', {timeZone: 'UTC'}).split('/');

    return (
        <td>
            {dateConverter(startDate)} - {dateConverter(endDate)}
        </td>
    );
};

export const postAgeCell = (cellProps, refreshPosting, disabled, disabledReason, tooltip) => {
    return <td>
        <PostAge
            dateRefreshable={cellProps.dataItem?.datRefreshable ? new Date(cellProps.dataItem?.datRefreshable) : new Date(cellProps.dataItem?.truckstopRefreshable)}
            postId={cellProps.dataItem?.id}
            refreshPosting={refreshPosting}
            disabled={disabled}
            disabledReason={disabledReason}
            showText={false}
            tooltip={tooltip}
        />
    </td>;
};

