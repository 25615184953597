import React, {useEffect, useRef} from 'react';
import {Grid} from '@material-ui/core';
import AppCard from "../../../../core/components/cards/AppCard/AppMuiCard";
import InfoIconTooltip from "../../../../core/components/Tooltips/InfoIconTooltip/InfoIconTooltip";
import {
    CurrencyCell,
    PayPeriodCell,
    RepPaidCell
} from "../../../open-load-board/includes/listingCells/multiUseListingCells";
import TgfDataGrid from "../../../../core/kendo/dataGrid/TgfDataGrid";
import {useDispatch} from "react-redux";
import {EmptyDataGrid} from "../../../../core/kendo/dataGrid/EmptyDataGrid";

const Adjustments = (props) => {

    const {
        associate,
        payPeriods,
        startDate,
        endDate,
        agencyMode,
        isAdmin
    } = props;

    const ref = useRef();
    const personalDispatch = useDispatch();

    useEffect(() => {
        if (startDate && endDate) {
            ref?.current?.refresh();
        }
    }, [associate, startDate, endDate]);

    const applyAdditionalFilters = (odata) => {
        odata = odata ?? {
            top: 20,
            skip: 0
        };

        if (startDate && endDate) {
            odata.filter.startDate = {ge: startDate.toISOString(), le: endDate.toISOString()};
        }
        if (associate) {
            odata.filter.associateId = associate.id;
        }

        return odata;
    };

    const loadAssociateAdjustments = async (odata) => {
        if (!associate) {
            return EmptyDataGrid;
        } else {
            try {
                personalDispatch(window.shell.actions.sys.processStart('loadAssociateMonthlyAdjustments'));
                return await window.shell.gateway.getAssociateMonthlyAdjustments(odata, associate?.id);

            } catch (e) {
                console.log(e);
            } finally {
                personalDispatch(window.shell.actions.sys.processComplete('loadAssociateMonthlyAdjustments'));
            }
        }
    };

    const GridColumns = [
        {
            title: 'Rep',
            field: 'rep',
            type: 'custom',
            sortable: false,
            cell: (props) => <td><span>{props.dataItem?.rep ?? ''}</span></td>,
            width: 130
        },
        {
            title: 'Related Shipment(s)',
            field: 'relatedBolNumbers',
            type: 'custom',
            sortable: true,
            cell: (props) => <td><span>{props.dataItem?.relatedBolNumbers ?? ''}</span></td>,
            width: 125
        },
        {
            title: 'Amount',
            field: 'amount',
            type: 'custom',
            sortable: true,
            cell: (props) => CurrencyCell(props.dataItem?.amount ?? ''),
            width: 80
        },
        {
            title: 'Reason for Change',
            field: 'reasonForChange',
            type: 'custom',
            sortable: true,
            cell: (props) => <td><span>{props.dataItem?.reasonForChange ?? ' '}</span></td>,
            width: 300
        },
        {
            title: 'Notes',
            field: 'notes',
            type: 'custom',
            sortable: true,
            cell: (props) => <td><span>{props.dataItem?.notes ?? ' '}</span></td>,
        },
        {
            title: 'Pay Period',
            field: 'startDate',
            sortable: true,
            type: 'custom',
            cell: PayPeriodCell,
            width: 200
        },
        {
            field: 'repPaidIndex',
            title: 'Rep Paid',
            type: 'custom',
            cell: RepPaidCell,
            sortable: true,
            width: 60
        }
    ];

    return (
        <AppCard
            title={
                <div style={{display: 'flex', alignItems: 'center'}}>
                    Adjustments {
                    agencyMode &&
                    <InfoIconTooltip
                        title={
                            `This information does NOT change when the filter and checkbox above are used. It will 
                            always provide you adjustments for your entire agency for the month/year being viewed.`
                        }
                        style={{alignSelf: "center"}}
                    />
                }
                </div>
            }
        >
            <Grid container direction="row" justify="center" alignItems="center" spacing={2}>
                <Grid item>
                    <TgfDataGrid
                        className={"open-load-board_shipment-listing-grid"}
                        ref={ref}
                        onApplyAdditionalDataStateAsOdata={applyAdditionalFilters}
                        onLoadDataPage={loadAssociateAdjustments}
                        gridColumns={GridColumns}
                        sort={[{field: "id", dir: "desc"}]}
                        pageable={{
                            pageSize: 20,
                            buttonCount: 10,
                            pageSizes: [5, 10, 20, 50, 100, 500]
                        }}
                    />
                </Grid>
            </Grid>
        </AppCard>
    );
};

export default Adjustments;
