import "../../../LtlQuote.scss";
import React, {useEffect, useRef, useState} from 'react';
import FullWidthLayout from "../../../../../core/layouts/FullWidthLayout";
import {Grid} from "@material-ui/core";
import LtlQuoteNav from "../../Nav/LtlQouteNav";
import CurrentCreditRemaining from "../../../../shipments/DetailsPage/includes/CurrentCreditRemaining";
import AppMuiCard from "../../../../../core/components/cards/AppCard";
import TgfButtonBasic from "../../../../../core/kendo/buttons/TgfButtonBasic/TgfButtonBasic";
import {useDispatch} from "react-redux";
import _ from "lodash";
import useFieldValidator from "../../../../../crm/components/customHooks/form/validation/useFieldValidator";
import {customerQuoteValidationSchema} from "../../validation/customerQuoteValidationSchema";
import {QuoteForm} from "../QuoteForm/QuoteForm";
import {ResultsPage} from "../ResultsPage/ResultsPage";
import {useFormStepper} from "../../../../../crm/components/customHooks/form/useFormStepper";
import HotNote from "../../../../shipments/DetailsPage/includes/HotNote";
import LabelOrLink from "../../../../../crm/components/cardHelperComponents/LabelOrLink";
import {BookingForm} from "../BookingForm/BookingForm";
import {ReviewAndFinalizePage} from "../ReviewAndFinalizePage/ReviewAndFinalizePage";
import ConfirmationModal from "../../../../../core/kendo/dialog/ConfimationModal";
import InfoModal from "../../../../../core/kendo/dialog/InfoModal";
import {BookingConfirmationPage} from "../BookingConfirmationPage/BookingConfirmationPage";
import CrmTypedAddressBookListingComboBox from "../../../../../crm/components/CrmTypedAddressBookListingComboBox";
import {TotalsWarningModal} from "../TotalsWarningModal/TotalsWarningModal";
import {SameDayPickupWarningModal} from "../SameDayPickupWarningModal/SameDayPickupWarningModal";
import {Info} from "@material-ui/icons";
import CategoryIcon from "@material-ui/icons/Category";

const renderStackableModalContent = () => {
    return (
        <div style={{textAlign: "center", fontWeight: "bold"}}>
            <p style={{textAlign: "center", fontWeight: "bold", marginTop: 0}}>IMPORTANT</p>
            <p>For stackable shipment contents, you must always note that the product is stackable within
                the Product Description field.</p>
            <p>Remember that in the eyes of most carriers, how the freight is loaded at the time of pickup, is what
                matters in terms of how many feet of space the shipment takes up. Classifying the freight as stackable
                in order to reduce the total feet of space will not matter if the shipper does not load it
                accordingly.</p>
            <p>NOTE - This reminder will not be repeated for other load contents where
                "Stackable" is selected.</p>
        </div>
    )
}

const renderHazmatModalContent = () => {
    return (
        <div style={{textAlign: "center", fontWeight: "bold"}}>
            <p style={{textAlign: "center", fontWeight: "bold", marginTop: 0}}>IMPORTANT</p>
            <p>At this time, you can only quote HazMat shipments through the rating engine.  If you intend to book a HazMat shipment, you must do so directly on Globaltranz's website, as you will not be allowed to book it through the rating engine.</p>
            <p>NOTE - This reminder will not be repeated for other load contents where
                "Hazmat" is selected.</p>
        </div>
    )
}

const renderTradeshowModalContent = () => {
    return (
        <div style={{textAlign: "center", fontWeight: "bold"}}>
            <p style={{textAlign: "center", fontWeight: "bold", marginTop: 0}}>IMPORTANT</p>
            <p>At this time, our Globaltranz agent wants to directly quote and book all Tradeshow shipments for Tallgrass Freight.</p>
            <p>Tradeshow shipments can be very time sensitive and failures can cause huge headaches for all parties involved. Therefore, our Globaltranz agent wants to review all aspects of such shipments to make sure issues can proactively be avoided.</p>
            <p>Please email our Globaltranz contacts with all the details of the Tradeshow quote/shipment you need assistance with.</p>
        </div>
    )
}

const defaultShipmentContent = {
    packageType: null,
    unitCount: null,
    piecesCount: null,
    nmfcCode: null,
    class: null,
    weightLbs: null,
    dimensionsLength: null,
    dimensionsWidth: null,
    dimensionsHeight: null,
    density: null,
    product: {
        id: null,
        internalProductNotes: null,
        productDescription: null,
    },
    logProduct: false,
    isHazMat: false,
    hazmatType: null,
    hazmatCode: null,
    hazmatClass: null,
    packingGroupNumber: null,
    isStackable: false,
};

const defaultShipperContent = {
    companyName: null,
    companyAddress1: null,
    companyAddress2: null,
    companyCity: null,
    companyStateProvince: null,
    companyPostalCode: null,
    primaryContactFirstName: null,
    companyBusinessPhone: null,
    readyTime: null,
    closeTime: null,
};

const defaultConsigneeContent = {
    companyName: null,
    companyAddress1: null,
    companyAddress2: null,
    companyCity: null,
    companyStateProvince: null,
    companyPostalCode: null,
    primaryContactFirstName: null,
    companyBusinessPhone: null,
    readyTime: null,
    closeTime: null,
};

const defaultFormValues = {
    originZipPostal: null,
    originCountry: null,
    pickupDate: null,
    originLocationType: 1,
    originAccessorials: [],
    pickupSite: null,
    destinationZipPostal: null,
    destinationCountry: null,
    destinationLocationType: 1,
    destinationAccessorials: [],
    deliverySite: null,
    shipmentContents: {0: {...defaultShipmentContent}},
    totalWeight: 0,
    totalDensity: 0.00,
    totalVolume: 0,
    generalBolNotes: null,
    customer: null,
    deliveryHours: null,
    shipper: defaultShipperContent,
    consignee: defaultConsigneeContent,
    selectedQuote: null,
    hazmatEmergencyPhone: null,
    referenceNumbers: {
        pickupNumber: null,
        deliveryNumber: null,
        refNum1Description: null,
        refNum1: null,
        refNum2Description: null,
        refNum2: null,
        refNum3Description: null,
        refNum3: null,
        refNum4Description: null,
        refNum4: null,
    },
};

export const QuoteWizard = ({type}) => {
    const personalDispatch = useDispatch();
    const [formValues, setFormValues] = useState(_.cloneDeep(defaultFormValues));
    const [formData, setFormData] = useState(null);
    const [creditStatus, setCreditStatus] = useState();
    const [isDirty, setIsDirty] = useState(false);
    const [isValid, setIsValid] = useState(true);
    const [shipmentContentsAreValid, setShipmentContentsAreValid] = useState([]);
    const [clearFormConfirmationModalOpen, setClearFormConfirmationModalOpen] = useState(false);
    const [bookedShipmentData, setBookedShipmentData] = useState(null);
    const [stackableModalHasBeenOpened, setStackableModalHasBeenOpened] = useState(false);
    const [stackableHasBeenToggled, setStackableHasBeenToggled] = useState(false);
    const [hazmatModalHasBeenOpened, setHazmatModalHasBeenOpened] = useState(false);
    const [hazmatHasBeenToggled, setHazmatHasBeenToggled] = useState(false);
    const [showTotalsWarningModal, setShowTotalsWarningModal] = useState(false);
    const [showSameDayPickupWarningModal, setShowSameDayPickupWarningModal] = useState(false);
    const [locationTimeZones, setLocationTimeZones] = useState(null);

    const customerInputRef = useRef();
    const originZipCodeRef = useRef();

    const {
        stepForward,
        stepBackward,
        initializeFormStepper,
        activeStepIdx,
    } = useFormStepper(5);

    const fauxLoadingStepBackward = async () => {
        personalDispatch(window.shell.actions.sys.processStart('stepBackwardLoading'));
        setTimeout(() => {
            personalDispatch(window.shell.actions.sys.processComplete('stepBackwardLoading')) ;
            stepBackward();
        }, 300);
    }

    const loadCustomerCreditStatus = async (customerId) => {
        try {
            personalDispatch(window.shell.actions.sys.processStart('loadCustomerCreditStatus'));
            setCreditStatus(await window.shell.gateway.getCompanyCreditStatus(customerId) ?? null);
        } catch (e) {
            console.log(e);
            personalDispatch(await window.shell.actions.sys.sendSnackbarMessage({
                content: 'Failed To Load Customer Credit Status',
                color: 'error',
            }));
        } finally {
            personalDispatch(window.shell.actions.sys.processComplete('loadCustomerCreditStatus'));
        }
    };

    const loadLtlFormData = async () => {
        try {
            personalDispatch(window.shell.actions.sys.processStart('loadLtlFormData'));
            const result = await window.shell.gateway.getLtlRateQuoteFormData(formValues.customer?.companyId);

            result.additionalData.siteTypes.push({id: 999, display: "Tradeshow"});

            const originAccessorials = [];
            if (result.entity.origin.isLiftGatePickup) {
                originAccessorials.push(1);
            }
            if (result.entity.origin.isInsidePickup) {
                originAccessorials.push(2);
            }
            if (result.entity.origin.byAppointmentOnly) {
                originAccessorials.push(3);
            }

            const destinationAccessorials = [];
            if (result.entity.destination.isLiftGateDelivery) {
                destinationAccessorials.push(1);
            }
            if (result.entity.destination.isInsideDelivery) {
                destinationAccessorials.push(2);
            }
            if (result.entity.destination.byAppointmentOnly) {
                destinationAccessorials.push(3);
            }
            if (result.entity.destination.notifyPriorToArrival) {
                destinationAccessorials.push(4);
            }
            if (result.entity.destination.sortAndSegregate) {
                destinationAccessorials.push(5);
            }

            const shipmentContents = !result.entity.shipmentItems.length
                ? {0: {...defaultShipmentContent}}
                : result.entity.shipmentItems.map(i => {
                    return {
                        packageType: i.packageTypeId,
                        unitsCount: i.unitCount,
                        nmfcCode: i.nmfcNumber,
                        class: i.freightClass,
                        weightLbs: i.weightLbs,
                        dimensionsLength: i.lengthIn,
                        dimensionsWidth: i.widthIn,
                        dimensionsHeight: i.heightIn,
                        density: 0, // calculated value.
                        piecesCount: i.unitSaidToContainCount,
                        isStackable: i.isStackable,
                        isHazMat: i.isHazmat,
                        productDescription: i.description,
                    };
                });

            setFormValues({
                ...formValues,
                // pickupDate: result.entity.pickupDateOnly,
                originLocationType: [1, 2].includes(result.entity.origin.siteTypeId) ? result.entity.origin.siteTypeId : null,
                pickupSite: ![1, 2, 21].includes(result.entity.origin.siteTypeId) ? result.entity.origin.siteTypeId : null,
                originZipPostal: result.entity.origin.postalCode,
                originAccessorials: originAccessorials,
                destinationLocationType: [1, 2, 21].includes(result.entity.destination.siteTypeId) ? result.entity.destination.siteTypeId : null,
                deliverySite: ![1, 2, 21].includes(result.entity.destination.siteTypeId) ? result.entity.destination.siteTypeId : null,
                destinationZipPostal: result.entity.destination.postalCode,
                destinationAccessorials: destinationAccessorials,
                shipmentContents: shipmentContents,
            });
            setFormData({
                originLocationTypes: result.additionalData.siteTypes.filter(i => [1, 2, 999].includes(i.id)),
                originAccessorialOptions: [
                    {id: 1, display: "Lift Gate"},
                    {id: 2, display: "Inside Pickup"},
                    {id: 3, display: "Appointment"},
                    {id: 4, display: "Protect From Freeze"},
                ],
                pickupSiteOptions: result.additionalData.siteTypes.filter(i => ![1, 2, 999].includes(i.id))
                    .sort((a, b) => a.display.toLowerCase() > b.display.toLowerCase() ? 1 : -1),
                destinationLocationTypes: result.additionalData.siteTypes.filter(i => [1, 2, 999].includes(i.id)),
                destinationAccessorialOptions: [
                    {id: 1, display: "Lift Gate"},
                    {id: 2, display: "Notify Prior To Arrival"},
                    {id: 3, display: "Inside Delivery"},
                    {id: 4, display: "Appointment"},
                    {id: 5, display: "Sort & Segregate"},
                ],
                deliverySiteOptions: result.additionalData.siteTypes.filter(i => ![1, 2, 999].includes(i.id))
                    .sort((a, b) => a.display.toLowerCase() > b.display.toLowerCase() ? 1 : -1),
                packageTypeOptions: function () {
                    const { packageTypes } = result.additionalData;
                    const palletsIndex = packageTypes.findIndex((x) => x.display === "Pallet");
                    // move pallets to front of array.
                    packageTypes.unshift(packageTypes.splice(palletsIndex, 1)[0]);
                    return packageTypes;
                }(),
                freightClassOptions: result.additionalData.freightClasses,
                hazmatTypes: result.additionalData.hazmatTypes,
                hazmatClasses: result.additionalData.hazmatClasses,
                hazmatPackingGroups: result.additionalData.hazmatPackingGroups
            });
        } catch (e) {
            console.log(e);
            personalDispatch(await window.shell.actions.sys.sendSnackbarMessage({
                content: 'Failed To Load Ltl Form Data.',
                color: 'error',
            }));
        } finally {
            personalDispatch(window.shell.actions.sys.processComplete('loadLtlFormData'));
        }

    };

    const handleReset = () => {
        initializeFormStepper();
        setFormValues(_.cloneDeep(defaultFormValues));
        setCreditStatus(null);
        setIsDirty(false);
        setClearFormConfirmationModalOpen(false);
        setStackableModalHasBeenOpened(false);
        setStackableHasBeenToggled(false);
        setHazmatHasBeenToggled(false);
        setHazmatModalHasBeenOpened(false);
    };

    const customerQuoteFieldErrors = useFieldValidator(customerQuoteValidationSchema, formValues, setIsValid);

    const applyFormValue = (name, value) => {
        setFormValues({
            ...formValues,
            [name]: value,
        });
        setIsDirty(true);
    };

    useEffect(() => {
        loadLtlFormData();
    }, [formValues.customer]);

    const handleCustomerChange = async (e) => {
        const customer = e;
        setFormValues({..._.cloneDeep(defaultFormValues), customer: customer});
        if (customer?.companyId) {
            await loadCustomerCreditStatus(customer.companyId);
        } else {
            setCreditStatus(null);
        }
        setIsDirty(false);
        originZipCodeRef.current.element.focus();
    };

    const shouldRenderFirstPage = activeStepIdx === 1 && (type === "quoteOnly" || (type === "customerQuote" && formValues.customer));
    const getPageTitle = () => {
        const pageType = type === "customerQuote" ? "Customer Quote/Book " : "Quote Only "
        let currentPageTitle = `Rating Engine - ${pageType}`;
        if(activeStepIdx === 2) currentPageTitle += "- Results";
        if(activeStepIdx === 3) currentPageTitle += "- Booking";
        if(activeStepIdx === 4) currentPageTitle += "- Review and Finalize";
        if(activeStepIdx === 5) currentPageTitle += "- Shipment Confirmation";
        return currentPageTitle;
    }

    const resetLocationTypes = () => {
        setFormValues({
            ...formValues,
            originLocationType: 1,
            destinationLocationType: 1
        });
    }

    const filterCompany = (filter) => {
        return {
            ...filter,
            $or :  [
                { companyIsDisabled: false },
                { companyIsDisabled: null }
            ]
        }
    };

    // for focusing on the customer select input on initial render
    useEffect(()=>{
        if(customerInputRef.current) {
            customerInputRef.current.focus();
        }
        if(type === "quoteOnly" && originZipCodeRef.current) {
            originZipCodeRef.current.element.focus();

        }

        if(type === "quoteOnly") {
            defaultShipmentContent.nmfcCode = "Quote Only";
            defaultShipmentContent.product.productDescription = "Quote Only";
        } else {
            defaultShipmentContent.nmfcCode = null;
            defaultShipmentContent.product.productDescription = null;
        }
    },[]);

    const renderTotalsWarningModalOrStepForward = () => {
        if(formValues.totalWeight >= 6000 || formValues.totalVolume >= 400) {
            setShowTotalsWarningModal(true);
        } else {
            stepForward();
        }
    }

    return (
        <FullWidthLayout SideNav={LtlQuoteNav} title={getPageTitle()}>
            <Grid container spacing={1}>
                {creditStatus &&
                <Grid item xs={12}>
                    <CurrentCreditRemaining creditStatus={creditStatus}></CurrentCreditRemaining>
                </Grid>
                }
                <Grid className={"quote-wizard__page-info"} item xs={12} md={12} lg={12}>
                    {(type === "customerQuote" && !formValues.customer) &&
                    <>
                        <p>This page is for running a quote or booking a shipment for a specific customer, using their
                            address book and Product Catalog entries. Simply select an active customer in the field
                            below to continue.</p>
                        <p>If you're just wanting to get an LTL rate, you can select the Quote Only option on the
                            left. The ability to save quotes isn’t currently available, but such a feature is something we eventually plan to add.</p>
                    </>
                }
                    {(type === "customerQuote" && formValues.customer) &&
                        <>
                            <p>You can select available Shipper/Consignee entries via the Shipper and Consignee fields. You can also choose items from the customer’s Product Catalog by clicking the {<CategoryIcon style={{margin: "0 4px", height: 18, verticalAlign: "bottom"}} className={'select-product-btn'}/>} icon above the Product Description field.</p>
                            <p>Please view the various {<Info className={"info-icon-tooltip_icon"} style={{verticalAlign: "bottom"}} />} tooltips and alerts as they provide important information that can help you. The ability to save quotes isn’t currently available, but such a feature is something we eventually plan to add.</p>
                        </>
                    }
                    {(type === "quoteOnly") &&
                        <>
                            <p>This page is for quickly running quotes without a customer being involved. The Pickup Date, Product Description, and NMFC fields are set for you already to make things quicker, but you can change them as desired.</p>
                            <p>Please view the various {<Info className={"info-icon-tooltip_icon"} style={{verticalAlign: "bottom"}} />} tooltips and alerts as they provide important information that can help you. The ability to save quotes isn’t currently available, but such a feature is something we eventually plan to add.</p>
                        </>
                    }
                </Grid>
                <Grid item xs={12}>
                    <AppMuiCard>
                        <Grid container spacing={2}>
                            {(type === "customerQuote" && activeStepIdx !== 5) &&
                                <Grid item className={'boldField'} xs={12} md={8} lg={4}>
                                    <LabelOrLink
                                        labelText={"Customer"}
                                        selected={formValues.customer}
                                        styles={{color: '#28a745'}}
                                    />
                                    {formValues.customer?.hotNote &&
                                        <HotNote company={formValues.customer}/>
                                    }
                                    <CrmTypedAddressBookListingComboBox
                                        style={{width: '100%'}}
                                        types={[CrmTypedAddressBookListingComboBox.Customer]}
                                        value={formValues.customer || null}
                                        onChange={handleCustomerChange}
                                        placeholder="Search..."
                                        popupIcon={' '}
                                        disabled={activeStepIdx !== 1}
                                        filterMap={filterCompany}
                                    />
                                </Grid>
                            }
                            {(activeStepIdx !== 1 && activeStepIdx !== 5) &&
                                <Grid item>
                                    <Grid style={{paddingTop: 20}} container spacing={2}>
                                        <Grid item>
                                            <TgfButtonBasic
                                                onClick={fauxLoadingStepBackward}
                                            >
                                                EDIT / GO BACK
                                            </TgfButtonBasic>
                                        </Grid>
                                        <Grid item>
                                            <TgfButtonBasic
                                                onClick={() => setClearFormConfirmationModalOpen(true)}
                                                themeColor={"error"}
                                            >
                                                COMPLETELY START OVER
                                            </TgfButtonBasic>
                                        </Grid>
                                    </Grid>
                                </Grid>
                            }
                            <Grid item xs={12} md={12} lg={8}></Grid>
                            {shouldRenderFirstPage &&
                                <QuoteForm
                                    type={type}
                                    formData={formData}
                                    formValues={formValues}
                                    setFormValues={setFormValues}
                                    applyFormValue={applyFormValue}
                                    setIsDirty={setIsDirty}
                                    fieldErrors={customerQuoteFieldErrors}
                                    shipmentContentsAreValid={shipmentContentsAreValid}
                                    setShipmentContentsAreValid={setShipmentContentsAreValid}
                                    defaultShipmentContent={defaultShipmentContent}
                                    defaultShipperContent={defaultShipperContent}
                                    defaultConsigneeContent={defaultConsigneeContent}
                                    activeStepIdx={activeStepIdx}
                                    originZipCodeRef={originZipCodeRef}
                                    setStackableHasBeenToggled={setStackableHasBeenToggled}
                                    setHazmatHasBeenToggled={setHazmatHasBeenToggled}
                                />
                            }
                            {activeStepIdx === 2 &&
                                <ResultsPage
                                    formValues={formValues}
                                    applyFormValue={applyFormValue}
                                    setFormValues={setFormValues}
                                    formData={formData}
                                    stepForward={stepForward}
                                    type={type}
                                    defaultShipmentContent={defaultShipmentContent}
                                    activeStepIdx={activeStepIdx}
                                    setLocationTimeZones={setLocationTimeZones}
                                />
                            }
                            {activeStepIdx === 3 &&
                                <BookingForm
                                    formValues={formValues}
                                    applyFormValue={applyFormValue}
                                    formData={formData}
                                    stepForward={stepForward}
                                    type={type}
                                    setFormValues={setFormValues}
                                    setIsDirty={setIsDirty}
                                    shipmentContentsAreValid={shipmentContentsAreValid}
                                    setShipmentContentsAreValid={setShipmentContentsAreValid}
                                    defaultShipmentContent={defaultShipmentContent}
                                    activeStepIdx={activeStepIdx}
                                    setShowSameDayPickupWarningModal={setShowSameDayPickupWarningModal}
                                />
                            }
                            {activeStepIdx === 4 &&
                                <ReviewAndFinalizePage
                                    formValues={formValues}
                                    applyFormValue={applyFormValue}
                                    formData={formData}
                                    stepForward={stepForward}
                                    type={type}
                                    setFormValues={setFormValues}
                                    setIsDirty={setIsDirty}
                                    shipmentContentsAreValid={shipmentContentsAreValid}
                                    setShipmentContentsAreValid={setShipmentContentsAreValid}
                                    defaultShipmentContent={defaultShipmentContent}
                                    setBookedShipmentData={setBookedShipmentData}
                                    activeStepIdx={activeStepIdx}
                                    loadCustomerCreditStatus={loadCustomerCreditStatus}
                                />
                            }
                            {activeStepIdx === 5 &&
                                <BookingConfirmationPage
                                    bookedShipmentData={bookedShipmentData}
                                    handleReset={handleReset}
                                />
                            }
                            <Grid item xs={12} md={12} lg={12}>
                                <Grid container spacing={2} justifyContent={"center"} alignContent={"center"}>
                                    {shouldRenderFirstPage &&
                                        <>
                                            <Grid item>
                                                <TgfButtonBasic
                                                    onClick={() => setClearFormConfirmationModalOpen(true)}
                                                    themeColor={"error"}
                                                    disabled={(!isDirty)}
                                                >
                                                    CLEAR FORM COMPLETELY
                                                </TgfButtonBasic>
                                            </Grid>
                                            <Grid item>
                                                <TgfButtonBasic
                                                    disabled={(!isValid || !isDirty || shipmentContentsAreValid.includes(false))}
                                                    onClick={renderTotalsWarningModalOrStepForward}
                                                >
                                                    GET QUOTES
                                                </TgfButtonBasic>
                                            </Grid>
                                        </>
                                    }
                                </Grid>
                            </Grid>
                            {clearFormConfirmationModalOpen &&
                                <ConfirmationModal
                                    title={`Confirm`}
                                    description={`Would like to clear the form and start over?`}
                                    action={handleReset}
                                    onClose={() => setClearFormConfirmationModalOpen(false)}
                                />
                            }
                            {(formValues?.originLocationType === 999 || formValues?.destinationLocationType === 999) &&
                                <InfoModal
                                    title={`Tradeshow Alert`}
                                    description={renderTradeshowModalContent()}
                                    action={resetLocationTypes}
                                    onClose={resetLocationTypes}
                                    width={800}
                                />
                            }
                            {(stackableHasBeenToggled && !stackableModalHasBeenOpened && type === "customerQuote") &&
                                <InfoModal
                                    title={`Stackable Alert`}
                                    description={renderStackableModalContent()}
                                    action={() => setStackableModalHasBeenOpened(true)}
                                    onClose={() => setStackableModalHasBeenOpened(true)}
                                    width={800}
                                />
                            }
                            {(hazmatHasBeenToggled && !hazmatModalHasBeenOpened) &&
                                <InfoModal
                                    title={`Hazmat Alert`}
                                    description={renderHazmatModalContent()}
                                    action={() => setHazmatModalHasBeenOpened(true)}
                                    onClose={() => setHazmatModalHasBeenOpened(true)}
                                    width={800}
                                />

                            }
                            {showTotalsWarningModal === true &&
                                <TotalsWarningModal
                                    title={"Weight and Cubic Feet Alert"}
                                    onClose={() => setShowTotalsWarningModal(false)}
                                    stepForward={stepForward}
                                />
                            }
                            {showSameDayPickupWarningModal === true &&
                                <SameDayPickupWarningModal
                                    title={"Same-Day Pickup Alert"}
                                    onClose={() => setShowSameDayPickupWarningModal(false)}
                                    stepForward={stepForward}
                                    locationTimeZones={locationTimeZones}
                                />
                            }
                        </Grid>
                    </AppMuiCard>
                </Grid>
            </Grid>
        </FullWidthLayout>
    );
};
