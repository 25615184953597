import {Grid} from "@material-ui/core";
import _ from "lodash";
import {useEffect, useState} from "react";
import {useDispatch} from "react-redux";
import {useHistory} from "react-router-dom";
import trimNullifyObject from "../../../../../core/utils/trimNullifyObject";
import useFieldValidator from "../../../../../crm/components/customHooks/form/validation/useFieldValidator";
import ButtonBar from "./includes/ButtonBar";
import "./includes/Css/ManageUsersForm.scss";
import DatCredsCard from "./includes/DatCredsCard";
import DetailsCard from "./includes/DetailsCard";
import JackListingGrid from "./includes/JackListingGrid";
import AssociateNotesListingGrid from "./includes/NotesCard/AssociateNotesListingGrid";
import TruckstopCredsCard from "./includes/TruckstopCredsCard";
import {associateValidationSchema} from "./includes/Validation/associateValidationSchema";

const defaultFormValues = {
    firstName: null,
    lastName: null,
    emailAddress: null,
    systemId: null,
    password: null,
    agencyName: null,
    agencyAbbreviation: null,
    businessPhone: null,
    businessPhoneExt: null,
    faxPhone: null,
    llcName: null,
    roleId: null,
    roles: [],
    parentAssociateId: null,
    isActive: true,
    displayPayouts: false,
    openLinksInNewTab: true,
    hasCarrierPrivilege: false,
    collectionRole: false,
    canViewMarginContribution: true,
    canEditBlockedDates: false,
    canEditBlockedDatesArBilling: false,
    canViewAccountingTab: false,
    canEditCustomerBilling: false,
    canViewCollectionsAssignment: false,
    canViewBackOfficeHelpDocs: false,
    canRetryInvoiceDistribution: false,
    canViewAgencyPage: false,
    canEditBusinessDays: false,
    rateConBusinessPhone: null,
    rateConBusinessPhoneExt: null,
    rateConFaxPhone: null,
    rateConEmailAddress: null,
    datCredentials: null,
    truckstopCredentials: null,
    canEditPersonnel: false,
};

const ManageUsersForm = (props) => {
    const personalDispatch = useDispatch();
    const history = useHistory();
    const [isValid, setIsValid] = useState(true);
    const [isDirty, setIsDirty] = useState(false);
    const [formValues, setFormValues] = useState(defaultFormValues);
    const [initialValues, setInitialValues] = useState(null);
    const [changeSubmitted, setChangeSubmitted] = useState(false);
    const fieldErrors = useFieldValidator(associateValidationSchema, formValues, setIsValid);

    useEffect(() => {
        if (props.associateId) {
            const mappedValues = {
                ...formValues,
                ...props.associate,
                password: props.associate?.decryptedPassword || props.associate?.password || null,
            };
            setFormValues(mappedValues);
            setInitialValues(_.cloneDeep(mappedValues));
        } else {
            setFormValues(defaultFormValues);
            setInitialValues(_.cloneDeep(defaultFormValues));
        }
    }, [props.associate]);

    const handleSubmit = async () => {
        try {
            personalDispatch(window.shell.actions.sys.processStart('saveAssociate'));
            const data = {
                loginAttempts: props.associate?.loginAttempts ?? 0,
                ...formValues,
            };
            const cleanAssociate = trimNullifyObject(data);
            const savedAssociate = await window.shell.gateway.saveAssociate(cleanAssociate);
            setIsDirty(false);

            if (!props.associateId) {
                history.push(`/admin/manage-users-new/${savedAssociate.id}`);
            } else {
                props.setAssociate(await window.shell.gateway.getAssociateFormData(props.associateId));
            }

            setChangeSubmitted(true);

            personalDispatch(await window.shell.actions.sys.sendSnackbarMessage({content: "Associate Saved."}));

        } catch (e) {
            console.log(e);
            personalDispatch(await window.shell.actions.sys.sendSnackbarMessage({
                content: 'Failed To Save Associate',
                color: 'error',
            }));
        } finally {
            personalDispatch(window.shell.actions.sys.processComplete('saveAssociate'));
        }
    };

    const handleReset = () => {
        setFormValues(_.cloneDeep(initialValues));
        setIsDirty(false);
    };

    return (
        <Grid container spacing={2} className={"manage-users-form"}>
            <Grid item xs={12}>
                <ButtonBar
                    associateId={props.associateId}
                    associate={props.associate}
                    setAssociate={props.setAssociate}
                    handleSubmit={handleSubmit}
                    handleReset={handleReset}
                    handleUnlockClick
                    isValid={isValid}
                    isDirty={isDirty}
                />
            </Grid>
            <Grid item xs={4}>
                <DetailsCard
                    formValues={formValues}
                    setFormValues={setFormValues}
                    fieldErrors={fieldErrors}
                    setIsDirty={setIsDirty}
                    isDirty={isDirty}
                    changeSubmitted={changeSubmitted}
                />
            </Grid>
            <Grid item xs={8}>
                <Grid container spacing={2}>
                    <Grid item xs={6}>
                        <DatCredsCard
                            formValues={formValues}
                            setFormValues={setFormValues}
                            setIsDirty={setIsDirty}
                        />
                    </Grid>
                    <Grid item xs={6}>
                        <TruckstopCredsCard
                            formValues={formValues}
                            setFormValues={setFormValues}
                            setIsDirty={setIsDirty}
                        />
                    </Grid>
                    <Grid item xs={12}>
                        <JackListingGrid
                            associateId={props.associateId}
                        />
                    </Grid>
                    <Grid item xs={12}>
                        <AssociateNotesListingGrid
                            associateId={props.associateId}
                        />
                    </Grid>
                </Grid>
            </Grid>
        </Grid>
    );
};

export default ManageUsersForm;
